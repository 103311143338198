import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Form, Formik, Field, yupToFormErrors} from "formik";
import * as Yup from "yup";

import * as AccountActions from "actions/Account";
import * as TimesheetActions from "actions/Timesheet";
import * as CommonActions from "actions/Common";
import "./ChangePersonalEmailContainer.scss";

const ChangePersonalEmailContainer = () => {
    const dispatch = useDispatch();
    const [isMailValid, setMailValid] = useState(false);

    const mailSchema = Yup.object().shape({
        personalEmail: Yup.string().required().email(),
    });

    return (
        <div id="change-personal-email-container">
            <div id="form-change-personal-email-container">
                {/*<div className="form-change-personal-email-title">*/}
                {/*    Modifier mon e-mail personnel*/}
                {/*</div>*/}
                <div className="parameters-card-title">Modifier mon e-mail personnel</div>
                <div className="parameters-card">
                    <Formik
                        initialValues={{personalEmail: ""}}
                        validate={(values, props) =>
                            Promise.resolve(
                                mailSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setMailValid(true))
                                    .catch(() => setMailValid(false))
                            )
                        }
                        onSubmit={(values, {setSubmitting}) => {
                            let body = {personal_email: values.personalEmail};
                            dispatch(CommonActions.setLoading(true));
                            dispatch(
                                AccountActions.updatePersonalEmail(
                                    JSON.stringify(body)
                                )
                            ).then((response) => {
                                if (
                                    response.type ===
                                    AccountActions.CHANGE_PERSONAL_EMAIL_SUCCESS
                                ) {
                                    let message =
                                        response.response.entities.personalEmail[
                                            "success"
                                            ].message;
                                    dispatch(
                                        TimesheetActions.notifySuccess(message)
                                    );
                                    dispatch(CommonActions.setLoading(false));
                                    setTimeout(() => {
                                        window.location.href = "/account";
                                    }, 1000);
                                }
                                setSubmitting(false);
                            });
                        }}
                    >
                        {({errors, isSubmitting}) => (
                            <Form noValidate className="form-change-personal-email">
                                <div className="field-container">
                                    <Field
                                        // className={}
                                        id="personalEmail"
                                        type="text"
                                        name="personalEmail"
                                        placeholder="E-mail personnel"
                                    />
                                </div>
                                <button
                                    className={
                                        "modify-account-action " +
                                        (isSubmitting || !isMailValid
                                            ? " disabled-account-btn"
                                            : "")
                                    }
                                    type="submit"
                                    disabled={isSubmitting || !isMailValid}
                                >
                                    Mettre à jour
                                </button>
                            </Form>
                        )}
                    </Formik>

                    <div className="form-change-personal-email-helper-text">
                        Cette adresse est utilisée pour vos demandes de
                        réinitialisation de mot de passe. Pensez à le mettre à jour.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePersonalEmailContainer;
