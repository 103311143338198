import React from 'react';
import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';

const overlayVariants = {
    initial: {opacity: 1, x: '100vw'},
    animate: {opacity: 1, x: 0, transition: {delay: 0, duration: 0.5}},
    exit: {opacity: 1, x: '100vw', transition: {delay: 0, duration: 0.5}},
};

const SlidePageTransitionPortal = ({show, handleClose, children, className = ""}) => {
    return createPortal(<AnimatePresence>
        {show && (
            <motion.div
                className={"transition-overlay validation-overlay " + (className ? className : "")}
                variants={overlayVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                onAnimationComplete={(definition) => {
                    if (definition === 'exit') {
                        handleClose()
                    }
                }}
                style={{
                    backgroundColor: 'white'
                }}
            >
                {children}
            </motion.div>
        )}
    </AnimatePresence>, document.getElementById('app-container'))
}

export default SlidePageTransitionPortal
