import React, {Fragment, useEffect, useState} from "react";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "./TimesheetMobile.scss";

import moment from "moment";
import "moment/locale/fr";

import * as TimesheetActions from "actions/Timesheet";
import PageHeader from "../../common/PageHeader";
import Calendar from "./Calendar";
import CalendarHeader from "../../TimesheetContainer/Calendar/CalendarHeader";
import SendMonthButton from "../../TimesheetContainer/Calendar/SendMonthButton";
import Actions from "./Actions";
import HelpText from "./HelpText";
import {changeOverlayContent} from "../../../events/OverlayEvents";
import Edit from "../../TimesheetContainer/Forms/Event/Edit";
import CountersContainerMobile from "./CountersContainerMobile";
import {useMonth} from "../../../hooks/useMonth";

const TimesheetMobile = () => {
    const me = useSelector((state) => state.user.me);
    // const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const monthEntity = useMonth()
    const selectedStartDay = useSelector((state) => state.timesheet.selectedStartDay);
    const selectedEndDay = useSelector((state) => state.timesheet.selectedEndDay);
    const selectedDays = useSelector((state) => state.timesheet.selectedDays);
    const craMode = useSelector((state) => state.timesheet.craMode)
    const dispatch = useDispatch();
    const location = useLocation()
    const {
        month = moment.parseZone().month() + 1,
        year = moment.parseZone().year(),
    } = useParams();

    let fetchable = false;

    const lowerLimit =
        moment(me.seniorityAt) > moment("01-01-2019", "DD-MM-YYYY")
            ? moment(me.seniorityAt)
            : moment("01-01-2019", "DD-MM-YYYY");

    const upperLimit = moment().add("1", "years").startOf("month");
    const cpLimit = moment().date(31).month(4);
    const rttLimit = moment().date(31).month(2);

    const targetMonth = moment(
        `01-${String(month).padStart(2, "0")}-${year}`,
        "DD-MM-YYYY"
    );

    const canDisplayCra = !me.hasRole("ROLE_NOT_CRA");

    const lowerLimitMonth = lowerLimit.startOf("month");

    if (
        (parseInt(year) &&
            parseInt(month) &&
            targetMonth >= lowerLimitMonth &&
            targetMonth <= upperLimit) ||
        (typeof year === "undefined" && typeof month === "undefined")
    ) {
        fetchable = true;
    }

    useEffect(() => {
        if (me.loaded && canDisplayCra) {
            if (fetchable) {
                dispatch(TimesheetActions.fetchEntireMonth(month, year));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me, month, year]);

    useEffect(() => {
        dispatch(TimesheetActions.viewMode(true))
    }, [location.pathname])

    useEffect(() => {
        if (selectedEndDay !== null) {
            // alert(selectedStartDay.dateAt+" "+selectedEndDay.dateAt)
            changeOverlayContent(
                <Edit action="new" monthEntity={monthEntity} modality={me.modality}/>
            );
        }
    }, [selectedEndDay]);

    const handleCounters = () => {
        changeOverlayContent(<CountersContainerMobile/>)
    }

    return me.loaded && me.seniorityAt ? (
        fetchable ? (
            <div id="page-container" className="timesheet-container">
                <div className="container">
                    <PageHeader title="Mon Activité" type="timesheet"/>
                    <CalendarHeader
                        monthEntity={monthEntity}
                        // type={type}
                        // user={user}
                        isRemoteEditing={craMode !== 'view'}
                    />
                    <Calendar month={monthEntity}/>
                    {/*<div>CRA Mode : {craMode}</div>*/}
                    <div id="day-details"></div>
                    <div className="cra-sub-area">
                        <SendMonthButton
                            monthEntity={monthEntity} f
                            personType={me.type}
                        />
                        <HelpText/>
                    </div>
                    {me.type !== "contractor" &&
                        <div className="mobile-counters-btn" onClick={handleCounters}>Compteurs et historique
                            d'absences <i className="fas fa-chevron-circle-right"></i></div>}
                    <Actions/>
                </div>
            </div>
        ) : (
            <Redirect to="/not-found"/>
        )
    ) : (
        ""
    );
};

export default TimesheetMobile;

