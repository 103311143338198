import React, {Fragment, useEffect, useState} from "react";
import {motion, AnimatePresence} from 'framer-motion/dist/framer-motion'
import moment from "moment";
import "moment/locale/fr";
import Month from "../../../entities/Month";
import {default as DayEntity} from "entities/Day";
import "./Calendar.scss";
import DaysOfWeek from "../../TimesheetContainer/Calendar/DaysOfWeek";
import Day from "./Day";
import RemoteDay from "./RemoteDay";
import {useSelector} from "react-redux";
import {useParams} from "react-router";

let contentVariants = {
    initial: {x: '-100vw'},
    animate: {x: 0},
    exit: {x: '100vw'},
};

const Calendar = (props) => {
    const monthEntity = props.month
    const craMode = useSelector((state) => state.timesheet.craMode)
    const calendarAnimationDirection = useSelector((state) => state.common.calendarAnimationDirection)
    const {
        month = moment.parseZone().month() + 1,
        year = moment.parseZone().year(),
    } = useParams();

    const getDays = () => {
        let daysArray = [];
        let lastDayOfMonth = moment
            .parseZone()
            .month(month - 1)
            .year(year)
            .endOf("month");
        let firstDayOfMonth = moment
            .parseZone()
            .month(month - 1)
            .year(year)
            .startOf("month");

        if (firstDayOfMonth.isoWeekday() !== 1) {
            let startingDay = moment
                .parseZone()
                .month(month - 1)
                .year(year)
                .startOf("month")
                .subtract(firstDayOfMonth.isoWeekday() - 1, "days");
            for (let i = 0; i < firstDayOfMonth.isoWeekday() - 1; i++) {
                let data = {
                    id: null,
                    date_at: moment.parseZone(startingDay),
                    worked: false,
                    working_state: "notCurrentMonth",
                    am: null,
                    pm: null,
                };
                let newDay = new DayEntity(data);
                daysArray.push(newDay);
                startingDay.add(1, "days");
            }
        }

        if (monthEntity instanceof Month) {
            const days = monthEntity.days
            Object.values(days).forEach((element, index) => {
                if (!(element instanceof DayEntity)) {
                    daysArray.push(new DayEntity(element));
                } else {
                    daysArray.push(element);
                }
            });
        } else { // chargement en cours
            const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
            for (let day = 1; day <= daysInMonth; day++) {
                let data = {
                    id: null,
                    date_at: moment(`${year}-${month}-${day}`, "YYYY-MM-DD"),
                    worked: false,
                    working_state: "notCurrentMonth",
                    am: null,
                    pm: null,
                };
                daysArray.push(new DayEntity(data));
            }
        }

        if (lastDayOfMonth.isoWeekday() !== 7) {
            let nextMonthStartingDay = moment
                .parseZone()
                .month(month - 1)
                .year(year)
                .endOf("month");
            while (nextMonthStartingDay.isoWeekday() !== 7) {
                nextMonthStartingDay.add(1, "days");
                let data = {
                    id: null,
                    date_at: moment.parseZone(nextMonthStartingDay),
                    worked: false,
                    working_state: "notCurrentMonth",
                    am: null,
                    pm: null,
                };
                let newDay = new DayEntity(data);
                daysArray.push(newDay);
            }
        }

        return daysArray;
    };

    return <AnimatePresence>
        <div className="calendar-mobile">
            <motion.div className="days-container-mobile" variants={contentVariants}
                        initial={{x: calendarAnimationDirection > 0 ? '-100vw' : '100vw'}}
                        animate="animate"
                        exit={{x: calendarAnimationDirection > 0 ? '100vw' : '-100vw'}}>
                <DaysOfWeek/>
                {craMode !== "remote" && getDays().map((day, key) => <Day day={day} key={key}/>)}
                {craMode === "remote" && getDays().map((day, key) => <RemoteDay day={day} key={key}/>)}
            </motion.div>
        </div>
    </AnimatePresence>
}

export default Calendar
