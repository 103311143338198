import { combineReducers } from "redux";
import Month from "entities/Month";
import Event from "entities/Event";
import EventCode from "entities/EventCode";
import OvertimeCode from "entities/OvertimeCode";
import { getCacheExpirationDate, setExpirableCache } from "utils/LocalStorage";
import {
    EXPAND_DAY,
    SELECT_START_DAY,
    SELECT_END_DAY,
    SELECT_DAYS,
    HOVER_DAYS,
    CLEAR_DAYS_SELECTION,
    CLEAR_DAYS_HOVERED_SELECTION,
    HOVER_EVENT,
    HOVER_OVERTIME,
    RESET_ERROR_MESSAGE,
    NOTIFY_ERROR,
    RESET_SUCCESS_MESSAGE,
    NOTIFY_SUCCESS,
    ENTER_EDIT_MODE,
    LOCK_START,
    LOCK_END,
    UNLOCK_START,
    UNLOCK_END,
    CLEAR_CALENDAR,
    MONTH_REQUEST,
    CLEAR_EXTRA_ACTIVITY_WEEK,
    CRA_REMOTE_MODE,
    CRA_EDIT_MODE,
    CRA_VIEW_MODE,
    PAGE_TRANSITION_ID,
    SET_REMOTE_DAY,
    SET_REMOTE_DAYS,
    SET_EXTRA_DAY,
    CLEAR_EXTRA_DAY,
    DISPLAY_EXTRA_OVERLAY,
    DISPLAY_PLANNING_COUNTERS,
    DISPLAY_WEEKEND
} from "actions/Timesheet";
import ExtraActivityWeek from "../entities/ExtraActivityWeek";
import ExtraActivityConfig from "../entities/ExtraActivityConfig";

export const entities = (
    state = {
        month: {},
        // days: {},
        // halfdays: {},
        // events: {},
        // overtimes: {},
        eventCodes: [],
        overtimeCodes: [],
        boundingEvents: [],
        // last3Absences: [],
        absenceHistory: [],
        expandedDay: {},
        extraActivityWeek: {},
        extraActivityConfig: {}
    },
    action
) => {
    switch (action.type) {
        case EXPAND_DAY:
            let expandedDay = {};
            if (action.day) {
                expandedDay = action.day;
                expandedDay.posX = action.posX;
                expandedDay.posY = action.posY;
            } else {
                expandedDay = {};
            }
            return { ...state, expandedDay: expandedDay };
        case MONTH_REQUEST:
            return {
                ...state,
                month: {},
                // days: {},
                // halfdays: {},
                // events: {},
                // overtimes: {},
                eventCodes: [],
                // overtimeCodes: [],
                boundingEvents: [],
            };

        case "MONTH_SUCCESS":
            let month = new Month(
                action.response.entities.month[action.response.result]
            );
            return {
                ...state,
                month: month,
            };

        case "CHECK_EVENT_BOUNDARIES_SUCCESS":
            let boundingEvents = {};
            if (action.response.entities.boundingEvents) {
                if (
                    action.response.entities.boundingEvents.boundingEvents
                        .previous
                ) {
                    let previousEvent = new Event(
                        action.response.entities.boundingEvents.boundingEvents.previous
                    );
                    boundingEvents.previous = previousEvent;
                }

                if (
                    action.response.entities.boundingEvents.boundingEvents.next
                ) {
                    let nextEvent = new Event(
                        action.response.entities.boundingEvents.boundingEvents.next
                    );
                    boundingEvents.next = nextEvent;
                }
            }
            return {
                ...state,
                boundingEvents: boundingEvents,
            };

        case "FETCH_ABSENCE_HISTORY_SUCCESS":
            let absenceHistory = [];
            if (action.response.entities.absenceHistory) {
                for (
                    let index = 0;
                    index < action.response.result.length;
                    index++
                ) {
                    const id = action.response.result[index];
                    absenceHistory.push(
                        new Event(action.response.entities.absenceHistory[id])
                    );
                }
            }
            return { ...state, absenceHistory: absenceHistory };

        // case "FETCH_OVERTIMES_SUCCESS":
        //     let overtimes = [];
        //     if (action.response.entities.overtimes) {
        //         let overtimesArray = Object.values(
        //             action.response.entities.overtimes
        //         );
        //         overtimesArray.forEach((element) => {
        //             overtimes.push(new Overtime(element));
        //         });
        //     }
        //     return {
        //         ...state,
        //         overtimes: overtimes,
        //     };

        case "LIST_OVERTIME_CODES_SUCCESS":
            if (action.persist) {
                let overtimeCodes = [];
                let overtimeCodesArray = [];
                if (action.response.entities.overtimeCodes) {
                    overtimeCodesArray = Object.values(
                        action.response.entities.overtimeCodes
                    );
                }
                overtimeCodesArray.forEach((element) => {
                    overtimeCodes.push(new OvertimeCode(element));
                });
                setExpirableCache(
                    "overtimeCodes",
                    overtimeCodes,
                    getCacheExpirationDate()
                );

                return {
                    ...state,
                    overtimeCodes: overtimeCodes,
                };
            }

            return state;

        case "EVENTCODES_SUCCESS":
            if (action.persist) {
                let eventCodes = [];
                let eventCodesArray = Object.values(
                    action.response.entities.eventCodes
                );
                eventCodesArray.forEach((element) => {
                    eventCodes.push(new EventCode(element));
                });
                setExpirableCache(
                    "eventCodes",
                    eventCodes,
                    getCacheExpirationDate()
                );
                return {
                    ...state,
                    eventCodes: eventCodes,
                };
            }
            return state;

        case "LOAD_EVENT_CODES_FROM_LOCALSTORAGE":
            let eventCodes = [];
            let eventCodesArray = Object.values(action.eventCodes);
            eventCodesArray.forEach((element) => {
                eventCodes.push(new EventCode(element));
            });
            return {
                ...state,
                eventCodes: eventCodes,
            };

        case "LOAD_OVERTIME_CODES_FROM_LOCALSTORAGE":
            let overtimeCodes = [];
            let overtimeCodesArray = Object.values(action.overtimeCodes);
            overtimeCodesArray.forEach((element) => {
                overtimeCodes.push(new OvertimeCode(element));
            });
            return {
                ...state,
                overtimeCodes: overtimeCodes,
            };

        case "CLEAR_CALENDAR":
            return state;

        case "CLEAR_EXTRA_ACTIVITY_WEEK":
            return {
                ...state,
                extraActivityWeek: {}
            }

        case "FETCH_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS":
            let extraActivityWeek = Object.values(action.response.entities.extraActivityWeek);
            return {
                ...state,
                extraActivityWeek: new ExtraActivityWeek(extraActivityWeek[0])
            }

        case "FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS":
            let missingExtraActivityWeek = Object.values(action.response.entities.missingExtraActivityWeek);
            return {
                ...state,
                missingExtraActivityWeek: new ExtraActivityWeek(missingExtraActivityWeek[0])
            }

        case "FETCH_EXTRA_ACTIVITY_FOR_WEEK_FAILURE":
            return {
                ...state,
                extraActivityWeek: {}
            }
        // case "REQUEST_EXTRA_ACTIVITIES_CONFIG_SUCCESS":
        //     return {
        //         ...state,
        //         extraActivityConfig: new ExtraActivityConfig(action.response.entities.extraActivityConfig.config)
        //     }
        default:
            return state;
    }
};

const clearCalendar = (state = null, action) => {
    const { type } = action;

    if (type === CLEAR_CALENDAR) {
        return null;
    }

    return state;
};

const hoveredEvent = (state = null, action) => {
    const { type, eventId } = action;

    if (type === HOVER_EVENT) {
        state = eventId;
    }

    return state;
};

const hoveredOvertime = (state = null, action) => {
    const { type, overtimeId } = action;

    if (type === HOVER_OVERTIME) {
        state = overtimeId;
    }

    return state;
};

const errorMessage = (state = null, action) => {
    const { type, errorMessage } = action;

    if (type === RESET_ERROR_MESSAGE) {
        return null;
    } else if (type === NOTIFY_ERROR) {
        return errorMessage || "Une erreur s'est produite";
    }

    return state;
};

const successMessage = (state = null, action) => {
    const { type, successMessage } = action;

    if (type === RESET_SUCCESS_MESSAGE) {
        return null;
    } else if (type === NOTIFY_SUCCESS) {
        return successMessage;
    }

    return state;
};

const selectedStartDay = (state = null, action) => {
    if (action.type === SELECT_START_DAY) {
        const { day } = action;
        state = day;
    } else if (action.type === CLEAR_DAYS_SELECTION) {
        state = null;
    }

    return state;
};

const selectedEndDay = (state = null, action) => {
    if (action.type === SELECT_END_DAY) {
        const { day } = action;
        state = day;
    } else if (action.type === CLEAR_DAYS_SELECTION) {
        state = null;
    }

    return state;
};

const hoveredDays = (state = [], action) => {
    if (action.type === HOVER_DAYS) {
        let daysToAdd = [];
        for (const day of action.days) {
            if (!state.includes(day)) {
                daysToAdd.push(day);
            }
        }
        return [...state, ...daysToAdd];
    } else if (action.type === CLEAR_DAYS_HOVERED_SELECTION) {
        return [];
    }

    return state;
};

const selectedDays = (state = [], action) => {
    if (action.type === SELECT_DAYS) {
        let daysToAdd = [];
        for (const day of action.days) {
            if (!state.includes(day)) {
                daysToAdd.push(day);
            }
        }
        return [...state, ...daysToAdd];
    } else if (action.type === CLEAR_DAYS_SELECTION) {
        return [];
    }

    return state;
};

const lockedStart = (state = false, action) => {
    const { type } = action;
    if (type === LOCK_START) {
        return true;
    } else if (type === UNLOCK_START) {
        return false;
    }
    return state;
};

const lockedEnd = (state = false, action) => {
    const { type } = action;
    if (type === LOCK_END) {
        return true;
    } else if (type === UNLOCK_END) {
        return false;
    }
    return state;
};

const lightboxContent = (state = null, action) => {
    const { type, lightboxContent } = action;

    if (type === "SET_LIGTHBOX_CONTENT") {
        return lightboxContent;
    }

    return state;
};

const editMode = (state = false, action) => {
    const { type, event } = action;
    if (type === ENTER_EDIT_MODE) {
        return event;
    }
    return state;
};

const craMode = (state = 'view', action) => {
    const { type, isRemoteMode } = action;
    if (type === CRA_REMOTE_MODE) {
        return 'remote';
    }
    if (type === CRA_EDIT_MODE) {
        return 'edit';
    }
    if (type === CRA_VIEW_MODE) {
        return 'view';
    }
    return state;
};

const remoteDays = (state = [], action) => {
    const { type, remoteDays, remoteDay } = action
     if (type === SET_REMOTE_DAYS) {
        return remoteDays;
    }
     if (type === SET_REMOTE_DAY) {
        const index = state.findIndex(day => day.day === remoteDay.day);

        if (index === -1) {
            return state;
        }

        const newState = [...state];
        newState[index] = {
            ...state[index],
            ...remoteDay
        };

        return newState;
    }
    return state;
}

const extraDay = (state = {number: null, type: null}, action) => {
    const { type, extraDay } = action
    if (type === SET_EXTRA_DAY) {
        return extraDay
    }
    if (type === CLEAR_EXTRA_DAY) {
        return {number: null, type: null}
    }
    return state;
}

const displayExtraOverlay = (state = false, action) => {
    const { type, displayExtraOverlay } = action
    if (type === DISPLAY_EXTRA_OVERLAY) {
        return displayExtraOverlay
    }
    return state;
}

const displayPlanningCounters = (state = false, action) => {
    const { type, displayPlanningCounters } = action
    if (type === DISPLAY_PLANNING_COUNTERS) {
        return displayPlanningCounters
    }
    return state;
}

const displayWeekend = (state = false, action) => {
    const { type, displayWeekend } = action
    if (type === DISPLAY_WEEKEND) {
        return displayWeekend
    }
    return state;
}

const transitionPageId = (state = '', action) => {
    const { type, id } = action;
    if (type === PAGE_TRANSITION_ID) {
        return id;
    }
    return state;
};


export default combineReducers({
    entities,
    errorMessage,
    successMessage,
    selectedStartDay,
    selectedEndDay,
    hoveredDays,
    selectedDays,
    lightboxContent,
    hoveredEvent,
    hoveredOvertime,
    editMode,
    lockedStart,
    lockedEnd,
    clearCalendar,
    craMode,
    remoteDays,
    extraDay,
    displayExtraOverlay,
    displayPlanningCounters,
    displayWeekend,
    transitionPageId
});
