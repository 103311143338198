import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Form, Formik, useFormikContext, Field, yupToFormErrors} from "formik";
import {changeOverlayContent} from "events/OverlayEvents";
import Lightbox from "components/common/Lightbox";

import * as TimesheetActions from "actions/Timesheet";
import {setLoading} from "actions/Common";

import "./ConfirmAskForExtraActivity.scss";
import LightboxHeader from "../../common/LightboxHeader";
import Button from "../Forms/Event/Button";

const ConfirmAskForExtraActivity = (props) => {
    const {monthEntity} = props;
    const dispatch = useDispatch();
    const [isSubmitting, setSubmitting] = useState(false);

    const handleClick = () => {
        dispatch(setLoading(true));
        setSubmitting(true)
        dispatch(TimesheetActions.requestExtraActivities(monthEntity.month, monthEntity.year))
            .then(() => {
                    Promise.all([
                        dispatch(TimesheetActions.fetchEntireMonth(monthEntity.month, monthEntity.year)),
                        dispatch(TimesheetActions.notifySuccess("Votre demande a été envoyée")),
                        dispatch(setLoading(false))
                    ]);
                    changeOverlayContent(null);
                    setSubmitting(false)
                }
            );
    }

    return <Lightbox key={monthEntity.id} className="lb-w1200">
        <div className="lb-ask-extra-activity">
            <LightboxHeader>
                <div className="lb-ask-extra-activity-header">
                    <div className="ask-extra-activity-header-title">
                        Demander l'autorisation de saisir une activité complémentaire
                    </div>
                </div>
            </LightboxHeader>
            <div className={"lb-ask-extra-activity-content"}>
                <div>
                    Demander à mon manager l'autorisation de saisir exceptionnellement
                    <br/>des activités complémentaires ce mois-ci
                </div>
            </div>
        </div>
        <div className="lb-confirm-send-actions">
            <div
                onClick={() => changeOverlayContent(null)}
                className="lb-confirm-send-cancel"
            >
                Annuler
            </div>
            <Button
                onClick={() => handleClick()}
                className="lb-confirm-send-confirm"
                text={"Envoyer"}
                processable={true}
                isProcessing={isSubmitting}
            />
        </div>
    </Lightbox>;
}

export default ConfirmAskForExtraActivity
