import {
    SET_FIRST_LOAD,
    TOGGLE_MOBILE,
    TOGGLE_MENU,
    SET_LOADING,
    SET_APP_LOADING,
    SET_AUTHENTICATED,
    REQUEST_IOS_TOKEN_SUCCESS,
    RESET_IOS_TOKEN_REQUEST_MESSAGE,
    SET_CALENDAR_ANIMATION_DIRECTION
} from "actions/Common";

const initialState = {
    isFirstLoad: true,
    isMobile: false,
    menuOpen: false,
    isLoading: false,
    appLoading: false,
    authenticated: false,
    iosTokenRequestSuccess: null,
    calendarAnimationDirection: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CALENDAR_ANIMATION_DIRECTION:
            if (action.calendarAnimationDirection) {
                return {...state, calendarAnimationDirection: action.calendarAnimationDirection}
            }

            return state
        case SET_FIRST_LOAD:
            if (action.isFirstLoad !== undefined) {
                return {...state, isFirstLoad: action.isFirstLoad}
            }

            return state
        case TOGGLE_MOBILE:
            if (action.state !== undefined) {
                return {...state, isMobile: action.state}
            }

            return state
        case TOGGLE_MENU:
            if (action.state !== undefined) {
                return { ...state, menuOpen: action.state };
            }

            return { ...state, menuOpen: !state.menuOpen };
        case SET_LOADING:
            if (action.isLoading !== undefined) {
                return { ...state, isLoading: action.isLoading };
            }

            return state;
        case SET_APP_LOADING:
            if (action.appLoading !== undefined) {
                return { ...state, appLoading: action.appLoading };
            }

            return state;
        case SET_AUTHENTICATED:
            return { ...state, authenticated: action.authenticated };
        case REQUEST_IOS_TOKEN_SUCCESS:
            return {
                ...state,
                iosTokenRequestSuccess: "Un email a été envoyé",
            };
        case RESET_IOS_TOKEN_REQUEST_MESSAGE:
            return {
                ...state,
                iosTokenRequestSuccess: null,
            };
        default:
            return state;
    }
};

export const resetIosTokenRequestMessage = (state = null, action) => {
    const { type, iosTokenRequestSuccess } = action;

    if (type === RESET_IOS_TOKEN_REQUEST_MESSAGE) {
        return null;
    }

    return state;
};
