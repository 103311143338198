import React, {useEffect, useState} from "react";

const AppleProcedure = () => {
    return <div>
        <ol>
            <li>Dans Safari, rendez-vous sur <a href="https://mysynchrone.fr">https://mysynchrone.fr</a></li>
            <li>Appuyez sur le bouton partager <img src="/img/install/share-apple.svg" alt=""/></li>
            <li>Choisissez "Sur l'écran d'accueil <img src="/img/install/add-ios.svg" alt=""/>"</li>
            <li>Appuyez sur "Ajouter"</li>
            <li>L'application est maintenant installée</li>
        </ol>
    </div>
}

export default AppleProcedure;
