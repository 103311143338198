import React, {useEffect, useState} from "react";
import "moment/locale/fr";
import "./ManageeDay.scss";

const ManageeDay = (props) => {
    const { day } = props

    // console.log(day)

    const getValue = (hd) => {
        switch(hd){
            case 'submitted': return 'S';
            case 'validated': return 'V';
            case 'weekend': return 'W';
            default: return '';
        }
    }

    const getClasses = () => {
        const classes = ["planning-hd"]
        if (day.am) {
            classes.push("planning-hd-am-"+day.am)
        }
        if (day.pm) {
            classes.push("planning-hd-pm-"+day.pm)
        }

        return classes.join(" ");
    }

    const getRemoteClasses = () => {
        const classes = ["planning-remote-hd"]
        if (day.remoteAm) {
            classes.push("planning-remote-hd-am")
        }
        if (day.remotePm) {
            classes.push("planning-remote-hd-pm")
        }

        return classes.join(" ");
    }


    return <div className={getClasses()}>
        {(day.remoteAm || day.remotePm) && <div className={getRemoteClasses()}><i className="fa fa-home"></i></div>}
    </div>
}

export default ManageeDay
