import {MY_SYNCHRONE_BASE_URL as baseUrl} from "config/Url.js";

class WelcomeBook {
    url;
    thumbnail;
    label;

    constructor(json) {
        this.url = json.url;
        this.thumbnail = json.thumbnail;
        this.label = json.label;
    }

    get downloadUrl() {
        return baseUrl + this.url;
    }

    get thumbnailPicture() {
        return baseUrl + this.thumbnail;
    }
}

export default WelcomeBook;
