import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Sortable from "sortablejs";
import * as DocumentActions from "actions/Document";
import MainDocuments from "components/DocumentContainer/MainDocuments";
import Section from "components/DocumentContainer/Section";

const DocumentsList = (props) => {
    const { isEdit } = props;
    const dispatch = useDispatch();
    const me = useSelector((state) => state.user.me);
    const welcomeBook = useSelector(
        (state) => state.document.entities.welcomeBook
    );
    const organizationChart = useSelector(
        (state) => state.document.entities.organizationChart
    );
    const sections = useSelector((state) => state.document.entities.sections);

    const moveFile = (fileId, newPosition) => {
        let json = {
            id: fileId,
            type: "file",
            position: newPosition,
        };
        dispatch(DocumentActions.moveElement(JSON.stringify(json)));
    };

    const moveFolder = (folderId, newPosition) => {
        let json = {
            id: folderId,
            type: "folder",
            position: newPosition,
        };
        dispatch(DocumentActions.moveElement(JSON.stringify(json)));
    };

    useEffect(() => {
        dispatch(DocumentActions.fetchDocuments()).then(() => {
            if (isEdit) {
                let test = document.querySelectorAll(
                    ".files-container-content, .nested-files-container-content"
                );
                Object.values(test).map((element, index) => {
                    new Sortable(element, {
                        animation: 150,
                        ghostClass: "ghost",
                        fallbackOnBody: true,
                        swapThreshold: 0.65,
                        onEnd: (event) => {
                            let itemId = event.item.attributes.elementid.value;
                            let newPosition = event.newIndex + 1;
                            if (
                                event.item.classList.contains("first-level-dnd")
                            ) {
                                moveFolder(itemId, newPosition);
                            } else {
                                moveFile(itemId, newPosition);
                            }
                        },
                    });
                });
            }
        });
    }, []);

    return (
        <div className="documents-container">
            {!isEdit && (
                <MainDocuments
                    welcomeBook={welcomeBook}
                    organizationChart={organizationChart}
                />
            )}
            <div className="sections-container">
                {Object.values(sections).length > 0
                    ? sections?.map((section, key) => {
                          return isEdit ? (
                              (me.hasRole(section.role) ||
                                  me.hasRole("ROLE_GED_ADMIN")) && (
                                  <Section
                                      key={key}
                                      section={section}
                                      isEdit={true}
                                  />
                              )
                          ) : (
                              <Section key={key} section={section} />
                          );
                      })
                    : ""}
            </div>
        </div>
    );
};

export default DocumentsList;
