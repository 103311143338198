import React, {useState} from "react";
import {Form, Formik, Field, yupToFormErrors} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {API_ROOT} from "config/Parameters";
import * as Yup from "yup";

import * as SecurityActions from "actions/Security";
import * as TimesheetActions from "actions/Timesheet";
import * as CommonActions from "actions/Common";
import * as PasswordSchemas from "validation/PasswordSchema";
import "./ChangePasswordContainer.scss";

const ChangePasswordContainer = () => {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.user.me);

    const [isCurrentPasswordHidden, setCurrentPasswordHidden] = useState(true);
    const [isPasswordHidden, setPasswordHidden] = useState(true);
    const [isConfirmPasswordHidden, setConfirmPasswordHidden] = useState(true);

    const [minimumCharactersValid, setMinimumCharactersValid] = useState(false);
    const [atLeastOneLowerValid, setAtLeastOneLowerValid] = useState(false);
    const [atLeastOneUpperValid, setAtLeastOneUpperValid] = useState(false);
    const [atLeastOneSpecialValid, setAtLeastOneSpecialValid] = useState(false);
    const [atLeastOneNumberValid, setAtLeastOneNumberValid] = useState(false);
    const [notCurrentYearValid, setNotCurrentYearValid] = useState(true);
    const [notSynchroneValid, setNotSynchroneValid] = useState(true);
    const [notPartOfFirstnameValid, setNotPartOfFirstnameValid] =
        useState(true);
    const [notPartOfLastnameValid, setNotPartOfLastnameValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(false);
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);

    const [showPasswordInstructions, setShowPasswordInstructions] =
        useState(false);

    const notPartOfFirstnameSchema = Yup.object().shape({
        password: Yup.string().test(
            "notPartOfFirstname",
            "Votre mot de passe ne doit pas contenir 3 caractères consécutifs de votre nom ou prénom",
            (item) => {
                let firstname = me.firstname;
                for (let i = 0; i < firstname.length - 2; i++) {
                    let pattern = firstname.substring(i, i + 3);
                    if (item.toLowerCase().match(pattern.toLowerCase())) {
                        return false;
                    }
                }

                return true;
            }
        ),
    });

    const notPartOfLastnameSchema = Yup.object().shape({
        password: Yup.string().test(
            "notPartOfLastname",
            "Votre mot de passe ne doit pas contenir 3 caractères consécutifs de votre nom ou prénom",
            (item) => {
                let lastname = me.lastname;
                for (let i = 0; i < lastname.length - 2; i++) {
                    let pattern = lastname.substring(i, i + 3);
                    if (item.toLowerCase().match(pattern.toLowerCase())) {
                        return false;
                    }
                }

                return true;
            }
        ),
    });

    return (
        <div id="change-password-container">
            <div id="form-change-password-container">
                {/*<div className="form-change-password-title">Modifier mon mot de passe</div>*/}
                <div className="parameters-card-title">Modifier mon mot de passe</div>
                <div className="parameters-card">
                    <Formik
                        initialValues={{
                            currentPassword: "",
                            password: "",
                            confirmPassword: "",
                        }}
                        validate={(values, props) =>
                            Promise.all([
                                PasswordSchemas.minLengthSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setMinimumCharactersValid(true))
                                    .catch((err) => {
                                        setMinimumCharactersValid(false);
                                    }),
                                PasswordSchemas.atLeastOneLowerSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setAtLeastOneLowerValid(true))
                                    .catch((err) => {
                                        setAtLeastOneLowerValid(false);
                                    }),
                                PasswordSchemas.atLeastOneUpperSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setAtLeastOneUpperValid(true))
                                    .catch((err) => {
                                        setAtLeastOneUpperValid(false);
                                    }),
                                PasswordSchemas.atLeastOneSpecialSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setAtLeastOneSpecialValid(true))
                                    .catch((err) => {
                                        setAtLeastOneSpecialValid(false);
                                    }),
                                PasswordSchemas.atLeastOneNumberSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setAtLeastOneNumberValid(true))
                                    .catch((err) => {
                                        setAtLeastOneNumberValid(false);
                                    }),
                                PasswordSchemas.notCurrentYearSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setNotCurrentYearValid(true))
                                    .catch((err) => {
                                        setNotCurrentYearValid(false);
                                    }),
                                PasswordSchemas.notSynchroneSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setNotSynchroneValid(true))
                                    .catch((err) => {
                                        setNotSynchroneValid(false);
                                    }),
                                PasswordSchemas.passwordValidSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setPasswordValid(true))
                                    .catch((err) => {
                                        setPasswordValid(false);
                                    }),
                                PasswordSchemas.confirmPasswordSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => {
                                        setConfirmPasswordValid(true);
                                    })
                                    .catch((err) => {
                                        setConfirmPasswordValid(false);
                                    }),
                                notPartOfFirstnameSchema
                                    .validate(values, {abortEarly: false})
                                    .then(() => setNotPartOfFirstnameValid(true))
                                    .catch((err) => {
                                        setNotPartOfFirstnameValid(false);
                                    }),
                                notPartOfLastnameSchema
                                    .validate(values, {abortEarly: false})
                                    .then(() => setNotPartOfLastnameValid(true))
                                    .catch((err) => {
                                        setNotPartOfLastnameValid(false);
                                    }),
                            ]).then(() => {
                                return PasswordSchemas.completePasswordSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => {
                                        return true;
                                    })
                                    .catch((err) => {
                                        return yupToFormErrors(err);
                                    });
                            })
                        }
                        onSubmit={(values, {setSubmitting}) => {
                            dispatch(CommonActions.setLoading(true));
                            fetch(API_ROOT + "login_check", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                },
                                credentials: "include",
                                mode: "cors",
                                body: JSON.stringify({
                                    username: me.username,
                                    password: values.currentPassword,
                                }),
                            })
                                .then((res) => res.json())
                                .then((json) => {
                                    if (json.refresh_token && json.token) {
                                        let jsonData = {
                                            password: {
                                                first: values.password,
                                                second: values.confirmPassword,
                                            },
                                        };
                                        dispatch(
                                            SecurityActions.changePassword(
                                                JSON.stringify(jsonData)
                                            )
                                        ).then((response) => {
                                            if (
                                                response.type ===
                                                SecurityActions.POST_CHANGE_PASSWORD_SUCCESS
                                            ) {
                                                let message =
                                                    response.response.entities
                                                        .message[
                                                        response.response.result
                                                        ].message;
                                                dispatch(
                                                    TimesheetActions.notifySuccess(
                                                        message
                                                    )
                                                );
                                                dispatch(
                                                    CommonActions.setLoading(false)
                                                );
                                                setTimeout(() => {
                                                    window.location.href =
                                                        "/account";
                                                }, 1000);
                                            }
                                        });
                                    } else {
                                        dispatch(
                                            TimesheetActions.notifyError(
                                                "Veuillez entrer votre mot de passe actuel"
                                            )
                                        );
                                        dispatch(CommonActions.setLoading(false));
                                    }
                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 1000);
                                });
                        }}
                    >
                        {({errors, isSubmitting}) => (
                            <Form>
                                <div className={"form-inputs"}>
                                    <div className="field-container">
                                        <Field
                                            id="currentPassword"
                                            type={
                                                isCurrentPasswordHidden
                                                    ? "password"
                                                    : "text"
                                            }
                                            name="currentPassword"
                                            placeholder="Mot de passe actuel*"
                                        />
                                        <i
                                            id="change-password-hidden-indicator"
                                            className={
                                                "far fa-fw " +
                                                (isCurrentPasswordHidden
                                                    ? "fa-eye"
                                                    : "fa-eye-slash")
                                            }
                                            onClick={() =>
                                                setCurrentPasswordHidden(
                                                    !isCurrentPasswordHidden
                                                )
                                            }
                                        ></i>
                                    </div>
                                    <div className="field-container">
                                        <Field
                                            id="password"
                                            type={
                                                isPasswordHidden ? "password" : "text"
                                            }
                                            name="password"
                                            placeholder="Nouveau mot de passe*"
                                        />
                                        <i
                                            id="change-password-hidden-indicator"
                                            className={
                                                "far fa-fw " +
                                                (isPasswordHidden
                                                    ? "fa-eye"
                                                    : "fa-eye-slash")
                                            }
                                            onClick={() =>
                                                setPasswordHidden(!isPasswordHidden)
                                            }
                                        ></i>
                                        {passwordValid &&
                                            notPartOfFirstnameValid &&
                                            notPartOfLastnameValid && (
                                                <i className="password-valid far fa-check-circle"></i>
                                            )}
                                    </div>
                                    <div className="field-container">
                                        <Field
                                            id="confirmPassword"
                                            type={
                                                isConfirmPasswordHidden
                                                    ? "password"
                                                    : "text"
                                            }
                                            name="confirmPassword"
                                            placeholder="Confirmer le mot de passe*"
                                        />
                                        <i
                                            id="change-password-hidden-indicator"
                                            className={
                                                "far fa-fw " +
                                                (isConfirmPasswordHidden
                                                    ? "fa-eye"
                                                    : "fa-eye-slash")
                                            }
                                            onClick={() =>
                                                setConfirmPasswordHidden(
                                                    !isConfirmPasswordHidden
                                                )
                                            }
                                        ></i>
                                        {confirmPasswordValid && (
                                            <i className="password-valid far fa-check-circle"></i>
                                        )}
                                    </div>
                                </div>
                                <div className="validation-instructions">
                                    <div className="validation-instruction-header">
                                        Votre mot de passe doit contenir :
                                    </div>
                                    <div className={"validation-instruction" +
                                        (minimumCharactersValid ? " validated" : "")}>
                                        <i className="far fa-check-circle validation-icon"></i> Au moins 12 caractères
                                    </div>
                                    <div className={"validation-instruction" +
                                        (atLeastOneLowerValid ? " validated" : "")}>
                                        <i className="far fa-check-circle validation-icon"></i> Au moins 1 minuscule
                                    </div>
                                    <div className={"validation-instruction" +
                                        (atLeastOneUpperValid ? " validated" : "")}>
                                        <i className="far fa-check-circle validation-icon"></i> Au moins 1 majuscule
                                    </div>
                                    <div className={"validation-instruction" +
                                        (atLeastOneSpecialValid ? " validated" : "")}>
                                        <i className="far fa-check-circle validation-icon"></i> Au moins 1 caractère spécial
                                    </div>
                                    <div className={"validation-instruction" +
                                        (atLeastOneNumberValid ? " validated" : "")}>
                                        <i className="far fa-check-circle validation-icon"></i> Au moins 1 chiffre
                                    </div>
                                    <div className="validation-instruction-header">
                                        Votre mot de passe ne doit pas contenir :
                                    </div>
                                    <div className={"validation-instruction" +
                                        (notPartOfFirstnameValid && notPartOfLastnameValid ? " validated" : "")}>
                                        <i className="far fa-check-circle validation-icon"></i> 3 lettres consécutives de
                                        votre nom ou prénom
                                    </div>
                                    <div className={"validation-check" +
                                        (notCurrentYearValid ? " validated" : "")}>
                                        <i className="far fa-check-circle validation-icon"></i> L'année en cours
                                    </div>
                                    <div className={"validation-check" +
                                        (notSynchroneValid ? " validated" : "")}>
                                        <i className="far fa-check-circle validation-icon"></i> Le nom de l'entreprise
                                    </div>
                                </div>
                                <div className="parameters-buttons-container">
                                    <button
                                        className={
                                            "modify-account-action" +
                                            (isSubmitting ||
                                            !passwordValid ||
                                            !notPartOfFirstnameValid ||
                                            !notPartOfLastnameValid ||
                                            !confirmPasswordValid
                                                ? " disabled-account-btn"
                                                : "")
                                        }
                                        type="submit"
                                        disabled={
                                            isSubmitting ||
                                            !passwordValid ||
                                            !notPartOfFirstnameValid ||
                                            !notPartOfLastnameValid ||
                                            !confirmPasswordValid
                                        }
                                    >
                                        Valider
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordContainer;
