import React, {useEffect, useState} from "react";
import moment from "moment";
import "moment/locale/fr";
import './AbsenceLineMobile.scss'
import * as ValidationActions from "actions/Validation";
import PageHeader from "../../common/PageHeader";
import {useDispatch, useSelector} from "react-redux";
import SlidePageTransitionPortal from "../../common/SlidePageTransitionPortal";
import Button from "../../TimesheetContainer/Forms/Event/Button";
import * as TimesheetActions from "actions/Timesheet";

const overlayVariants = {
    initial: {opacity: 1, x: '100vw'},
    animate: {opacity: 1, x: 0, transition: {delay: 0, duration: 0.5}},
    exit: {opacity: 1, x: '100vw', transition: {delay: 0, duration: 0.5}},
};

const AbsenceLineMobile = (props) => {
    const {absence, user} = props
    const [showPortal, setShowPortal] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false);
    const [isRefusing, setRefusing] = useState(false);
    const [isValidating, setValidating] = useState(false);
    const [comment, setComment] = useState("");
    const me = useSelector((state) => state.user.me);
    const dispatch = useDispatch()

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleClose = () => {
        setShowPortal(false);
    };

    const handleSubmit = (action) => {
        if (!isSubmitting) {
            setSubmitting(true);
            let json = {
                event: absence.id,
                comment: comment,
                updated_at: moment
                    .parseZone(absence.updatedAt)
                    .format("YYYY-MM-DD[T]HH:mm:ss"),
            };

            if (action === "validate") {
                json.action = "validated1";
                setValidating(true)
            } else if (action === "refuse") {
                json.action = "refused";
                setRefusing(true)
            }

            let body = JSON.stringify(json);
            dispatch(ValidationActions.postEventAction(body)).then(() => {
                    setSubmitting(false);
                    setValidating(false)
                    setRefusing(false)
                    handleClose()
                    dispatch(ValidationActions.fetchAbsenceListForMobile(me.matricule));
                },
                (response) => {
                    setSubmitting(false);
                    setValidating(false)
                    setRefusing(false)
                    let errorMessage = "Une erreur est survenue";
                    response.json().then((json) => {
                        if (json.errors) {
                            errorMessage = json.errors[0].message;
                        } else if (json.message) {
                            errorMessage = json.message;
                        }

                        return dispatch(
                            TimesheetActions.notifyError(errorMessage)
                        );
                    });
                });
        }
    }

    const eventDescription = (event, forOverlay) => {
        if (event.startAt === event.endAt) {
            return (
                <div>
                    Le <span
                    className={forOverlay && "bold"}>{moment.parseZone(event.startAt).format("dddd D MMMM YYYY")}
                    {event.daysCount === 0.5 && (event.startMoment === "am" ? " matin" : " après-midi")}</span>
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        Du <span
                        className={forOverlay && "bold"}>{moment.parseZone(event.startAt).format("dddd D MMMM YYYY")}
                        {event.startMoment === "pm" && " midi"}</span>
                    </div>
                    <div>
                        Au <span
                        className={forOverlay && "bold"}>{moment.parseZone(event.endAt).format("dddd D MMMM YYYY")}
                        {event.endMoment === "am" && " midi"}</span>
                    </div>
                </div>
            );
        }
    };

    return <div className="absence-line-mobile">
        <div>
            <div className="absence-line-code-description">
                {absence.eventCode.description}
                <span
                    className="absence-line-days-count">({absence.daysCount} jour{absence.daysCount > 1 && 's'})</span>
            </div>
            {eventDescription(absence)}
        </div>
        <div className="absence-line-mobile-actions" onClick={() => setShowPortal(true)}>
            <i className="fas fa-caret-right"></i>
        </div>
        <SlidePageTransitionPortal show={showPortal} handleClose={handleClose}>
            <PageHeader action={handleClose} title={user.firstname + ' ' + user.lastname} type="absence"/>
            <div className="absence-validation-mobile-header">Valider la demande de :</div>
            <div className="absence-validation-mobile-event-code">{absence.eventCode.description}</div>
            <div className=""></div>
            {eventDescription(absence, true)}
            <div className="">Total : <span
                className="bold">{absence.daysCount} jour{absence.daysCount > 1 && 's'}</span></div>
            <textarea
                className="validate-absence-mobile-comment-input"
                placeholder="Commentaire"
                onChange={(e) => handleCommentChange(e)}
            ></textarea>
            <div className="validation-mobile-actions">
                <Button className={"validate-btn" + (isSubmitting ? ' disabled-btn' : '')}
                        onClick={() => handleSubmit('validate')}
                        text={"Valider"}
                        processable={true}
                        isProcessing={isValidating}
                />
                <Button className={"refuse-btn" + (isSubmitting ? ' disabled-btn' : '')}
                        onClick={() => handleSubmit('refuse')}
                        text={"Refuser"}
                        processable={true}
                        isProcessing={isRefusing}
                />
            </div>
        </SlidePageTransitionPortal>
    </div>

}

export default AbsenceLineMobile
