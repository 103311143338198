import React from "react"
import {Field, Form, Formik, useFormikContext} from "formik";
import {useDispatch} from "react-redux";
import {isEmpty} from "../../utils/Utils";
import * as SettingsActions from "actions/Settings";
import * as CommonActions from "actions/Common";
import * as TimesheetActions from "actions/Timesheet";
import PageHeader from "../common/PageHeader";

const ParametersSubCategoryContainer = (props) => {
    const dispatch = useDispatch();
    const {subcategory, handleClose} = props

    const handleSubmit = () => {
        subcategory.patchFunction()
    }

    const translateDay = (day) => {
        switch (day) {
            case 'monday':
                return 'lundi'
            case 'tuesday':
                return 'mardi'
            case 'wednesday':
                return 'mercredi'
            case 'thursday':
                return 'jeudi'
            case 'friday':
                return 'vendredi'
            case 'saturday':
                return 'samedi'
            case 'sunday':
                return 'dimanche'
            default:
                return ''
        }
    }


    const SubCategoryForm = () => {
        const {values, isSubmitting, setFieldValue, submitForm,} = useFormikContext()
        const handleClick = (key, value) => {
            setFieldValue(key, !value)
        }

        let rendering = '';
        switch (subcategory.dataType) {
            case "checkboxList": {
                rendering = Object.entries(values).map((entry, index) => {
                    const [key, value] = entry
                    return <div className="parameters-subcategory-element" key={index}>
                        <label htmlFor={key} className={key + "-container"}>
                            <Field
                                className="parameters-checkbox"
                                type="checkbox"
                                name={key}
                            />
                            <span className="parameters-checkmark" onClick={() => handleClick(key, value)}></span>
                            <span className="parameters-checkbox-label"
                                  onClick={() => handleClick(key, value)}>{translateDay(key)}</span>
                        </label>

                    </div>
                })
            }
        }

        return <Form>
            {rendering}
            <div className="parameters-buttons-container">
                {/*<div className="lb-confirm-send-cancel">*/}
                {/*    Annuler*/}
                {/*</div>*/}
                <button type={"submit"} className="parameters-buttons-confirm">
                    Enregistrer
                </button>
            </div>
        </Form>
    }

    return <div className="parameters-subcategory-container">
        <PageHeader title={subcategory.title} action={handleClose} type={"parameters"}/>
        {/*<div className="parameters-subcategory-title">{subcategory.title}</div>*/}
        <div className="parameters-subcategory-subtitle">{subcategory.subTitle}</div>

        <div className="parameters-card-title">{subcategory.cardTitle}</div>
        <div className="parameters-card">
            {!isEmpty(subcategory.data) &&
                <Formik initialValues={subcategory.data} onSubmit={(values, {setSubmitting}) => {
                    dispatch(CommonActions.setLoading(true));
                    dispatch(
                        SettingsActions.patchRemoteDaysConfig(
                            JSON.stringify(values)
                        )).then((response) => {
                        if (response.type === SettingsActions.PATCH_REMOTE_DAYS_CONFIG_SUCCESS) {
                            dispatch(TimesheetActions.notifySuccess('Vos jours de télétravail récurrents ont été enregistrés avec succès'))
                            dispatch(CommonActions.setLoading(false))
                            dispatch(SettingsActions.fetchRemoteDaysConfig())
                        }
                    })
                }}>
                    {({errors, isSubmitting}) => (
                        <div className="parameters-subcategory-content">
                            <SubCategoryForm/>
                        </div>
                    )}
                </Formik>}
        </div>

    </div>
}

export default ParametersSubCategoryContainer;
