import React from "react";
// import "./HomeAppContainer.scss";
import {HomeApp} from "./HomeApp";
import applications from "../../config/Home.json"
import {useSelector} from "react-redux";

export const HomeAppContainer = (props) => {
    const me = useSelector((state) => state.user.me);

    return <div className="apps-container">
        {applications.filter(app => props.applications.includes(app.name)).map((application, index) => <HomeApp
            key={index}
            color={application.color}
            url={application.url}
            text={application.text}
            subtext={application.subtext}
            picto={application.icon}
            isContractor={me.type === "contractor"}
            mobile={props.isMobile}
            isAvailableOffline={application.isAvailableOffline}
        />)}
    </div>
};
