import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeOverlayContent} from "events/OverlayEvents";
import Lightbox from "components/common/Lightbox";

import * as TimesheetActions from "actions/Timesheet";
import { setLoading } from "actions/Common";

import "./ExtraActivitiesWeekSelection.scss";
import LightboxHeader from "../../common/LightboxHeader";
import CalendarHeader from "../../TimesheetContainer/Calendar/CalendarHeader";
import ConfirmAskForExtraActivity from "../../TimesheetContainer/Calendar/ConfirmAskForExtraActivity";
import ExtraActivitiesPopUp from "../../TimesheetContainer/Calendar/ExtraActivitiesPopUp";
import ExtraActivityWeek from "./ExtraActivityWeek";

const ExtraActivitiesWeekSelection = (props) => {
    const {monthEntity} = props;
    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.common.isMobile)

    const handleClick = () => {
        // dispatch(setLoading(true));
        // dispatch(TimesheetActions.requestExtraActivities(monthEntity.month, monthEntity.year))
        //     .then(() => {
        //         Promise.all([
        //                 dispatch(TimesheetActions.fetchEntireMonth(monthEntity.month, monthEntity.year)),
        //                 dispatch(TimesheetActions.notifySuccess("Votre demande a été envoyée")),
        //                 dispatch(setLoading(false))
        //             ]);
        //             changeOverlayContent(null);
        //         }
        //     );
    }

    useEffect(() => {
        if (!isMobile) {
            changeOverlayContent(null)
        }
    }, [isMobile]);

    const handleExtraActivityRequest = () => {
        changeOverlayContent(<ConfirmAskForExtraActivity monthEntity={monthEntity} />)
    }

    return <Lightbox key={monthEntity.id} className="lb-w1200 timesheet-lb">
        <div className="lb-ask-extra-activity">
            <LightboxHeader>
                <div className="lb-ask-extra-activity-header">
                    <div className="ask-extra-activity-header-title">
                        Saisir une activité complémentaire
                    </div>
                </div>
            </LightboxHeader>
            <div className="content">
                <CalendarHeader monthEntity={monthEntity} isRemoteEditing={true} />
                <div className="extra-weeks-container">
                    {monthEntity.weeks.map((week, key) => <ExtraActivityWeek key={key} week={week} monthEntity={monthEntity} />)}
                </div>
                {monthEntity.canRequestExtraActivities && <div className="extra-request">
                    <p>Vous ne pouvez actuellement pas saisir d'activité sur toutes les semaines du mois, vous pouvez demander à votre manager l'autorisation de saisir exceptionnellement
des activités complémentaires ce mois-ci.</p>
                    <div className="lb-confirm-send-actions">
                        <div onClick={handleExtraActivityRequest} className="lb-confirm-send-request">Demander la saisie d'une activité complémentaire</div>
                    </div>
                </div>}
            </div>
        </div>
    </Lightbox>;
}

export default ExtraActivitiesWeekSelection
