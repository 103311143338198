import React from "react";
import {motion, AnimatePresence} from 'framer-motion/dist/framer-motion'
import {monthInLetter} from "utils/Utils";

import "./CalendarHeaderDate.scss";
import {useSelector} from "react-redux";

let contentVariants = {
    initial: {x: '-100vw'},
    animate: {x: 0},
    exit: {x: '100vw'},
};

const CalendarHeaderDate = (props) => {
    const {monthEntity, type, user} = props;
    const month = monthEntity.month;
    const year = monthEntity.year;
    const calendarAnimationDirection = useSelector((state) => state.common.calendarAnimationDirection)

    return (
        <AnimatePresence>
            <motion.span variants={contentVariants}
                    initial={{x: calendarAnimationDirection > 0 ? '-100vw' : '100vw'}}
                    animate="animate"
                    exit={{x: calendarAnimationDirection > 0 ? '100vw' : '-100vw'}}>
                <span className="month">{monthInLetter(month)} </span>
                <span className="year">{year} </span>
                {type === "view" && user && (
                    <span className="cra-user-identity">({user.identity})</span>
                )}
            </motion.span>
        </AnimatePresence>
    );
};

export default CalendarHeaderDate;
