import React, {Component, useEffect, useRef, useState} from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "moment";
import "moment/locale/fr";
import {useSelector} from "react-redux";
import {getOSInfo} from "../../utils/Browser";

class CustomDateInput extends Component {
    render() {
        const {onClick, value} = this.props;

        return (
            <div className="custom-date-input" onClick={onClick}>
                {formattedDate(value, "DD MMMM YYYY")}
            </div>
        );
    }
}

// Fonction de formatage de la date
const formattedDate = (date, format) => {
    if (format) {
        return moment.parseZone(date, format).format("ddd D MMMM");
    }
    return moment.parseZone(date).format("ddd D MMMM");
};

const ResponsiveDatePicker = (props) => {
    const isMobile = useSelector((state) => state.common.isMobile)
    const {value, setValue, isAllowed} = props;
    const [selectedDate, setSelectedDate] = useState(new Date(value));
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        calculateMinMaxDates();
    }, [value]);

    // Fonction pour ouvrir l'input type="date"
    const handleDateClick = () => {
        return inputRef.current?.showPicker();  // Clique sur l'input caché
    };

    // Fonction pour gérer la sélection de la date
    const handleDateChange = (event) => {
        const date = new Date(event.target.value);
        setValue(date);
    };

    // Calcul des dates min et max
    const calculateMinMaxDates = () => {
        const startOfMonth = moment(value).startOf('month');
        const endOfMonth = moment(value).endOf('month');

        let min = null;
        let max = null;

        for (let day = startOfMonth; day <= endOfMonth; day.add(1, 'day')) {
            const dayAsDate = day.toDate();
            if (isAllowed(dayAsDate)) {
                if (!min) {
                    min = dayAsDate;
                }
                max = dayAsDate;  // On continue à mettre à jour jusqu'à la dernière date valide
            }
        }

        setMinDate(min);
        setMaxDate(max);
    };

    return (
        <div className={"mys-datepicker"}>
            {isMobile && getOSInfo().osName === "Android" ? (<>
                    {/* Ce div affiche la date formatée */}
                    <div className="custom-date-input" onClick={handleDateClick}>
                        {formattedDate(value)}
                    </div>

                    {/* Input de type "date" caché */}
                    <input
                        ref={inputRef}
                        type="date"
                        value={moment(value).format("YYYY-MM-DD")}
                        onChange={handleDateChange}
                        min={minDate ? moment(minDate).format("YYYY-MM-DD") : undefined}
                        max={maxDate ? moment(maxDate).format("YYYY-MM-DD") : undefined}
                        style={{position: "absolute", left: "-9999px"}}
                    />
                </>
            ) : (
                // React DatePicker pour bureau
                <DatePicker
                    selected={new Date(value)}
                    onChange={(date) => setValue(date)}
                    locale="fr"
                    dateFormat="dd MMMM yyyy"
                    todayButton="Aujourd'hui"
                    filterDate={isAllowed}
                    customInput={<CustomDateInput/>}
                />
            )}
        </div>
    );
};

export default ResponsiveDatePicker;
