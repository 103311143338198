import React, {useEffect, useState} from "react";
import AbsenceLineMobile from "./AbsenceLineMobile";

const PersonRowDetailsMobile = (props) => {
    const {user} = props

    return <>
        {user.absences.map((absence, key) => {
            return <AbsenceLineMobile key={key} absence={absence} user={user}/>
        })}
    </>
}

export default PersonRowDetailsMobile