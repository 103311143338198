import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";

const HelpText = (props) => {
    const craMode = useSelector((state) => state.timesheet.craMode)
    const [text, setText] = useState("")
    const me = useSelector((state) => state.user.me);

    useEffect(() => {
        if (craMode === 'remote') {
            setText(me.type === "contractor" ? "Sélectionnez vos jours de travail à distance" : "Sélectionnez vos jours en télétravail")
        } else if (craMode === 'edit') {
            setText("Sélectionnez une date")
        } else {
            setText("")
        }
    }, [craMode])

    return <div className={"cra-help-text" + (craMode === "view" ? " cra-help-text-hidden" : "")}>{text}</div>
};

export default HelpText;
