import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Lightbox from "components/common/Lightbox";
import {changeOverlayContent} from "events/OverlayEvents";
import SpecificCRAContainer from "./SpecificCRAContainer";

import * as TimesheetActions from "actions/Timesheet";
import {setLoading} from "actions/Common";

import "./DepositCRAContainer.scss";
import Button from "../Forms/Event/Button";

const DepositCRAContainer = (props) => {
    const dispatch = useDispatch();
    const {monthEntity} = props;

    const filesInStore = useSelector(
        (state) => state.timesheet.entities.month.files
    );
    const [files, setFiles] = useState(filesInStore);
    const [certified, setCertified] = useState(false);
    const [special, setSpecial] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [touched, setTouched] = useState(false);

    const handleSubmit = () => {
        if (files.length > 0 && !submitting && touched && certified) {
            setSubmitting(true);
            dispatch(setLoading(true));
            let json = {special: true, certified: certified};
            let filesArray = [];
            files.map((element) => {
                if (!element.id) {
                    let file = {
                        file: {file: element.path + "||" + element.base64},
                    };
                    filesArray.push(file);
                }
            });
            json.new_files = filesArray;
            dispatch(
                TimesheetActions.patchSpecificCra(
                    monthEntity.month,
                    monthEntity.year,
                    JSON.stringify(json)
                )
            ).then(() => {
                dispatch(setLoading(false));
                setSubmitting(false);
                let successMessage;
                if (files.length > 1) {
                    successMessage = "Vos fichiers ont bien été envoyés";
                } else {
                    successMessage = "Votre fichier a bien été envoyé";
                }
                dispatch(TimesheetActions.notifySuccess(successMessage));
                changeOverlayContent(null);
                return Promise.resolve(
                    dispatch(TimesheetActions.clearDaysSelection())
                ).then(() => {
                    dispatch(setLoading(false));
                    Promise.all([
                        dispatch(
                            TimesheetActions.fetchEntireMonth(
                                monthEntity.month,
                                monthEntity.year
                            )
                        ),
                        dispatch(
                            TimesheetActions.notifySuccess(
                                "Votre déclaration a été envoyée"
                            )
                        ),
                    ]);
                });
            });
        }
    };

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-confirm-send">
                <div
                    className="lb-confirm-send-header"
                    style={{textTransform: "uppercase"}}
                >
                    Déposer le cra spécifique
                </div>
                <SpecificCRAContainer
                    files={files}
                    setFiles={setFiles}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    special={special}
                    setSpecial={setSpecial}
                    certified={certified}
                    setCertified={setCertified}
                    afterSubmit={true}
                    touched={touched}
                    setTouched={setTouched}
                />
                <div className="lb-confirm-send-actions">
                    <div
                        onClick={() => changeOverlayContent(null)}
                        className="lb-confirm-send-cancel"
                    >
                        Annuler
                    </div>
                    {/*<div*/}
                    {/*    onClick={handleSubmit}*/}
                    {/*    className={*/}
                    {/*        "lb-confirm-send-confirm" +*/}
                    {/*        (!certified || submitting || !touched*/}
                    {/*            ? " disabled-btn"*/}
                    {/*            : "")*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    Déposer le CRA spécifique*/}
                    {/*</div>*/}
                    <Button
                        onClick={handleSubmit}
                        className={
                            "lb-confirm-send-confirm" +
                            (!certified || submitting || !touched
                                ? " disabled-btn"
                                : "")
                        }
                        text={"Déposer le CRA spécifique"}
                        processable={true}
                        isProcessing={submitting}
                    />
                </div>
            </div>
        </Lightbox>
    );
};

export default DepositCRAContainer;
