import React, {useEffect, useState} from "react";

const AndroidProcedure = () => {
    return <div>
        <ol>
            <li>Dans Chrome, rendez-vous sur <a href="https://mysynchrone.fr">https://mysynchrone.fr</a></li>
            <li>Appuyez sur le bouton <img src="/img/install/three-dots.svg" alt=""/> en haut à droite de
                l'écran
            </li>
            <li>Appuyez sur "<img src="/img/install/add-to-home-screen.svg" alt=""/> Ajouter à l'écran d'accueil"</li>
            <li>Appuyez sur "Installer"</li>
            <li>L'application est maintenant installée</li>
        </ol>
    </div>
}

export default AndroidProcedure;
