import { combineReducers } from "redux";
import {
    OPEN_MANAGERS,
    CLOSE_MANAGERS,
    TEAM_PLANNING_SUCCESS,
    TEAM_PLANNING_WEEK_SUCCESS,
    TEAM_PLANNING_REQUEST,
    TEAM_PLANNING_WEEK_REQUEST
} from "actions/TeamPlanning";
import User from "entities/User";

export const entities = (
    state = {
        managees: [],
    },
    action
) => {
    switch (action.type) {
        case TEAM_PLANNING_REQUEST:
        case TEAM_PLANNING_WEEK_REQUEST:
            return {
                ...state,
                managees: []
            }
        case TEAM_PLANNING_SUCCESS:
        case TEAM_PLANNING_WEEK_SUCCESS:
            let users = [];
            if (action.response.entities.user) {
                let listArray = Object.values(action.response.entities.user);
                listArray.forEach((element) => {
                    users.push(new User(element));
                });
            }
            return {
                ...state,
                managees: users,
            };

        default:
            return state;
    }
};

const openManagers = (state = [], action) => {
    const { type, matricules } = action;

    switch (type) {
        case OPEN_MANAGERS:
            return [...state, ...matricules];

        case CLOSE_MANAGERS:
            return state.filter(manager => !matricules.includes(manager));

        default:
            return state;
    }
};

export default combineReducers({
    openManagers,
    entities,
});
