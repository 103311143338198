import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as UserActions from "actions/User";
import * as ValidationActions from "actions/Validation";
import PersonTypeBadge from "../common/PersonTypeBadge";
import './CRAContainerMobile.scss'
import PageHeader from "../../common/PageHeader";
import {monthInLetter} from "../../../utils/Utils";
import {Link} from "react-router-dom";

const CRAContainerMobile = (props) => {
    const me = useSelector((state) => state.user.me);
    const months = useSelector((state) => state.validation.months);
    const dispatch = useDispatch()

    const initFilters = () => {
        let year = me.currentPeriod.year.toString();
        let month = me.currentPeriod.month.toString();
        let periodString = year + "-" + month.padStart(2, "0");
        let defaultPeriod = {
            value: periodString,
            label: periodString,
        };

        let filters = {
            period: defaultPeriod.value,
            matricules: null,
            states: "submitted",
            company: null,
            types: null,
            manager: me.matricule,
            particularities: [],
        };

        return filters;
    }
    const [filters] = useState(initFilters());

    const loadList = () => {
        dispatch(
            ValidationActions.fetchCRAList(
                filters,
                25,
                1
            )
        );
    };

    useEffect(() => {
        if (me.loaded) {
            loadList();
        }
    }, [me.loaded]);

    return <div id="page-container">
        <PageHeader title="Validation CRA" type="timesheet"/>
        <div
            className="cra-month-header-mobile">{monthInLetter(me.currentPeriod.month) + ' ' + me.currentPeriod.year.toString()}</div>
        {months.length > 0 ? (
            months.sort((a, b) => {
                return a.user.lastname.localeCompare(b.user.lastname);
            }).map((month, key) => {

                return <Link
                    to={`/backend/cra/${month.id}/${month.month}/${month.year}/${month.user.matricule}`}
                    key={key}
                    className="person-row-mobile-link"
                >
                    <div className="person-row-mobile" key={key}>
                        <div className="person-row-mobile-details">
                            <div
                                className="person-row-mobile-name">{month.user.firstname + ' ' + month.user.lastname}</div>
                            <PersonTypeBadge personType={month.user.personType}/>
                            <div className="person-row-mobile-matricule">Matricule : {month.user.matricule}</div>
                        </div>
                        <div>
                            <i className="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </Link>
            })
        ) : <div className="empty-validation-page">Aucun CRA à valider</div>}
    </div>
}

export default CRAContainerMobile
