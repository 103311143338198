import React, {useEffect} from "react";
import {Link} from "react-router-dom";

import "./HomepagePost.scss";

const HomepagePost = (props) => {
    const loadJuicerJs = () => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "juicer-js";
        script.src = "https://assets.juicer.io/embed.js";
        document.body.appendChild(script);
    };

    useEffect(() => {
        loadJuicerJs()
    }, [])

    return (
        <div className="home-news-container">
            {
                <>
                    <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css"/>
                    <ul className="juicer-feed" data-feed-id="synchronefr" data-origin="embed-code" data-per="1"
                        data-pages="1" data-columns="1" data-filter="Instagram" data-overlay="false"></ul>
                    <div className="show-more">
                        <Link to="/actus">Voir toutes nos actualités</Link>
                    </div>

                </>
            }
        </div>
    );
};

export default HomepagePost;
