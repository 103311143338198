import store from "store/ConfigureStore";
import HalfDay from "entities/HalfDay";
import { isEmpty } from "utils/Utils";

import { useSelector } from "react-redux";

class HalfDayRepository {
    findForCurrentMonth = () => {
        let halfdays = store.getState().validation.craDisplayMonth?.halfdays;
        if (isEmpty(halfdays)) {
            halfdays = store.getState().timesheet.entities.month?.halfdays;
        }

        if (!isEmpty(halfdays)) {
            const result = [];
            let array = Object.values(halfdays);
            array.forEach((element) => {
                result.push(element);
            });

            return result;
        }

        return;
    };

    findWithoutHook = (id) => {
        let halfdays = store.getState().validation.craDisplayMonth?.halfdays;
        if (isEmpty(halfdays)) {
            halfdays = store.getState().timesheet.entities.month?.halfdays;
        }

        if (!isEmpty(halfdays)) {
            let result = null;
            let array = Object.values(halfdays);
            array.forEach((element) => {
                if (element.id === id) {
                    result = element;
                }
            });
            return result;
        }

        return;
    };

    find = (id) => {
        return useSelector((state) => {
            let halfdays = state.validation.craDisplayMonth?.halfdays;
            if (isEmpty(halfdays)) {
                halfdays = state.timesheet.entities.month?.halfdays;
            }
            if (!isEmpty(halfdays)) {
                let result = null;
                let array = Object.values(halfdays);
                array.forEach((element) => {
                    if (element.id === id) {
                        result = element;
                    }
                });
                return result;
            }

            return;
        });
    };
}

export default HalfDayRepository;
