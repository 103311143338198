import React, {useState} from "react";
import {Formik, Form, useFormikContext, yupToFormErrors} from "formik";
import * as Yup from "yup";
import InputType from "components/TimesheetContainer/Forms/Event/StepActions/InputType";
import Comment from "components/TimesheetContainer/Forms/Event/StepActions/Comment";
import OvertimeDaysContainer from "components/TimesheetContainer/Forms/Event/StepActions/OvertimeDaysContainer";
import * as TimesheetActions from "actions/Timesheet";

import {EntityRepository, DAY_REPOSITORY} from "store/EntityRepository";

import moment from "moment";
import "moment/locale/fr";

import "./Step3Overtime.scss";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {daysForOvertimeList} from "utils/Utils";

export const CONSTRAINT_INPUT = "constraint";
export const HOUR_INPUT = "hour";
export const TICKET_INPUT = "ticket";

Yup.setLocale({
    date: {
        default: "Format de date invalide",
    },
});

const Step3Overtime = (props) => {
    const {
        ignoreMisssion,
        constraintCategory,
        selectedOvertimeCode
    } = props

    const getSelectedInputType = () => {
        if (constraintCategory === "intervention") {
            return HOUR_INPUT;
        }
        return CONSTRAINT_INPUT;
    };

    const [overtimeType, setOvertimeType] = useState(getSelectedInputType());
    const dispatch = useDispatch();

    const dayRepo = EntityRepository().getRepository(DAY_REPOSITORY);
    const selectedDays = dayRepo.findSelectedDays();
    const initialDays = () => {
        const result = [];
        selectedDays.forEach((element) => {
            result.push({
                date: moment.parseZone(element.dateAt).format("YYYY-MM-DD"),
                hours: [{start: "", end: ""}],
                tickets: 0,
            });
        });

        return result;
    };

    const OvertimeForm = (props) => {
        const {
            values,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleBlur,
        } = useFormikContext();

        const {ignoreMission} = props

        const handleInputTypeChange = (e, inputValue) => {
            setFieldValue("type", inputValue);
            props.setOvertimeType(inputValue);
        };

        useEffect(() => {
            if (constraintCategory === "intervention") {
                setFieldValue("type", HOUR_INPUT);
                setOvertimeType(HOUR_INPUT);
            }
        }, [constraintCategory]);

        useEffect(() => {
            setFieldValue("code", selectedOvertimeCode.id);
        }, [selectedOvertimeCode]);

        useEffect(() => {
            setFieldValue("constraint_count", daysForOvertimeList(values, overtimeType).length);
        }, [])

        return (
            <Form noValidate context={{overtimeType}}>
                <InputType
                    handleClick={(e, id) => handleInputTypeChange(e, id)}
                    constraintCategory={constraintCategory}
                    ignoreMission={ignoreMission}
                />
                <OvertimeDaysContainer
                    overtimeType={overtimeType}
                    values={values}
                    selectedDays={selectedDays}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    constraintCategory={constraintCategory}
                    ignoreMission={ignoreMission}
                    isSubmitting={isSubmitting}
                />
                <Comment
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <div className="btn-container">
                    <button
                        className="next-btn"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Suivant
                    </button>
                </div>
            </Form>
        );
    };

    const sanitizeForm = (values) => {
        switch (values.type) {
            case CONSTRAINT_INPUT:
                // delete values.days;
                break;

            case HOUR_INPUT:
                for (let index = 0; index < values.days.length; index++) {
                    delete values.days[index].tickets;
                }
                break;

            case TICKET_INPUT:
                for (let index = 0; index < values.days.length; index++) {
                    delete values.days[index].hours;
                }
                break;
            default:
                break;
        }

        // return JSON.stringify(values);
        return values;
    };

    function after(ref, msg) {
        return this.test({
            name: "after",
            exclusive: false,
            message: msg || "${path} must be after ${reference}",
            params: {
                reference: ref.path,
            },
            test: function (value) {
                if (value === "00:00" && this.resolve(ref) > "00:00") {
                    return true;
                }
                return value > this.resolve(ref);
            },
        });
    }

    Yup.addMethod(Yup.string, "after", after);

    const schema = Yup.object().shape({
        type: Yup.string()
            .ensure()
            .required("Vous devez choisir un type d'activité complémentaire")
            .oneOf(
                [CONSTRAINT_INPUT, HOUR_INPUT, TICKET_INPUT],
                "Vous devez choisir un type d'activité complémentaire"
            ),
        constraint_count: Yup.number().when(
            "$constraintCategory",
            (constraintCategory, schema) => {
                if (
                    constraintCategory === "availability" &&
                    overtimeType !== HOUR_INPUT
                ) {
                    return schema
                        .required("Vous devez remplir le nombre d'astreintes")
                        .positive("Vous devez remplir le nombre d'astreintes");
                }
            }
        ),
        start_at: Yup.date().required(
            "Vous devez renseigner une date de début"
        ),
        end_at: Yup.date().required("Vous devez renseigner une date de fin"),
        days: Yup.array().when("$overtimeType", (overtimeType, schema) => {
            if (overtimeType !== CONSTRAINT_INPUT) {
                return schema.of(
                    Yup.object().shape({
                        date: Yup.string(),
                        tickets: Yup.number()
                            .transform(value => (isNaN(value) ? undefined : value))
                            .required("Veuillez saisir un nombre de tickets")
                            .when(
                                "$overtimeType",
                                (overtimeType, schema) => {
                                    if (overtimeType === TICKET_INPUT) {
                                        return schema.min(
                                            1,
                                            "Veuillez saisir un nombre de tickets"
                                        );
                                    }
                                    return schema;
                                }
                            ),
                        hours: Yup.array().when(
                            "$overtimeType",
                            (overtimeType, schema) => {
                                if (overtimeType === HOUR_INPUT) {
                                    return schema.required().of(
                                        Yup.object().shape({
                                            start: Yup.string()
                                                .required(
                                                    "Vous devez fournir un horaire de début"
                                                )
                                                .matches(
                                                    /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
                                                    "Le format de l'heure est incorrect"
                                                ),
                                            // .before(Yup.ref("end")),
                                            end: Yup.string()
                                                .required(
                                                    "Vous devez fournir un horaire de fin"
                                                )
                                                .after(
                                                    Yup.ref("start"),
                                                    "L'horaire de fin doit être supérieur à l'horaire de début"
                                                )
                                                .matches(
                                                    /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
                                                    "Le format de l'heure est incorrect"
                                                ),
                                        })
                                    );
                                }
                                return schema;
                            }
                        ),
                    })
                );
            }
        }),
    });

    return (
        <div className="step3-contents">
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                    type: CONSTRAINT_INPUT,
                    constraint_count: 0,
                    days: initialDays(),
                    start_at: moment
                        .parseZone(props.startAt)
                        .format("YYYY-MM-DD"),
                    end_at: moment.parseZone(props.endAt).format("YYYY-MM-DD"),
                    code: 0,
                    comment: "",
                }}
                validate={(values, props) => {
                    return schema
                        .validate(values, {
                            abortEarly: false,
                            context: {overtimeType, constraintCategory},
                        })
                        .then(() => {
                            return true;
                        })
                        .catch((err) => {
                            dispatch(
                                TimesheetActions.notifyError(err.errors[0])
                            );
                            return yupToFormErrors(err);
                        });
                }}
                // validationSchema={}
                onSubmit={(values, {setSubmitting}) => {
                    let json = sanitizeForm(values);
                    props.setOvertimeFormValues(json);
                    props.nextStep("hours-not-worked");
                    setSubmitting(false);
                }}
            >
                {({errors, isSubmitting}) => (
                    <OvertimeForm setOvertimeType={setOvertimeType} ignoreMission={ignoreMisssion}/>
                )}
            </Formik>
        </div>
    );
};

export default Step3Overtime;
