import React, {Fragment, useEffect, useState} from "react";
import "./Actions.scss";
import {useDispatch, useSelector} from "react-redux";
import * as TimesheetActions from "actions/Timesheet";
import {changeOverlayContent} from "../../../events/OverlayEvents";
import ConfirmAskForExtraActivity from "../../TimesheetContainer/Calendar/ConfirmAskForExtraActivity";
import {setLoading} from "../../../actions/Common";
import * as SettingsActions from "actions/Settings";
import {useMonth} from "../../../hooks/useMonth";
import ExtraActivitiesWeekSelection from "./ExtraActivitiesWeekSelection";

const Actions = () => {
    const dispatch = useDispatch();
    const [classNames, setClassNames] = useState(['btn-action'])
    const craMode = useSelector((state) => state.timesheet.craMode)
    const me = useSelector((state) => state.user.me);
    // const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const monthEntity = useMonth()
    const remoteDays = useSelector((state) => state.timesheet.remoteDays);

    const year = monthEntity.year
    const month = monthEntity.month

    const handleToggle = () => {
        if (craMode !== 'view') {
            dispatch(TimesheetActions.viewMode(true))
        } else {
            dispatch(TimesheetActions.editMode(true))
        }
    }

    const handleRemoteMode = () => {
        if (!monthEntity.submitted) {
            dispatch(TimesheetActions.remoteMode(true))
        }
    }

    const handleViewMode = () => {
        dispatch(TimesheetActions.viewMode(true))
        dispatch(TimesheetActions.setRemoteDays(monthEntity.remoteDays))
    }

    const handleSubmitRemoteDays = () => {
        if (!monthEntity.submitted) {
            dispatch(setLoading(true));
            dispatch(SettingsActions.patchRemoteDaysForMonth(month, year, JSON.stringify(remoteDays))).then(() => {
                handleViewMode()
                dispatch(TimesheetActions.notifySuccess('Vos jours de télétravail ont été enregistrés avec succès'))
                dispatch(setLoading(false))
                dispatch(TimesheetActions.fetchEntireMonth(month, year));
            })
        }
    }

    const handleExtraActivityRequest = () => {
        changeOverlayContent(<ConfirmAskForExtraActivity monthEntity={monthEntity}/>)
    }

    const handleExtraActivity = () => {
        changeOverlayContent(<ExtraActivitiesWeekSelection monthEntity={monthEntity}/>)
    }

    const getClasses = (active) => {
        const classes = ['btn-action-container']
        if (active) {
            classes.push('action-visible')
        }

        return classes.join(' ')
    }

    useEffect(() => {
        const classes = ['btn-action']
        const active = craMode === 'edit'
        if (active) {
            classes.push('active')
        }
        setClassNames(classes.join(' '))
    }, [craMode])

    const extraActivityButton = () => {
        if (monthEntity.canExtraActivitiesAtLeastOneWeek) {
            return <div className={getClasses(craMode !== 'view')} onClick={handleExtraActivity}>
                <div className="btn-action-label">Saisir une activité complémentaire</div>
                <div className="btn-action">
                    <i className="fal fa-repeat"></i>
                </div>
            </div>
        } else if (monthEntity.canRequestExtraActivities) {
            return <div className={getClasses(craMode !== 'view')} onClick={handleExtraActivityRequest}>
                <div className="btn-action-label">Demander la saisie d'une activité complémentaire</div>
                <div className="btn-action">
                    <i className="fal fa-repeat"></i>
                </div>
            </div>
        }
    }

    return <div className={"mobile-actions-container" + (monthEntity.submitted ? " actions-hidden" : "")}>
        {craMode !== 'remote' ? <>
            <div className="actions-container">
                <div className={getClasses(craMode === 'view')} onClick={handleRemoteMode}>
                    <div
                        className="btn-action-label">{me.type === "contractor" ? "Définir mon travail à distance" : "Définir mes jours télétravaillés"}</div>
                    <div className="btn-action">
                        <i className="fa fa-home"></i>
                    </div>
                </div>
                {extraActivityButton()}
            </div>
            <div className={classNames} onClick={handleToggle}>
                <i className="fal fa-plus"></i>
            </div>
        </> : <>
            <div className="btn-action no-animation" onClick={handleSubmitRemoteDays}>
                <i className="fal fa-check"></i>
            </div>
            <div className="btn-action no-animation" onClick={handleViewMode}>
                <i className="fal fa-times"></i>
            </div>
        </>}
    </div>
};

export default Actions;

