import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import HomepageContainer from "../../HomepageContainer/HomepageContainer";
import HomePageMobile from "../../mobile/HomePageMobile/HomePageMobile";
import * as TimesheetActions from "actions/Timesheet";

const HomePage = () => {
    const isMobile = useSelector((state) => state.common.isMobile)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(TimesheetActions.setPageTransitionId(""))
    }, [])

    return isMobile ? <HomePageMobile /> : <HomepageContainer />
};

export default HomePage;
