import React, {Fragment} from "react";
import {ErrorMessage, Field} from "formik";

import {
    CONSTRAINT_INPUT,
    HOUR_INPUT,
    TICKET_INPUT,
} from "components/TimesheetContainer/Forms/Event/StepActions/Step3Overtime";
import RadioButton from "../../../../common/RadioButton";

const InputType = (props) => {

    const {ignoreMission} = props



    return (
        <div
            className={
                "input-type-radio-group" +
                (ignoreMission ? " hidden " : "") +
                (props.constraintCategory === "intervention" ? " hidden" : "")
            }
        >
            <div className="input-type-radio-label">Saisie :</div>
            <div className="input-type-radios">
                <Field
                    className="input-type-radio"
                    component={RadioButton}
                    handleClick={props.handleClick}
                    name="type"
                    inputValue={CONSTRAINT_INPUT}
                    id={"inputType-" + CONSTRAINT_INPUT}
                    label="en nombre d'astreintes"
                />
                {!ignoreMission && <Fragment>
                    <Field
                        className="input-type-radio"
                        component={RadioButton}
                        handleClick={props.handleClick}
                        name="type"
                        inputValue={HOUR_INPUT}
                        id={"inputType-" + HOUR_INPUT}
                        label="en nombre d'heures"
                    />
                    <Field
                        className="input-type-radio"
                        component={RadioButton}
                        handleClick={props.handleClick}
                        name="type"
                        inputValue={TICKET_INPUT}
                        id={"inputType-" + TICKET_INPUT}
                        label="en tickets"
                    />
                </Fragment>}
            </div>
        </div>
    );
};

export default InputType;
