import React, {useEffect} from "react";
import {changeOverlayContent} from "events/OverlayEvents";
import ExtraActivitiesPopUp from "../../TimesheetContainer/Calendar/ExtraActivitiesPopUp";
import {useSelector} from "react-redux";

const ExtraActivityWeek = (props) => {
    const {week, monthEntity} = props;
    const me = useSelector((state) => state.user.me)

    const isClickable = week.can_extra_activities

    const handleAddExtraActivity = () => {
        if (isClickable) {
            const day = {
                dateAt: week.start
            }
            changeOverlayContent(<ExtraActivitiesPopUp day={day} monthEntity={monthEntity} type={me.type}/>);
        }
    }

    const classes = ["extra-week"]
    if (!isClickable) {
        classes.push("disabled")
    }

    return <div onClick={handleAddExtraActivity} className={classes.join(" ")}>Semaine du {week.start.format("DD/MM")} au {week.end.format("DD/MM")}</div>
}

export default ExtraActivityWeek
