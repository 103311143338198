import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Lightbox from "../../common/Lightbox";
import {changeOverlayContent} from "../../../events/OverlayEvents";
import * as TimesheetActions from "actions/Timesheet";
import "./CountersContainerMobile.scss"
import AbsenceHistoryLine from "./AbsenceHistoryLine";
import LightboxHeader from "../../common/LightboxHeader";

const CountersContainerMobile = () => {
    const vacationCount = useSelector((state) => state.user.vacationCount);
    const absenceHistory = useSelector((state) => state.timesheet.entities.absenceHistory);
    const me = useSelector((state) => state.user.me);
    const [visibleItems, setVisibleItems] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(25);
    const containerRef = useRef(null);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(TimesheetActions.fetchAbsenceHistory())
    }, []);

    useEffect(() => {
        setVisibleItems(absenceHistory.slice(0, itemsToShow));
    }, [absenceHistory, itemsToShow]);

    const loadMoreItems = () => {
        setItemsToShow((prev) => prev + 25);
    };

    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 100) {
            loadMoreItems();
        }
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    const CountersMobileLine = ({isTotal, text, count}) => {
        return <div className={`counters-mobile-counter-${isTotal ? 'total' : 'line'}`}>
            {isTotal && <span className="counters-mobile-counter-description">{text}</span>}
            <span className={`counters-mobile-counter-${isTotal ? '' : 'line-'}total-count`}>
                {count.toFixed(2)}
            </span>
            {!isTotal && <span className="counters-mobile-counter-line-description">{text}</span>}
        </div>
    }


    return <Lightbox className="lb-w1200" ref={containerRef}>
        <div className="lb-counters-mobile">
            <LightboxHeader>
                <div className="lb-counters-mobile-header">
                    <div className="counters-mobile-header-title">
                        Mes absences
                    </div>
                </div>
            </LightboxHeader>
            <div className={"lb-counters-mobile-content"}>
                <div className="lb-counters-mobile-content-counters">
                    <div className="counters-mobile-counters-header">Mes compteurs</div>
                    <div className="counters-mobile-counter">
                        <CountersMobileLine isTotal={true} text={"Solde CP :"}
                                            count={(vacationCount.cp + vacationCount.remainder + vacationCount.beforeJune)}/>
                        <CountersMobileLine isTotal={false} text={"CP N-1"} count={vacationCount.cp}/>
                        <CountersMobileLine isTotal={false} text={"CP N"} count={vacationCount.beforeJune}/>
                        {vacationCount.remainder > 0 && me.displayRemainderCounter &&
                            <CountersMobileLine isTotal={false} text={"CP reliquats"} count={vacationCount.remainder}/>}
                    </div>
                    <div className="counters-mobile-counter">
                        <CountersMobileLine isTotal={true} text={"Solde JRS :"}
                                            count={(vacationCount.rtt + vacationCount.rttRemainder)}/>
                        <CountersMobileLine isTotal={false} text={"JRS N"} count={vacationCount.rtt}/>
                        <CountersMobileLine isTotal={false} text={"JRS N-1"} count={vacationCount.rttRemainder}/>
                    </div>
                </div>
                <div className="lb-counters-mobile-content-history">
                    <div className="counters-mobile-history-header">Mon historique</div>
                    <div className="counters-mobile-history-caption">
                        <div className="counters-mobile-history-caption-item">Refusé :<i className="far fa-times"></i>
                        </div>
                        <div className="counters-mobile-history-caption-item">En attente :<i
                            className="far fa-circle"></i></div>
                        <div className="counters-mobile-history-caption-item">Validé :<i className="fas fa-circle"></i>
                        </div>
                    </div>
                    <div className="counters-mobile-history-content">
                        {visibleItems.map((absence, key) => {
                            return <AbsenceHistoryLine key={key} event={absence}/>
                        })}
                        {/*<LoadMore loadMoreItems={loadMoreItems}/>*/}
                    </div>
                </div>
            </div>
        </div>
    </Lightbox>;
}

export default CountersContainerMobile
