import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import * as TimesheetActions from "actions/Timesheet";
import {useEffect, useState} from "react";
import {EntityRepository, HALFDAY_REPOSITORY} from "../store/EntityRepository";

export const useDaySelector = () => {
    const dispatch = useDispatch();
    const [canOvertime, setCanOvertime] = useState(false);
    const craMode = useSelector((state) => state.timesheet.craMode);
    const me = useSelector((state) => state.user.me);
    const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const overtimeCodes = useSelector((state) => state.timesheet.entities.overtimeCodes);
    const boundingEvents = useSelector((state) => state.timesheet.entities.boundingEvents);
    const selectedStartDay = useSelector((state) => state.timesheet.selectedStartDay);
    const currentPeriod = useSelector((state) => state.period.entities.periodLite?.currentPeriod);
    const nextEvent = boundingEvents.next;
    const days = monthEntity.days;
    const halfDayRepo = EntityRepository().getRepository(HALFDAY_REPOSITORY);

    const checkCanOvertime = () => {
        if (overtimeCodes.length === 1) {
            return !(overtimeCodes[0].code === 'ASTAND' && !monthEntity.canExtraActivities);
        } else {
            return overtimeCodes.length > 1;
        }
    }

    useEffect(() => {
        setCanOvertime(checkCanOvertime());
    }, [overtimeCodes]);

    useEffect(() => {
        if (craMode !== 'edit') {
            dispatch(TimesheetActions.clearDaysSelection());
        }
    }, [craMode]);

    const formattedMonth = (month, year) => {
        return `${month < 10 ? `0${month}` : month}-${year}`;
    };

    const isSelectable = (day) => {
        if (!selectedStartDay) {
            return true;
        }

        const dayDate = moment.parseZone(day.dateAt).format("YYYY-MM-DD");
        const startDayDate = moment.parseZone(selectedStartDay.dateAt).format("YYYY-MM-DD");

        return dayDate >= startDayDate &&
            moment(day.dateAt).month() === moment(selectedStartDay.dateAt).month() &&
            (!nextEvent || dayDate < moment.parseZone(nextEvent.startAt).format("YYYY-MM-DD"));
    };

    // const selectable = (day) => {
    //     if (craMode !== 'edit' || day.workingState !== "worked" || monthEntity.locked || day.workingState === "notCurrentMonth") {
    //         return false;
    //     }
    //
    //     let absenceOnly = formattedMonth(monthEntity.month, monthEntity.year) > currentPeriod
    //
    //     if (selectedStartDay) {
    //         if (
    //             moment.parseZone(day.dateAt).format("YYYY-MM-DD") >=
    //             moment
    //                 .parseZone(selectedStartDay.dateAt)
    //                 .format("YYYY-MM-DD") &&
    //             moment.parseZone(day.dateAt).month() ===
    //             moment.parseZone(selectedStartDay.dateAt).month()
    //         ) {
    //             if (!nextEvent || (canOvertime && !absenceOnly)) {
    //                 return true;
    //             } else {
    //                 if (
    //                     moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
    //                     moment.parseZone(nextEvent.startAt).format("YYYY-MM-DD")
    //                 ) {
    //                     return true;
    //                 } else if (
    //                     moment.parseZone(day.dateAt).format("YYYY-MM-DD") ===
    //                     moment.parseZone(nextEvent.startAt).format("YYYY-MM-DD")
    //                 ) {
    //                     if (nextEvent.startMoment === "pm") {
    //                         return true;
    //                     }
    //                 }
    //             }
    //         }
    //         return false;
    //     }
    //
    //     if (!absenceOnly || (absenceOnly && me.type !== "contractor")) {
    //         if (
    //             (day.workingState === "worked" &&
    //                 (!me.endAt ||
    //                     (me.endAt &&
    //                         moment
    //                             .parseZone(day.dateAt)
    //                             .format("YYYY-MM-DD") <=
    //                         moment
    //                             .parseZone(me.endAt)
    //                             .format("YYYY-MM-DD"))) &&
    //                 moment.parseZone(day.dateAt).format("YYYY-MM-DD") >=
    //                 moment
    //                     .parseZone(me.startAt)
    //                     .format("YYYY-MM-DD") &&
    //                 (halfDayRepo.findWithoutHook(day.am) === null || halfDayRepo.findWithoutHook(day.pm) === null)) ||
    //             (canOvertime && !absenceOnly) ||
    //             monthEntity.specialTime
    //         ) {
    //             return true
    //         }
    //     }
    //
    //     return false;
    // };

    const selectable = (day) => {
        if (craMode !== 'edit' || monthEntity.locked || day.workingState === "notCurrentMonth") {
            return false;
        }

        const dayDateFormatted = moment.parseZone(day.dateAt).format("YYYY-MM-DD");
        const selectedStartDayDateFormatted = selectedStartDay ? moment.parseZone(selectedStartDay.dateAt).format("YYYY-MM-DD") : null;
        const nextEventStartDateFormatted = nextEvent ? moment.parseZone(nextEvent.startAt).format("YYYY-MM-DD") : null;
        const isAbsenceOnly = formattedMonth(monthEntity.month, monthEntity.year) > currentPeriod;

        if (selectedStartDay) {
            if (dayDateFormatted >= selectedStartDayDateFormatted &&
                moment.parseZone(day.dateAt).month() === moment.parseZone(selectedStartDay.dateAt).month()) {
                if (!nextEvent || (canOvertime && !isAbsenceOnly)) {
                    return true;
                } else if (dayDateFormatted < nextEventStartDateFormatted) {
                    return true;
                } else if (dayDateFormatted === nextEventStartDateFormatted && nextEvent.startMoment === "pm") {
                    return true;
                }
            }
            return false;
        }

        if (!isAbsenceOnly || (isAbsenceOnly && me.type !== "contractor")) {
            const meStartAtFormatted = moment.parseZone(me.startAt).format("YYYY-MM-DD");
            const meEndAtFormatted = me.endAt ? moment.parseZone(me.endAt).format("YYYY-MM-DD") : null;
            const isHalfDayEmpty = halfDayRepo.findWithoutHook(day.am) === null || halfDayRepo.findWithoutHook(day.pm) === null;

            if ((day.workingState === "worked" &&
                    (!me.endAt || dayDateFormatted <= meEndAtFormatted) &&
                    dayDateFormatted >= meStartAtFormatted &&
                    isHalfDayEmpty) ||
                (canOvertime && !isAbsenceOnly) ||
                monthEntity.specialTime) {
                return true;
            }
        }

        return false;
    };

    const selectDay = (day) => {
        if (craMode === 'edit' && selectable(day)) {
            let daysToAdd = [day.id];

            if (selectedStartDay) {
                for (let element of Object.values(days)) {
                    const elementDate = moment.parseZone(element.dateAt).format("YYYY-MM-DD");
                    const selectedStartDayDate = moment.parseZone(selectedStartDay.dateAt).format("YYYY-MM-DD");

                    if (element.worked || canOvertime) {
                        if (elementDate > selectedStartDayDate && elementDate < moment.parseZone(day.dateAt).format("YYYY-MM-DD")) {
                            daysToAdd.push(element.id);
                        }
                    }
                }
            }

            if (!selectedStartDay) {
                dispatch(TimesheetActions.selectStartDay(day));
                if (day.am) {
                    dispatch(TimesheetActions.lockStart());
                }
                dispatch(TimesheetActions.checkEventBoundaries(moment.parseZone(day.dateAt).format("YYYY-MM-DD")));
            } else {
                dispatch(TimesheetActions.selectEndDay(day));
                if (day.pm) {
                    dispatch(TimesheetActions.lockEnd());
                }
            }

            dispatch(TimesheetActions.selectDays(daysToAdd));
        }
    };

    return {
        selectDay,
        selectable,
        isSelectable
    };
};
