import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";
import { apiOptions } from "utils/Api";
import * as CommonActions from "actions/Common";
import { resetContact } from "actions/Contact";

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";
export const fetchAll = (periodRange, manager) => {
    return {
        // Types of actions to emit before and after
        types: [USER_REQUEST, USER_SUCCESS, USER_FAILURE],
        needLogin: true,
        // Perform the fetching:
        callAPI: () => {
            if (manager) {
                return fetch(
                    `${Params.API_ROOT}user?maxResults=10000&minDate=${
                        periodRange["startDate"]
                    }&maxDate=${periodRange["endDate"]}${
                        manager && "&manager=" + manager
                    }`,
                    apiOptions("GET")
                );
            }
            return fetch(
                `${Params.API_ROOT}user?maxResults=10000&minDate=${periodRange["startDate"]}&maxDate=${periodRange["endDate"]}`,
                apiOptions("GET")
            );
        },
        schema: Schemas.USER_ARRAY,
    };
};

export const FIND_USER_REQUEST = "FIND_USER_REQUEST";
export const FIND_USER_SUCCESS = "FIND_USER_SUCCESS";
export const FIND_USER_FAILURE = "FIND_USER_FAILURE";
export const findUser = (userId) => {
    return {
        // Types of actions to emit before and after
        types: [FIND_USER_REQUEST, FIND_USER_SUCCESS, FIND_USER_FAILURE],
        needLogin: true,
        // Perform the fetching:
        callAPI: () => {
            return fetch(`${Params.API_ROOT}user/${userId}`, apiOptions("GET"));
        },
        schema: Schemas.VALIDATION_USER,
    };
};

export const LIST_REQUEST = "LIST_REQUEST";
export const LIST_SUCCESS = "LIST_SUCCESS";
export const LIST_FAILURE = "LIST_FAILURE";
export const fetchUserList = (runAs) => {
    return {
        types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}user?all=1${
                    runAs ? "&visible=1&maxResults=10000" : ""
                }`,
                apiOptions("GET")
            );
        },
        schema: Schemas.LIST_USER_ARRAY,
    };
};

export const RUN_AS_LIST_REQUEST = "RUN_AS_LIST_REQUEST";
export const RUN_AS_LIST_SUCCESS = "RUN_AS_LIST_SUCCESS";
export const RUN_AS_LIST_FAILURE = "RUN_AS_LIST_FAILURE";
export const fetchRunAsList = () => {
    return {
        types: [RUN_AS_LIST_REQUEST, RUN_AS_LIST_SUCCESS, RUN_AS_LIST_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}user/run-as?maxResults=10000`,
                apiOptions("GET")
            );
        },
        schema: Schemas.RUN_AS_LIST_USER_ARRAY,
    };
};

export const MANAGER_LIST_REQUEST = "MANAGER_LIST_REQUEST";
export const MANAGER_LIST_SUCCESS = "MANAGER_LIST_SUCCESS";
export const MANAGER_LIST_FAILURE = "MANAGER_LIST_FAILURE";
export const fetchManagerList = () => {
    return {
        types: [
            MANAGER_LIST_REQUEST,
            MANAGER_LIST_SUCCESS,
            MANAGER_LIST_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}user/managers?visible=1&maxResults=10000`,
                apiOptions("GET")
            );
        },
        schema: Schemas.MANAGERS_LIST_ARRAY,
    };
};

export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_FAILURE = "ME_FAILURE";
export const fetchMe = () => {
    return {
        types: [ME_REQUEST, ME_SUCCESS, ME_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}user/me`, apiOptions("GET"));
        },
        schema: Schemas.USER,
    };
};

export const ME_HIERARCHY_REQUEST = "ME_HIERARCHY_REQUEST";
export const ME_HIERARCHY_SUCCESS = "ME_HIERARCHY_SUCCESS";
export const ME_HIERARCHY_FAILURE = "ME_HIERARCHY_FAILURE";
export const fetchMeHierarchy = () => {
    return {
        types: [
            ME_HIERARCHY_REQUEST,
            ME_HIERARCHY_SUCCESS,
            ME_HIERARCHY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}hierarchy/me`, apiOptions("GET"));
        },
        schema: Schemas.USER,
    };
};

export const OPT_OUT_EURO_2020_POP_UP_REQUEST =
    "OPT_OUT_EURO_2020_POP_UP_REQUEST";
export const OPT_OUT_EURO_2020_POP_UP_SUCCESS =
    "OPT_OUT_EURO_2020_POP_UP_SUCCESS";
export const OPT_OUT_EURO_2020_POP_UP_FAILURE =
    "OPT_OUT_EURO_2020_POP_UP_FAILURE";
export const optOutEuro2020 = () => {
    return {
        types: [
            OPT_OUT_EURO_2020_POP_UP_REQUEST,
            OPT_OUT_EURO_2020_POP_UP_SUCCESS,
            OPT_OUT_EURO_2020_POP_UP_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}user/opt-out-euro-2020`,
                apiOptions("POST")
            );
        },
        schema: Schemas.USER,
    };
};

export const fetchCurrentUser = () => {
    return (dispatch, getState) => {
        return dispatch(fetchMe()).then((data) => {
            dispatch(CommonActions.setAppLoading(false));
            dispatch(resetContact());
        });
        // .catch(() => logout());
    };
};

export const fetchAllUser = () => {
    return (dispatch, getState) => {
        return dispatch(fetchUserList(true));
    };
};
