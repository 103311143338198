import React, {useState, Fragment} from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import moment from "moment";
import "moment/locale/fr";

import * as UserActions from "actions/User";
import * as TimesheetActions from "actions/Timesheet";
import * as TeamPlanningActions from "actions/TeamPlanning";
import TeamPlanningHeader from "components/Backend/TeamPlanningContainer/TeamPlanningHeader";
import TeamPlanningTable from "components/Backend/TeamPlanningContainer/TeamPlanningTable/TeamPlanningTable";

import "./TeamPlanningContainer.scss";
import {setLoading} from "actions/Common";
import PageHeader from "../../common/PageHeader";

const TeamPlanningContainer = () => {
    const history = useHistory();
    const me = useSelector((state) => state.user.me);
    const isMobile = useSelector((state) => state.common.isMobile)

    const managees = useSelector(
        (state) => state.teamPlanning.entities.managees
    );
    const meHierarchy = useSelector((state) => state.user.meHierarchy);
    // const managees = useSelector((state) => state.user.users);
    const absences = useSelector((state) => state.validation.absences);
    const holidays = useSelector((state) => {
        if (state.timesheet.entities.month.days) {
            let days = Object.values(
                state.timesheet.entities.month.days
            ).filter((days) => days.workingState === "public_holiday");

            let result = [];

            days.map((element, key) => {
                let relevantDay = moment
                    .parseZone(element.dateAt)
                    .format("YYYY-MM-DD");
                result.push(relevantDay);
            });

            return result;
        }
    });
    const isLoading = useSelector((state) => state.common.isLoading);
    const dispatch = useDispatch();

    const openManager = (manager) => {
        let managers = [];
        managers.push(manager);

        dispatch(TeamPlanningActions.openManagers(managers));
    };

    const closeManager = (manager) => {
        // const findManageesMatricules = (array, manager) => {
        //     if (meHierarchy[manager]) {
        //         for (let i = 0; i < meHierarchy[manager].length; i++) {
        //             const element = meHierarchy[manager][i];
        //             array.push(element);
        //             findManageesMatricules(element);
        //         }
        //     }
        //
        //     return array;
        // };
        // let managers = [];
        // managers.push(manager);
        // findManageesMatricules(managers, manager);
        //
        // dispatch(TeamPlanningActions.closeManagers(managers));
    };

    const openManagers = useSelector(
        (state) => state.teamPlanning.openManagers
    );

    const [daysArray, setDaysArray] = useState([]);
    const {
        month = moment.parseZone().month() + 1,
        year = moment.parseZone().year(),
    } = useParams();

    const formattedMonth = (month, year) => {
        let monthString = "";
        if (month < 10) {
            monthString = `0${month}`;
        } else {
            monthString = month;
        }

        return year + "-" + monthString;
    };

    useEffect(() => {
        dispatch(setLoading(true));
        openManager(me.matricule);
        dispatch(TimesheetActions.fetchMonth(month, year)).then((data) => {
            let promises = [dispatch(UserActions.fetchMeHierarchy())];
            Promise.all(promises);
        });
        let filters = {
            period: formattedMonth(month, year),
            person: null,
            company: null,
            manager: null,
            states: ["submitted", "validated1", "validated2"],
            includeSickness: 1,
        };
        let tempArray = [];
        let periodRange = [];
        // periodRange["startDate"] = formattedMonth(month, year);
        // periodRange["endDate"] = formattedMonth(month, year);
        periodRange["period"] = formattedMonth(month, year);

        dispatch(TeamPlanningActions.fetchTeamPlanningForPeriod(periodRange)).then(() => dispatch(setLoading(false)));
        // dispatch(ValidationActions.fetchAbsencesForValidation(filters));
        const dayCursor = moment
            .parseZone(formattedMonth(month, year), "YYYY-MM")
            .startOf("month");
        let nextMonthNumber;
        if (parseInt(month) < 12) {
            nextMonthNumber = parseInt(month) + 1;
        } else {
            nextMonthNumber = 1;
        }

        while (dayCursor.format("M") !== String(nextMonthNumber)) {
            tempArray.push(dayCursor.format("YYYY-MM-DD"));
            dayCursor.add(1, "days");
        }
        setDaysArray(tempArray);
    }, []);

    const CaptionEntry = (props) => {
        const {color, title, opacity} = props;

        const styles = {
            backgroundColor: color ? color : "#a51160",
        };

        if (opacity) {
            styles.opacity = 0.5;
        }

        return (
            <div className="caption">
                <div className="caption-color" style={styles}></div>
                <div className="caption-title">{title}</div>
            </div>
        );
    };

    useEffect(() => {
        if (isMobile) {
            history.push("/backend/team-planning-by-week")
        }
    }, [isMobile]);

    const TeamPlanningTitle = () => {
        return <div className="cra-list-title-container">
                <span className="cra-list-title">
                    Voir le planning d'équipe
                </span>
            <div className="caption-container">
                <CaptionEntry
                    title="Absence validée par le Manager"
                    opacity={false}
                />
                <CaptionEntry
                    title="Absence en attente de validation"
                    opacity={true}
                />
            </div>
        </div>
    };

    return (
        <div className="team-planning-page">
            <div className="team-planning-container">
                {/*{!isLoading && (*/}
                <Fragment>
                    <TeamPlanningTitle/>
                    <TeamPlanningHeader month={month} year={year}/>
                    <TeamPlanningTable
                        daysArray={daysArray}
                        me={me}
                        openManagers={openManagers}
                        managees={managees}
                        month={month}
                        year={year}
                        absences={absences}
                        holidays={holidays}
                        openManager={openManager}
                        closeManager={closeManager}
                    />
                </Fragment>
                {/*)}*/}
            </div>
        </div>
    );
};

export default TeamPlanningContainer;
