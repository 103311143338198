import React from "react";
import DocumentsList from "components/DocumentContainer/DocumentsList";

const DocumentContainer = () => {
    return (
        <div id="page-container" className="backend">
            <div className="container">
                <DocumentsList isEdit={true} />
            </div>
        </div>
    );
};

export default DocumentContainer;
