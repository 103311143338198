import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

const selectOvertimeCodesFromMe = (state) => state.timesheet.entities.eventCodes;
const selectOvertimeCodesFromUser = (state) => state.validation.craEventCodes;

const selectValidOvertimeCodes = createSelector(
    [selectOvertimeCodesFromMe, selectOvertimeCodesFromUser],
    (fromMe, fromUser) => {
        const isValidState = (state) => state !== null && Object.keys(state).length > 0;

        if (isValidState(fromUser)) {
            return fromUser;
        }

        if (isValidState(fromMe)) {
            return fromMe;
        }

        return {};
    }
);

export const useEventCodes = () => {
    return useSelector(selectValidOvertimeCodes);
};
