import React from "react";
import {useSelector} from "react-redux";
import TimesheetContainer from "../../TimesheetContainer/TimesheetContainer";
import TimesheetMobile from "../../mobile/TimesheetMobile/TimesheetMobile";

const TimesheetPage = () => {
    const isMobile = useSelector((state) => state.common.isMobile)

    return isMobile ? <TimesheetMobile /> : <TimesheetContainer />
};

export default TimesheetPage;
