import store from "store/ConfigureStore";
import Event from "entities/Event";
import { isEmpty } from "utils/Utils";
import moment from "moment";
import "moment/locale/fr";

import { useSelector } from "react-redux";

class EventRepository {
    find = (id, type) => {
        return useSelector((state) => {
            if (id) {
                // let events;

                let events = store.getState().validation.craDisplayMonth?.events;
                if (isEmpty(events)) {
                    events = store.getState().timesheet.entities.month?.events;
                }

                // if (type === "view") {
                //     events = state.validation.craDisplayMonth.events;
                // } else {
                //     events = state.timesheet.entities.month.events;
                // }

                if (!isEmpty(events)) {
                    let result = null;
                    let array = Object.values(events);
                    array.forEach((element) => {
                        if (element.id === id) {
                            result = element;
                        }
                    });
                    return result;
                }
            }

            return null;
        });
    };

    findWithoutHook = (id, type) => {
        if (id) {
            let events = store.getState().validation.craDisplayMonth?.events;
            if (isEmpty(events)) {
                events = store.getState().timesheet.entities.month?.events;
            }

            if (!isEmpty(events)) {
                    let result = null;
                    let array = Object.values(events);
                    array.forEach((element) => {
                        if (element.id === id) {
                            result = element;
                        }
                    });
                    return result;
                }
        }
        return null
    }

    findForCurrentMonth = (type) => {
        return useSelector((state) => {
            let events;
            if (type === "view") {
                events = state.validation.craDisplayMonth?.events;
            } else {
                events = state.timesheet.entities.month?.events;
            }

            if (!isEmpty(events)) {
                const result = [];
                let array = Object.values(events);
                array.forEach((element) => {
                    result.push(element);
                });

                return result;
            }
            return [];
        });
    };

    findForHalfDay = (id) => {
        return useSelector((state) => {
            if (id) {
                let events = state.timesheet.entities.month.events;
                if (!isEmpty(events)) {
                    let result = null;
                    let array = Object.values(events);
                    array.forEach((element) => {
                        if (element.id === id.event) {
                            result = element;
                        }
                    });
                    return result;
                }
            }

            return;
        });
    };

    findPreviousEvent = (id) => {
        return this.findAdjacentEvent(id, "previous");
    };

    findNextEvent = (id) => {
        return this.findAdjacentEvent(id, "next");
    };

    findAdjacentEvent = (id, modifier) => {
        let state = store.getState().timesheet.entities.month.events;

        if (!isEmpty(state)) {
            let result = null;
            let array = Object.values(state);
            array.sort((a, b) => {
                return (
                    moment.parseZone(a.startAt) - moment.parseZone(b.startAt)
                );
            });

            let indexOfEvent = 0;
            if (array.length > 1) {
                for (let index = 0; index < array.length; index++) {
                    const element = array[index];
                    if (element.id === id) {
                        indexOfEvent = index;
                    }
                }
                if (modifier === "next") {
                    if (indexOfEvent < array.length - 1) {
                        result = array[indexOfEvent + 1];
                    }
                } else if (modifier === "previous") {
                    if (indexOfEvent > 0) {
                        result = array[indexOfEvent - 1];
                    }
                }
            }

            return result;
        }

        return;
    };

    findForDateWithoutHook = (date, type) => {
        let events;
        if (type === "view") {
            events = store.getState().validation.craDisplayMonth.events;
        } else {
            events = store.getState().timesheet.entities.month.events;
        }

        if (!isEmpty(events)) {
            const result = [];
            let array = Object.values(events);
            array.forEach((element) => {
                if (
                    moment.parseZone(element.startAt).format("YYYY-MM-DD") <=
                        moment.parseZone(date).format("YYYY-MM-DD") &&
                    moment.parseZone(element.endAt).format("YYYY-MM-DD") >=
                        moment.parseZone(date).format("YYYY-MM-DD")
                ) {
                    result.push(element);
                }
            });

            return result;
        }

        return [];
    };
}

export default EventRepository;
