import {Schemas} from "middleware/Schemas";
import * as Params from "config/Parameters";

import {apiOptions} from "utils/Api";
import {setLoading} from "actions/Common";

import {buildUrl} from "utils/Utils";

export const USER_FILTER_REQUEST = "USER_FILTER_REQUEST";
export const USER_FILTER_SUCCESS = "USER_FILTER_SUCCESS";
export const USER_FILTER_FAILURE = "USER_FILTER_FAILURE";
export const filterUsers = (filters, type, maxResults, page) => {
    filters.maxResults = maxResults;
    filters.page = page;
    return {
        types: [USER_FILTER_REQUEST, USER_FILTER_SUCCESS, USER_FILTER_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(`${Params.API_ROOT}validation/${type}/user`, filters),
                apiOptions("GET")
            );
        },
        schema: Schemas.USER_ARRAY,
    };
};

export const ABSENCES_FOR_VALIDATION_REQUEST =
    "ABSENCES_FOR_VALIDATION_REQUEST";
export const ABSENCES_FOR_VALIDATION_SUCCESS =
    "ABSENCES_FOR_VALIDATION_SUCCESS";
export const ABSENCES_FOR_VALIDATION_FAILURE =
    "ABSENCES_FOR_VALIDATION_FAILURE";
export const fetchAbsencesForValidation = (filters) => {
    return {
        types: [
            ABSENCES_FOR_VALIDATION_REQUEST,
            ABSENCES_FOR_VALIDATION_SUCCESS,
            ABSENCES_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(`${Params.API_ROOT}validation/event/absence`, filters),
                apiOptions("GET")
            );
        },
        schema: Schemas.ABSENCES_FOR_VALIDATION_ARRAY,
    };
};

export const EVENT_CODES_FOR_VALIDATION_REQUEST =
    "EVENT_CODES_FOR_VALIDATION_REQUEST";
export const EVENT_CODES_FOR_VALIDATION_SUCCESS =
    "EVENT_CODES_FOR_VALIDATION_SUCCESS";
export const EVENT_CODES_FOR_VALIDATION_FAILURE =
    "EVENT_CODES_FOR_VALIDATION_FAILURE";
export const fetchEventCodesForValidation = (filters) => {
    return {
        types: [
            EVENT_CODES_FOR_VALIDATION_REQUEST,
            EVENT_CODES_FOR_VALIDATION_SUCCESS,
            EVENT_CODES_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(`${Params.API_ROOT}validation/event-code`, filters),
                apiOptions("GET")
            );
        },
        schema: Schemas.EVENT_CODES_FOR_VALIDATION_ARRAY,
    };
};

export const fetchAbsenceList = (filters, maxResults, page) => {
    return (dispatch) =>
        dispatch(filterUsers(filters, "event/absence", maxResults, page)).then(
            dispatch(setLoading(false))
        );
};

export const fetchAbsenceListForMobile = (matricule) => {
    const filters = {
        period: "",
        types: null,
        person: null,
        company: null,
        manager: matricule,
        states: ["submitted"],
        minDate: null,
        maxDate: null,
    }
    return (dispatch) =>
        dispatch(fetchAbsenceList(filters, 25, 1)).then(
            dispatch(setLoading(false))
        );
};

export const MONTHS_FOR_VALIDATION_REQUEST = "MONTHS_FOR_VALIDATION_REQUEST";
export const MONTHS_FOR_VALIDATION_SUCCESS = "MONTHS_FOR_VALIDATION_SUCCESS";
export const MONTHS_FOR_VALIDATION_FAILURE = "MONTHS_FOR_VALIDATION_FAILURE";
export const fetchMonthsForValidation = (filters) => {
    return (dispatch) => {
        return dispatch({
            types: [
                MONTHS_FOR_VALIDATION_REQUEST,
                MONTHS_FOR_VALIDATION_SUCCESS,
                MONTHS_FOR_VALIDATION_FAILURE,
            ],
            needLogin: true,
            callAPI: () => {
                return fetch(
                    buildUrl(`${Params.API_ROOT}validation/month`, filters),
                    apiOptions("GET")
                );
            },
            schema: Schemas.MONTHS_FOR_VALIDATION_ARRAY,
        });
    };
};

export const fetchCRAList = (filters, maxResults, page) => {
    return (dispatch) => {
        filters.maxResults = maxResults;
        filters.page = page;
        let realFilters = {...filters};
        if (realFilters.particularities) {
            realFilters.particularities.map((element) => {
                realFilters[element] = 1;
            });
            delete realFilters.particularities;
        }
        filters.maxResults = maxResults;
        filters.page = page;
        dispatch(fetchMonthsForValidation(realFilters)).then(() =>
            dispatch(setLoading(false))
        );
    };
};

export const FETCH_PERIOD_REQUEST = "FETCH_PERIOD_REQUEST";
export const FETCH_PERIOD_SUCCESS = "FETCH_PERIOD_SUCCESS";
export const FETCH_PERIOD_FAILURE = "FETCH_PERIOD_FAILURE";
export const fetchPeriods = () => {
    return {
        types: [
            FETCH_PERIOD_REQUEST,
            FETCH_PERIOD_SUCCESS,
            FETCH_PERIOD_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/period`,
                apiOptions("GET")
            );
        },
        schema: Schemas.PERIOD_ARRAY,
    };
};

export const LOCK_PERIOD_REQUEST = "LOCK_PERIOD_REQUEST";
export const LOCK_PERIOD_SUCCESS = "LOCK_PERIOD_SUCCESS";
export const LOCK_PERIOD_FAILURE = "LOCK_PERIOD_FAILURE";
export const lockPeriod = (periodId, json) => {
    return {
        types: [LOCK_PERIOD_REQUEST, LOCK_PERIOD_SUCCESS, LOCK_PERIOD_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/period/${periodId}`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.PERIOD_ARRAY,
    };
};

export const FORCE_SUBMIT_REQUEST = "FORCE_SUBMIT_REQUEST";
export const FORCE_SUBMIT_SUCCESS = "FORCE_SUBMIT_SUCCESS";
export const FORCE_SUBMIT_FAILURE = "FORCE_SUBMIT_FAILURE";
export const forceSubmit = (periodId, confirm) => {
    return {
        types: [
            FORCE_SUBMIT_REQUEST,
            FORCE_SUBMIT_SUCCESS,
            FORCE_SUBMIT_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/period/${periodId}/force-submit?confirm=${confirm}`,
                apiOptions("POST")
            );
        },
        payload: {},
        schema: confirm ? Schemas.MESSAGE : Schemas.USER_ARRAY,
    };
};

export const POST_EVENT_ACTION_REQUEST = "POST_EVENT_ACTION_REQUEST";
export const POST_EVENT_ACTION_SUCCESS = "POST_EVENT_ACTION_SUCCESS";
export const POST_EVENT_ACTION_FAILURE = "POST_EVENT_ACTION_FAILURE";
export const postEventAction = (json) => {
    return {
        types: [
            POST_EVENT_ACTION_REQUEST,
            POST_EVENT_ACTION_SUCCESS,
            POST_EVENT_ACTION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/event-action`,
                apiOptions("POST", json)
            );
        },
        schema: Schemas.EVENT_ARRAY,
    };
};

export const FETCH_EVENT_ACTION_REQUEST = "FETCH_EVENT_ACTION_REQUEST";
export const FETCH_EVENT_ACTION_SUCCESS = "FETCH_EVENT_ACTION_SUCCESS";
export const FETCH_EVENT_ACTION_FAILURE = "FETCH_EVENT_ACTION_FAILURE";
export const fetchEventAction = (eventId) => {
    return {
        types: [
            FETCH_EVENT_ACTION_REQUEST,
            FETCH_EVENT_ACTION_SUCCESS,
            FETCH_EVENT_ACTION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/event-action/${eventId}`,
                apiOptions("GET")
            );
        },
        schema: Schemas.EVENT_ACTION_ARRAY,
    };
};

export const FETCH_EVENT_FOR_VALIDATION_REQUEST =
    "FETCH_EVENT_FOR_VALIDATION_REQUEST";
export const FETCH_EVENT_FOR_VALIDATION_SUCCESS =
    "FETCH_EVENT_FOR_VALIDATION_SUCCESS";
export const FETCH_EVENT_FOR_VALIDATION_FAILURE =
    "FETCH_EVENT_FOR_VALIDATION_FAILURE";
export const fetchEventForValidationAction = (matricule, period) => {
    return {
        types: [
            FETCH_EVENT_FOR_VALIDATION_REQUEST,
            FETCH_EVENT_FOR_VALIDATION_SUCCESS,
            FETCH_EVENT_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/event/activity?matricule=${matricule}&period=${period}`,
                apiOptions("GET")
            );
        },
        schema: Schemas.EVENTS_FOR_VALIDATION_ARRAY,
    };
};

export const CHANGE_ACTIVITY_TYPE_REQUEST = "CHANGE_ACTIVITY_TYPE_REQUEST";
export const CHANGE_ACTIVITY_TYPE_SUCCESS = "CHANGE_ACTIVITY_TYPE_SUCCESS";
export const CHANGE_ACTIVITY_TYPE_FAILURE = "CHANGE_ACTIVITY_TYPE_FAILURE";
export const changeActivityTypeAction = (eventId, json) => {
    return {
        types: [
            CHANGE_ACTIVITY_TYPE_REQUEST,
            CHANGE_ACTIVITY_TYPE_SUCCESS,
            CHANGE_ACTIVITY_TYPE_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/event/${eventId}`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.EVENT,
    };
};

export const CHANGE_ABSENCE_TYPE_REQUEST = "CHANGE_ABSENCE_TYPE_REQUEST";
export const CHANGE_ABSENCE_TYPE_SUCCESS = "CHANGE_ABSENCE_TYPE_SUCCESS";
export const CHANGE_ABSENCE_TYPE_FAILURE = "CHANGE_ABSENCE_TYPE_FAILURE";
export const changeAbsenceTypeAction = (eventId, json) => {
    return {
        types: [
            CHANGE_ABSENCE_TYPE_REQUEST,
            CHANGE_ABSENCE_TYPE_SUCCESS,
            CHANGE_ABSENCE_TYPE_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/event/${eventId}`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.EVENT,
    };
};

export const POST_MONTH_ACTION_REQUEST = "POST_MONTH_ACTION_REQUEST";
export const POST_MONTH_ACTION_SUCCESS = "POST_MONTH_ACTION_SUCCESS";
export const POST_MONTH_ACTION_FAILURE = "POST_MONTH_ACTION_FAILURE";
export const postMonthAction = (json) => {
    return {
        types: [
            POST_MONTH_ACTION_REQUEST,
            POST_MONTH_ACTION_SUCCESS,
            POST_MONTH_ACTION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/month-action`,
                apiOptions("POST", json)
            );
        },
        schema: Schemas.MONTHS_FOR_VALIDATION_ARRAY,
    };
};

export const FETCH_MONTH_ACTION_REQUEST = "FETCH_MONTH_ACTION_REQUEST";
export const FETCH_MONTH_ACTION_SUCCESS = "FETCH_MONTH_ACTION_SUCCESS";
export const FETCH_MONTH_ACTION_FAILURE = "FETCH_MONTH_ACTION_FAILURE";
export const fetchMonthAction = (monthId) => {
    return {
        types: [
            FETCH_MONTH_ACTION_REQUEST,
            FETCH_MONTH_ACTION_SUCCESS,
            FETCH_MONTH_ACTION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/month-action/${monthId}`,
                apiOptions("GET")
            );
        },
        schema: Schemas.MONTH_ACTION_ARRAY,
    };
};

export const FETCH_OVERTIMES_FOR_VALIDATION_REQUEST =
    "FETCH_OVERTIMES_FOR_VALIDATION_REQUEST";
export const FETCH_OVERTIMES_FOR_VALIDATION_SUCCESS =
    "FETCH_OVERTIMES_FOR_VALIDATION_SUCCESS";
export const FETCH_OVERTIMES_FOR_VALIDATION_FAILURE =
    "FETCH_OVERTIMES_FOR_VALIDATION_FAILURE";
export const fetchOvertimesForValidation = (matricule, period) => {
    return {
        types: [
            FETCH_OVERTIMES_FOR_VALIDATION_REQUEST,
            FETCH_OVERTIMES_FOR_VALIDATION_SUCCESS,
            FETCH_OVERTIMES_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/overtime?matricule=${matricule}&period=${period}`,
                apiOptions("GET")
            );
        },
        schema: Schemas.OVERTIMES_FOR_VALIDATION_ARRAY,
    };
};

export const RESET_OVERTIMES_FOR_VALIDATION = "RESET_OVERTIMES_FOR_VALIDATION";
export const resetOvertimesForValidation = () => {
    return {
        type: RESET_OVERTIMES_FOR_VALIDATION,
    };
};

export const CHANGE_OVERTIME_TYPE_REQUEST = "CHANGE_OVERTIME_TYPE_REQUEST";
export const CHANGE_OVERTIME_TYPE_SUCCESS = "CHANGE_OVERTIME_TYPE_SUCCESS";
export const CHANGE_OVERTIME_TYPE_FAILURE = "CHANGE_OVERTIME_TYPE_FAILURE";
export const changeOvertimeTypeAction = (overtimeId, json) => {
    return {
        types: [
            CHANGE_OVERTIME_TYPE_REQUEST,
            CHANGE_OVERTIME_TYPE_SUCCESS,
            CHANGE_OVERTIME_TYPE_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/overtime/${overtimeId}`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.OVERTIME,
    };
};

export const FETCH_OVERTIME_CODES_FOR_VALIDATION_REQUEST =
    "FETCH_OVERTIME_CODES_FOR_VALIDATION_REQUEST";
export const FETCH_OVERTIME_CODES_FOR_VALIDATION_SUCCESS =
    "FETCH_OVERTIME_CODES_FOR_VALIDATION_SUCCESS";
export const FETCH_OVERTIME_CODES_FOR_VALIDATION_FAILURE =
    "FETCH_OVERTIME_CODES_FOR_VALIDATION_FAILURE";
export const fetchOvertimeCodesForValidationAction = (overtimeId) => {
    return {
        types: [
            FETCH_OVERTIME_CODES_FOR_VALIDATION_REQUEST,
            FETCH_OVERTIME_CODES_FOR_VALIDATION_SUCCESS,
            FETCH_OVERTIME_CODES_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/overtime/${overtimeId}/code`,
                apiOptions("GET")
            );
        },
        schema: Schemas.OVERTIMECODE_ARRAY,
    };
};

export const FETCH_EVENT_CODES_FOR_EDIT_REQUEST =
    "FETCH_EVENT_CODES_FOR_EDIT_REQUEST";
export const FETCH_EVENT_CODES_FOR_EDIT_SUCCESS =
    "FETCH_EVENT_CODES_FOR_EDIT_SUCCESS";
export const FETCH_EVENT_CODES_FOR_EDIT_FAILURE =
    "FETCH_EVENT_CODES_FOR_EDIT_FAILURE";
export const fetchEventCodesForEditAction = (eventId) => {
    return {
        types: [
            FETCH_EVENT_CODES_FOR_EDIT_REQUEST,
            FETCH_EVENT_CODES_FOR_EDIT_SUCCESS,
            FETCH_EVENT_CODES_FOR_EDIT_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}validation/event/${eventId}/code`,
                apiOptions("GET")
            );
        },
        schema: Schemas.EVENTCODE_ARRAY,
    };
};

export const FETCH_ABSENCE_HISTORY_FOR_VALIDATION_REQUEST =
    "FETCH_ABSENCE_HISTORY_FOR_VALIDATION_REQUEST";
export const FETCH_ABSENCE_HISTORY_FOR_VALIDATION_SUCCESS =
    "FETCH_ABSENCE_HISTORY_FOR_VALIDATION_SUCCESS";
export const FETCH_ABSENCE_HISTORY_FOR_VALIDATION_FAILURE =
    "FETCH_ABSENCE_HISTORY_FOR_VALIDATION_FAILURE";
export const fetchAbsenceHistoryForValidation = (userMatricule) => {
    return {
        types: [
            FETCH_ABSENCE_HISTORY_FOR_VALIDATION_REQUEST,
            FETCH_ABSENCE_HISTORY_FOR_VALIDATION_SUCCESS,
            FETCH_ABSENCE_HISTORY_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event/history/absence?user=${userMatricule}`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.ABSENCE_HISTORY_ARRAY,
    };
};

export const MONTH_FOR_CRA_DISPLAY_REQUEST = "MONTH_FOR_CRA_DISPLAY_REQUEST";
export const MONTH_FOR_CRA_DISPLAY_SUCCESS = "MONTH_FOR_CRA_DISPLAY_SUCCESS";
export const MONTH_FOR_CRA_DISPLAY_FAILURE = "MONTH_FOR_CRA_DISPLAY_FAILURE";
export const fetchMonthForCRADisplay = (month, year, userMatricule) => {
    return {
        types: [
            MONTH_FOR_CRA_DISPLAY_REQUEST,
            MONTH_FOR_CRA_DISPLAY_SUCCESS,
            MONTH_FOR_CRA_DISPLAY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/month/${month}/${year}?user=${userMatricule}`,
                apiOptions("GET")
            );
        },
        payload: {month, year},
        schema: Schemas.MONTH,
    };
};

export const DAYS_FOR_CRA_DISPLAY_REQUEST = "DAYS_FOR_CRA_DISPLAY_REQUEST";
export const DAYS_FOR_CRA_DISPLAY_SUCCESS = "DAYS_FOR_CRA_DISPLAY_SUCCESS";
export const DAYS_FOR_CRA_DISPLAY_FAILURE = "DAYS_FOR_CRA_DISPLAY_FAILURE";
export const fetchDaysForCRADisplay = (monthId) => {
    return {
        types: [
            DAYS_FOR_CRA_DISPLAY_REQUEST,
            DAYS_FOR_CRA_DISPLAY_SUCCESS,
            DAYS_FOR_CRA_DISPLAY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/day?maxResults=40&month=${monthId}`,
                apiOptions("GET")
            );
        },
        payload: {monthId},
        schema: Schemas.DAY_ARRAY,
    };
};

export const HALFDAYS_FOR_CRA_DISPLAY_REQUEST =
    "HALFDAYS_FOR_CRA_DISPLAY_REQUEST";
export const HALFDAYS_FOR_CRA_DISPLAY_SUCCESS =
    "HALFDAYS_FOR_CRA_DISPLAY_SUCCESS";
export const HALFDAYS_FOR_CRA_DISPLAY_FAILURE =
    "HALFDAYS_FOR_CRA_DISPLAY_FAILURE";
export const fetchHalfDaysForCRADisplay = (monthId) => {
    return {
        types: [
            HALFDAYS_FOR_CRA_DISPLAY_REQUEST,
            HALFDAYS_FOR_CRA_DISPLAY_SUCCESS,
            HALFDAYS_FOR_CRA_DISPLAY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/halfday?maxResults=100&month=${monthId}`,
                apiOptions("GET")
            );
        },
        payload: {monthId},
        schema: Schemas.HALFDAY_ARRAY,
    };
};

export const FETCH_EVENTS_FOR_CRA_DISPLAY_REQUEST =
    "FETCH_EVENTS_FOR_CRA_DISPLAY_REQUEST";
export const FETCH_EVENTS_FOR_CRA_DISPLAY_SUCCESS =
    "FETCH_EVENTS_FOR_CRA_DISPLAY_SUCCESS";
export const FETCH_EVENTS_FOR_CRA_DISPLAY_FAILURE =
    "FETCH_EVENTS_FOR_CRA_DISPLAY_FAILURE";
export const fetchEventsForCRADisplay = (monthId) => {
    return {
        types: [
            FETCH_EVENTS_FOR_CRA_DISPLAY_REQUEST,
            FETCH_EVENTS_FOR_CRA_DISPLAY_SUCCESS,
            FETCH_EVENTS_FOR_CRA_DISPLAY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event?maxResults=100&month=${monthId}`,
                apiOptions("GET")
            );
        },
        payload: {monthId},
        schema: Schemas.EVENT_ARRAY,
    };
};

export const EVENTCODES_FOR_CRA_DISPLAY_REQUEST =
    "EVENTCODES_FOR_CRA_DISPLAY_REQUEST";
export const EVENTCODES_FOR_CRA_DISPLAY_SUCCESS =
    "EVENTCODES_FOR_CRA_DISPLAY_SUCCESS";
export const EVENTCODES_FOR_CRA_DISPLAY_FAILURE =
    "EVENTCODES_FOR_CRA_DISPLAY_FAILURE";

export const fetchEventCodesForCRADisplay = (userMatricule) => {
    return {
        types: [
            EVENTCODES_FOR_CRA_DISPLAY_REQUEST,
            EVENTCODES_FOR_CRA_DISPLAY_SUCCESS,
            EVENTCODES_FOR_CRA_DISPLAY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event-code?maxResults=1000&user=${userMatricule}`,
                apiOptions("GET")
            );
        },
        payload: {userMatricule},
        schema: Schemas.EVENTCODE_ARRAY,
    };
};

export const LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_REQUEST =
    "LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_REQUEST";
export const LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_SUCCESS =
    "LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_SUCCESS";
export const LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_FAILURE =
    "LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_FAILURE";
export const listOvertimeCodesForCRADisplay = (userMatricule) => {
    return {
        types: [
            LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_REQUEST,
            LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_SUCCESS,
            LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/overtime-code?maxResults=100&user=${userMatricule}`,
                apiOptions("GET")
            );
        },
        payload: {userMatricule},
        schema: Schemas.OVERTIMECODE_ARRAY,
    };
};

export const FETCH_OVERTIMES_FOR_CRA_DISPLAY_REQUEST =
    "FETCH_OVERTIMES_FOR_CRA_DISPLAY_REQUEST";
export const FETCH_OVERTIMES_FOR_CRA_DISPLAY_SUCCESS =
    "FETCH_OVERTIMES_FOR_CRA_DISPLAY_SUCCESS";
export const FETCH_OVERTIMES_FOR_CRA_DISPLAY_FAILURE =
    "FETCH_OVERTIMES_FOR_CRA_DISPLAY_FAILURE";
export const fetchOvertimesForCRADisplay = (month, year, userMatricule) => {
    return {
        types: [
            FETCH_OVERTIMES_FOR_CRA_DISPLAY_REQUEST,
            FETCH_OVERTIMES_FOR_CRA_DISPLAY_SUCCESS,
            FETCH_OVERTIMES_FOR_CRA_DISPLAY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/overtime/${month}/${year}?user=${userMatricule}`,
                apiOptions("GET")
            );
        },
        payload: {month, year, userMatricule},
        schema: Schemas.OVERTIME_ARRAY,
    };
};

export const fetchEntireMonthForCRADisplay = (
    monthId,
    userMatricule,
    month,
    year
) => {
    return (dispatch) => {
        dispatch(setLoading(true));

        let promises = [
            dispatch(fetchEventCodesForCRADisplay(userMatricule)),
            dispatch(listOvertimeCodesForCRADisplay(userMatricule)),
        ];
        return Promise.all(promises).then(() => {
            return dispatch(
                fetchMonthForCRADisplay(month, year, userMatricule)
            ).then(() => {
                dispatch(setLoading(false));
            });
        });

        // return dispatch(
        //     fetchMonthForCRADisplay(month, year, userMatricule)
        // ).then(() => {
        //     let promises = [
        //         // dispatch(fetchDaysForCRADisplay(monthId)),
        //         // dispatch(fetchHalfDaysForCRADisplay(monthId)),
        //         // dispatch(fetchEventsForCRADisplay(monthId)),
        //         dispatch(fetchEventCodesForCRADisplay(userMatricule)),
        //         dispatch(listOvertimeCodesForCRADisplay(userMatricule)),
        //         // dispatch(
        //         // fetchOvertimesForCRADisplay(month, year, userMatricule)
        //         // ),
        //     ];
        //     return Promise.all(promises).then(() => {
        //         dispatch(setLoading(false));
        //     });
        // });
    };
};

export const RESET_CRA_DISPLAY = "RESET_CRA_DISPLAY";
export const resetCraDisplay = () => ({
    type: RESET_CRA_DISPLAY,
});


export const fetchExtraActivitiesForMobile = (matricule) => {
    let filters = {
        period: "",
        matricules: null,
        states: ["pending"],
        types: ["all"],
        manager: matricule,
        minDate: "",
        maxDate: "",
    };

    return (dispatch) =>
        dispatch(fetchExtraActivities(filters))
}

export const FETCH_EXTRA_ACTIVITIES_REQUEST = "FETCH_EXTRA_ACTIVITIES_REQUEST";
export const FETCH_EXTRA_ACTIVITIES_SUCCESS = "FETCH_EXTRA_ACTIVITIES_SUCCESS";
export const FETCH_EXTRA_ACTIVITIES_FAILURE = "FETCH_EXTRA_ACTIVITIES_FAILURE";
export const fetchExtraActivities = (filters) => {
    return {
        types: [
            FETCH_EXTRA_ACTIVITIES_REQUEST,
            FETCH_EXTRA_ACTIVITIES_SUCCESS,
            FETCH_EXTRA_ACTIVITIES_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(`${Params.API_ROOT}validation/extra-activities`, filters),
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.EXTRA_ACTIVITY_MONTH_ARRAY,
    };
}

export const FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_REQUEST =
    "FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_REQUEST";
export const FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_SUCCESS =
    "FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_SUCCESS";
export const FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_FAILURE =
    "FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_FAILURE";
export const fetchExtraActivitiesWeeksForValidation = (month, year, userId) => {
    return {
        types: [
            FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_REQUEST,
            FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_SUCCESS,
            FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(`${Params.API_ROOT}validation/extra-activities/${year}/${month}/${userId}`),
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_ARRAY,
    };
}

export const FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_REQUEST =
    "FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_REQUEST";
export const FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_SUCCESS =
    "FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_SUCCESS";
export const FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_FAILURE =
    "FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_FAILURE";
export const fetchExtraActivitiesConfigForValidation = (id) => {
    return {
        types: [
            FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_REQUEST,
            FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_SUCCESS,
            FETCH_EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(`${Params.API_ROOT}validation/extra-activities/${id}/config`),
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION,
    };
}

export const UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_REQUEST =
    "UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_REQUEST";
export const UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_SUCCESS =
    "UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_SUCCESS";
export const UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_FAILURE =
    "UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_FAILURE";
export const updateExtraActivitiesForValidation = (id, json) => {
    return {
        types: [
            UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_REQUEST,
            UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_SUCCESS,
            UPDATE_EXTRA_ACTIVITIES_FOR_VALIDATION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(`${Params.API_ROOT}validation/extra-activities/${id}`),
                apiOptions("PATCH", json)
            );
        },
        payload: {},
        schema: Schemas.EXTRA_ACTIVITIES_CONFIG_FOR_VALIDATION_ARRAY,
    };
}
