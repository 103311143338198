import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

const selectMonthEntity = (state) => state.timesheet.entities.month;
const selectCraDisplayed = (state) => state.validation.craDisplayMonth;

const selectValidMonth = createSelector(
    [selectMonthEntity, selectCraDisplayed],
    (monthEntity, craDisplayed) => {
        const isValidState = (state) => state !== null && Object.keys(state).length > 0;

        if (isValidState(craDisplayed)) {
            return craDisplayed;
        }

        if (isValidState(monthEntity)) {
            return monthEntity;
        }

        return {};
    }
);

export const useMonth = () => {
    return useSelector(selectValidMonth);
};
