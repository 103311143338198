import moment from "moment";

const NOTIFICATION_CONFIG_KEY = "notif_config"

export const getNotificationConfig = () => {
    // const config = localStorage.getItem(NOTIFICATION_CONFIG_KEY)
    const config = getCookie(NOTIFICATION_CONFIG_KEY)
    if (!config) {
        return {
            enabled: null,
            disabledUntil: null,
            enabledFor: null,
            start: '09:00',
            end: '18:00'
        };
    } else {
        return JSON.parse(config);
    }
};

export const setNotificationConfig = (config) => {
    // localStorage.setItem(NOTIFICATION_CONFIG_KEY, JSON.stringify(config));
    setCookie(NOTIFICATION_CONFIG_KEY, JSON.stringify(config), 3650); // 10 ans
    saveConfigToIndexedDB(config)
    return config
};

export const enableNotitification = () => {
    const config = getNotificationConfig()
    config.enabled = true;
    return setNotificationConfig(config);
};

export const enableNotitificationFor = (type) => {
    const config = getNotificationConfig()
    config.enabledFor = type;
    return setNotificationConfig(config);
};

export const disabableNotitification = () => {
    const config = getNotificationConfig()
    config.enabled = false;
    return setNotificationConfig(config);
};

export const toggleEnabledNotitification = () => {
    const config = getNotificationConfig()
    config.enabled = !config.enabled;
    return setNotificationConfig(config);
};

export const disabableNotitificationUntil = (date) => {
    const config = getNotificationConfig()
    config.disabledUntil = date;
    return setNotificationConfig(config);
};

export const setNotitificationHours = (hours) => {
    const config = getNotificationConfig()
    config.start = hours.start
    config.end = hours.end
    config.enabledFor = 'custom'
    return setNotificationConfig(config);
};

export const getUrlByReference = (data) => {
    switch (data.reference) {
        case 'NEW_ABSENCE': return '/timesheet';
        default: return '/'
    }
}

export const buildNotificationFormPayload = (payload) => {
    return [
        payload.data.title,
        {
            body: payload.data.body,
            icon: '/favicon-32x32.png',
            data: payload.data
        }
    ]
}

export const canReceiveNotification = () => {
    const config = getNotificationConfig()
    const now = moment()
    const time = now.format('HH:mm')
    if (config.enabled) {
        if (config.enabledFor === 'fullday') {
            return true
        }
        if (config.enabledFor === 'custom') {
            if (config.start <= time && config.end >= time) {
                return true
            }
        }
    } else if (config.disabledUntil < now.format('YYYY-MM-DD')) {
        return true
    }

    return false
}

// Utilitaires pour les cookies
export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

export const setCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 86400000).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

// IndexedDB functions
function openDatabase() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('NotificationDB', 1);
        request.onerror = (event) => reject(event);
        request.onsuccess = (event) => resolve(event.target.result);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('config')) {
                db.createObjectStore('config', { keyPath: 'id' });
            }
        };
    });
}

function saveConfigToIndexedDB(config) {
    openDatabase().then((db) => {
        const transaction = db.transaction(['config'], 'readwrite');
        const store = transaction.objectStore('config');
        store.put({ id: 'notificationConfig', ...config });
    });
}
