import React, {useState} from 'react';
import {
    ACCOUNT_CONTENT,
    INSTALL_CONTENT,
    NOTIFICATIONS_CONTENT,
    REMOTE_CONTENT,
    RUNAS_CONTENT
} from "./ParametersContainer";
import ParametersCategoryContainer from "./ParametersCategoryContainer";
import {isEmpty} from "../../utils/Utils";
import MyAccountContainer from "../MyAccountContainer/MyAccountContainer";
import NotificationsContainer from "./NotificationsContainer";
import RunAsContainer from "../RunAsContainer/RunAsContainer";
import InstallContainer from "./InstallContainer";

const ParametersContent = (props) => {
    const {activeContent, craCategory, handleClose} = props

    return <>
        {(() => {
            switch (activeContent) {
                case NOTIFICATIONS_CONTENT:
                    return <NotificationsContainer handleClose={handleClose}/>
                case ACCOUNT_CONTENT:
                    return <MyAccountContainer handleClose={handleClose}/>
                case RUNAS_CONTENT:
                    return <RunAsContainer handleClose={handleClose}/>
                case INSTALL_CONTENT:
                    return <InstallContainer handleClose={handleClose}/>
                default:
                    return <>
                        {!isEmpty(craCategory) &&
                            <ParametersCategoryContainer category={craCategory} handleClose={handleClose}/>}
                    </>
            }
        })()}
    </>
}

export default ParametersContent;
