import React from "react";

import "./Lightbox.scss";
import {useSwipeable} from "react-swipeable";
import {useDispatch} from "react-redux";
import {changeOverlayContent} from "../../events/OverlayEvents";
import * as TimesheetActions from "actions/Timesheet";

const LightboxHeader = ({children}) => {
    const dispatch = useDispatch();
    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            const lb = document.getElementById('lightbox');
            if (lb) {
                lb.classList.add('animate')
                lb.style.bottom = 0
            }
        },
        onSwiping: (eventData) => {
            const lb = document.getElementById('lightbox');
            if (lb) {
                lb.classList.remove('animate')
                if (eventData.deltaY > 0) {
                    lb.style.bottom = (-1 * eventData.deltaY) + 'px'
                }
                if (eventData.deltaY > 300) {
                    closeLightbox()
                }
            }
        },
        preventScrollOnSwipe: true
    });

    function closeLightbox() {
        changeOverlayContent(null);
        dispatch(TimesheetActions.clearDaysSelection());
        dispatch(TimesheetActions.unlockStart());
        dispatch(TimesheetActions.unlockEnd());
        dispatch(TimesheetActions.viewMode())
    }

    return <div className="lb-header" {...handlers}>
        {children}
    </div>
};

export default LightboxHeader;
