import React, {Fragment, useEffect} from "react";

import moment from "moment";
import "moment/locale/fr";

const FakeExtraDayLine = (props) => {

    const {config, element, missingWeek, overtimes, eventCodes, matchingDay, path} = props;

    const TotalHoursContainer = () => {
        let array = matchingDay?.hours || [];
        let duration = 0;
        let durationString;
        array.forEach((element) => {
            if (element) {
                let startTime = moment.parseZone(element.start, "HH:mm");
                let endTime = moment.parseZone(element.end, "HH:mm");
                let addTime;
                if (endTime.format("HH:mm") === "00:00") {
                    addTime = 24 * 60 * 60 * 1000 + endTime.diff(startTime);
                } else {
                    addTime = endTime.diff(startTime);
                }
                if (!isNaN(addTime)) {
                    duration += addTime;
                }
            }
        });

        let hours = parseInt(moment.duration(duration).asHours());
        let minutes = parseInt(moment.duration(duration).asMinutes()) % 60;

        if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            durationString = hours + ":" + minutes;
        } else {
            durationString = "0:00";
        }

        return durationString;
    };

    const getMissionLabel = (complementaries) => {
        if (complementaries) {
            let overtimeCode = overtimes.find(overtime => overtime.id === complementaries.hours[0].code)
            let mission = eventCodes.find(eventCode => eventCode.code === overtimeCode.affectation)

            return mission ? mission.description : 'Prestation';
        }

        return '';
    }

    const getOvertimeLabel = (complementaries) => {
        let overtimeCode = overtimes.find(overtime => overtime.id === complementaries)

        return overtimeCode ? overtimeCode.description : 'Prestation';
    }

    const getMissingDayMissionLabel = (date, type) => {
        if (missingWeek) {
            let extraActivitiesForDay;
            if (type === 'complementaries') {
                extraActivitiesForDay = missingWeek.complementaries.find(day => day.date === date)
            } else {
                extraActivitiesForDay = missingWeek.constraints.find(day => day.date === date)
            }
            return getMissionLabel(extraActivitiesForDay)
        }

        return undefined;
    }

    return <div className="overtime-day-line">
        {config.canDisplayEventCodesForComplementaries() && path === "complementaries" &&
            <div className={`fake-input-overtime-mission`}
                 title={getMissingDayMissionLabel(element.date, "complementaries")}>
                {getMissingDayMissionLabel(element.date, "complementaries") ? getMissingDayMissionLabel(element.date, "complementaries") : " "}
            </div>}
        <div className="hour-input-spacer">&nbsp;</div>
        <div className="hour-line-container">
            {/*{getMissingDayHours(element.date, "complementaries")}*/}
            {matchingDay ? matchingDay.hours.map((hour, key) => {
                return <div className="hour-line no-hover" key={key}>
                    <div className="hour-input">
                        <div className="no-input-extra-activities-hour">{hour.start}</div>
                        <div className="hour-separator">
                            <i className="far fa-chevron-right"></i>
                        </div>
                        <div className="no-input-extra-activities-hour">{hour.end}</div>
                    </div>
                    {((config.canDisplayOvertimeCodesForComplementaries() && path === "complementaries") || (config.canDisplayOvertimeCodesForConstraints() && path === "constraints")) &&
                        <div className="no-input-extra-activities-code no-hover"
                             title={getOvertimeLabel(hour.code)}>{getOvertimeLabel(hour.code)}</div>}

                </div>
            }) : <div className="hour-line no-hover">
                <div className="hour-input no-input-hour-input">&nbsp;</div>
                {((config.canDisplayOvertimeCodesForComplementaries() && path === "complementaries") || (config.canDisplayOvertimeCodesForConstraints() && path === "constraints")) &&
                    <div className="no-input-extra-activities-code no-hover">&nbsp;</div>}
            </div>}
        </div>
        {/*{config.canDisplayOvertimeCodesForComplementaries() &&*/}
        {/*    <div className={"fake-input-overtime-overtime"}>&nbsp;</div>}*/}
        <div
            className={"total-hours-container" + (path === "constraints" ? " constraint-total-hours-container" : "")}>
            <div className="total-hours-container-value">
                <TotalHoursContainer/>
            </div>
        </div>
    </div>;
}

export default FakeExtraDayLine;
