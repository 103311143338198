import React, { useEffect, Fragment } from "react";
import { useParams, Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import Calendar from "components/TimesheetContainer/Calendar/Calendar";
import * as ValidationActions from "actions/Validation";
import * as UserActions from "actions/User";
import EventContainer from "components/TimesheetContainer/Calendar/EventContainer";
import CRADisplayMobile from "../../mobile/TimesheetMobile/CRADisplayMobile";

const CRADisplay = () => {
    const { monthId, month, year, userMatricule } = useParams();
    const isMobile = useSelector((state) => state.common.isMobile)
    const dispatch = useDispatch();

    // const monthEntity = useSelector(
    //     (state) => state.validation.craDisplayMonth
    // );

    const monthEntity = useSelector(state => state.validation.craDisplayMonth);
    const user = useSelector((state) => state.user.craUser);

    useEffect(() => {
        dispatch(
            ValidationActions.fetchEntireMonthForCRADisplay(
                monthId,
                userMatricule,
                month,
                year
            )
        );

        return () => {
            dispatch(ValidationActions.resetCraDisplay())
        }
    }, []);

    useEffect(() => {
        if (monthEntity?.user) {
            dispatch(UserActions.findUser(monthEntity.user));
        }
    }, [monthEntity]);

    return monthId ? (
            (isMobile && monthEntity?.loaded) ? <CRADisplayMobile month={monthEntity}/> : <div id="page-container">
            <div className="container">
                {monthEntity && user && (
                    <Fragment>
                        <EventContainer
                            firstDayOfMonth={moment(
                                month + "-" + year,
                                "MM-YYYY"
                            ).startOf("month")}
                            type="view"
                            currentMonth={monthEntity.month}
                            currentYear={monthEntity.year}
                            days={monthEntity.days}
                            mainActivity={monthEntity.mainActivity}
                            autofillable={monthEntity.autofillable}
                            isMonthSubmitted={monthEntity.submitted}
                            isMonthEditable={monthEntity.editable}
                            isSpecialTime={monthEntity.specialTime}
                            modality={Array.isArray(user) ? user.modality : user.modality}
                            personType={Array.isArray(user) ? user.type : user.type}
                            extraActivities={monthEntity.extraActivities}
                        />
                        <Calendar
                            monthEntity={monthEntity}
                            days={monthEntity.days}
                            remoteDays={monthEntity.remoteDays}
                            user={user}
                            me={user}
                            type="view"
                        />
                    </Fragment>
                )}
            </div>
        </div>
    ) : (
        <Redirect to="/not-found" />
    );
};

export default CRADisplay;
