import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";

import { apiOptions } from "utils/Api";

import { buildUrl } from "utils/Utils";

export const TOGGLE_MANAGERS = "TOGGLE_MANAGERS";
export const toggleManagers = (matricules) => ({
    type: TOGGLE_MANAGERS,
    matricules: matricules,
});

export const OPEN_MANAGERS = "OPEN_MANAGERS";
export const openManagers = (matricules) => ({
    type: OPEN_MANAGERS,
    matricules: matricules,
});

export const CLOSE_MANAGERS = "CLOSE_MANAGERS";
export const closeManagers = (matricules) => ({
    type: CLOSE_MANAGERS,
    matricules: matricules,
});

export const TEAM_PLANNING_REQUEST = "TEAM_PLANNING_REQUEST";
export const TEAM_PLANNING_SUCCESS = "TEAM_PLANNING_SUCCESS";
export const TEAM_PLANNING_FAILURE = "TEAM_PLANNING_FAILURE";
export const fetchTeamPlanningForPeriod = (filters) => {
    return {
        types: [
            TEAM_PLANNING_REQUEST,
            TEAM_PLANNING_SUCCESS,
            TEAM_PLANNING_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(`${Params.API_ROOT}validation/planning`, filters),
                apiOptions("GET")
            );
        },
        schema: Schemas.USER_ARRAY,
    };
};


export const TEAM_PLANNING_WEEK_REQUEST = "TEAM_PLANNING_WEEK_REQUEST";
export const TEAM_PLANNING_WEEK_SUCCESS = "TEAM_PLANNING_WEEK_SUCCESS";
export const TEAM_PLANNING_WEEK_FAILURE = "TEAM_PLANNING_WEEK_FAILURE";
export const fetchTeamPlanningForWeek = (year, week) => {
    let url = `${Params.API_ROOT}validation/planning-by-week/${year}/${week}`
    if (year === null || week === null) {
        url = `${Params.API_ROOT}validation/planning-by-week`
    }
    return {
        types: [
            TEAM_PLANNING_WEEK_REQUEST,
            TEAM_PLANNING_WEEK_SUCCESS,
            TEAM_PLANNING_WEEK_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                buildUrl(url),
                apiOptions("GET")
            );
        },
        schema: Schemas.USER_ARRAY,
    };
};
