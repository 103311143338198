import React, {useEffect, useState} from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import {useDispatch} from "react-redux";
import * as TimesheetActions from "actions/Timesheet";

const ExtraDayTypeSelector = (props) => {
    const [opened, setOpened] = useState(false);
    const {day, rtl} = props;
    const dispatch = useDispatch()

    const isClickable = day.constraint || day.complementary

    const close = () => {
        setOpened(false)
        dispatch(TimesheetActions.displayExtraOverlay(false))
    }

    const ref = useClickOutside(() => {
        close();
    });

    const handleToggle = () => {
        if (isClickable) {
            if (!opened) {
                dispatch(TimesheetActions.displayExtraOverlay(true))
            }
            setOpened(!opened)
        }
    }

    const selectDay = (type) => {
        close()
        dispatch(TimesheetActions.setExtraDay({
            number: day.number,
            type: type
        }))
    }

    const handleConstraint = () => {
        selectDay("constraint")
    }

    const handleComplementary = () => {
        selectDay("complementary")
    }

    const activityBtnContainerClasses = () => {
        const classes = ['activity-btn-container']
        if (opened) {
            classes.push('opened')
        }
        if (rtl) {
            classes.push('rtl')
        }

        return classes.join(' ')
    }

    return <div className={"day-btn-container"} ref={ref}>
        <div className={"day-btn"+(!isClickable ? " disabled" : "")} onClick={handleToggle}>{day.number}</div>
        <div className={activityBtnContainerClasses()}>
            {day.constraint && <div className="day-btn-with-label" onClick={handleConstraint}>
                <div className={"day-btn day-btn-constraint"}><i className="fal fa-house-leave"></i></div>
                <div className="day-btn-label">Intervention en astreinte</div>
            </div>}
            {day.complementary && <div className="day-btn-with-label" onClick={handleComplementary}>
                <div className={"day-btn day-btn-complementary"}><i className="fal fa-layer-plus"></i></div>
                <div className="day-btn-label">Activité complémentaire</div>
            </div>}
        </div>
    </div>
}

export default ExtraDayTypeSelector
