import React, {useEffect, useState} from "react";

import "./Button.scss";

const Button = (props) => {
    // const [processing, setProcessing] = useState(false);
    const processable = props.processable || false
    const getClassnames = () => {
        let classes = "lb-event-action-btn ";
        switch (props.type) {
            case "activity":
                classes += " activity-type";
                break;
            case "absence":
                classes += " absence-type";
                break;
            case "hours-not-worked":
                classes += " hours-not-worked-type";
                break;
            case "next":
                classes += " next-btn";
                break;
            case "cancel":
                classes += " cancel-btn";
                break;
            case "disabled":
                classes += " disabled-btn";
                break;
            default:
                break;
        }

        if (props.disabled) {
            classes += " disabled-btn";
        }

        return classes + " " + props.className;
    };

    const handleClick = () => {
        // if (!processing && processable) {
        //     setProcessing(true)
        // }
        if (!props.isProcessing && !props.disabled) {
            if (props.onClick) {
                props.onClick()
            }
        }

    }

    // const processingState = props.isProcessing || processing
    const processingState = props.isProcessing

    return (
        <div
            onClick={handleClick}
            className={"base-btn " + getClassnames()}
            style={props.additionnalStyle}
        >

            <div className="label">{processingState && processable ?
                <span className="btn-loader"></span> : props.text}</div>
            {props.shortname ? (
                <span className="event-shortname">{props.shortname}</span>
            ) : (
                ""
            )}
        </div>
    );
};

export default Button;
