import React from "react";
import moment from "moment";
import "moment/locale/fr";
import {useSelector} from "react-redux";

const TeamPlanningTableHeader = (props) => {
    const { daysArray } = props;

    const displayCounters = useSelector(state => state.timesheet.displayPlanningCounters)
    const displayWeekend = useSelector(state => state.timesheet.displayWeekend)

    return (
        <div className={"thead"}>
            <div className={"empty-header "+(!displayCounters ? "without-counters" : "")}>&nbsp;</div>
            <div className={"planning-days-container "+(displayWeekend ? "" : "without-weekends")}>
                {daysArray.map((element, key) => {
                    return (
                        <div className="day-header" key={key}>
                            {moment.parseZone(element).format("dd")}<br/>
                            {moment.parseZone(element).format("D")}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TeamPlanningTableHeader;
