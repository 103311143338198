import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.scss";
import App from "components/App";
import * as serviceWorker from "./serviceWorker";
// import "@fortawesome/fontawesome-pro/css/all.min.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "store/ConfigureStore";

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://74802f8933c14f6dbbd776b589eccb0f@o415127.ingest.sentry.io/5305764",
        release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
        ignoreErrors: [/window.twttr.widgets/],
    });
    Sentry.setContext("Git", {
        branch: process.env.REACT_APP_GIT_BRANCH,
        commit: process.env.REACT_APP_GIT_COMMIT,
        tag: process.env.REACT_APP_GIT_TAG,
    });
    Sentry.setTag("environment", process.env.REACT_APP_SENTRY_ENV);
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>{<App />}</BrowserRouter>
    </Provider>,
    document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();

