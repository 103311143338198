import React, {Fragment} from "react";
import "./PageHeader.scss";
import {Link} from "react-router-dom";
import {isDesktop} from "../../utils/Browser";

const PageHeader = props => {
    const classes = ["page-header"];
    classes.push('page-header-' + props.type);

    return (
        <div className={classes.join(" ")}>
            {props.action ? <div className="page-header-btn" onClick={props.action}><i
                    className="far fa-chevron-left"></i></div> :
                <Link to={props.link ? props.link : "/"} className="page-header-btn"><i
                    className="far fa-chevron-left"></i></Link>}
            {props.breadcrumb && isDesktop()
                ? (<div className="breadcrumb">
                    {props.breadcrumb.map((element, index) => {
                        if (element.url) {
                            return <Fragment key={index}><Link className="title" to={element.url}>{element.label}</Link><span
                                className="links-separator">{">"}</span>{" "}</Fragment>
                        }
                        return <div key={index} className={"active-breadcrumb"}>{element.label}</div>
                    })}
                </div>)
                : <div className="page-header-title">{props.title}</div>}
        </div>
    );
};

export default PageHeader;
