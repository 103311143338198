import React from "react";
import moment from "moment";
import "moment/locale/fr";

import "./ActionsBlock.scss";
import {changeOverlayContent} from "events/OverlayEvents";
import AbsenceValidationForm from "components/Backend/AbsenceContainer/AbsenceValidationForm";
import CommentBox from "components/Backend/AbsenceContainer/CommentBox";
import AbsenceHistoryActions from "components/Backend/AbsenceContainer/AbsenceHistoryActions";

const ActionsBlock = (props) => {
    const {me, user, manager, loadList} = props;

    const ActionButton = (props) => {
        const {children, additionnalClasses} = props;

        return (
            <span
                className={
                    "action-btn " +
                    (additionnalClasses ? additionnalClasses : "")
                }
            >
                {children}
            </span>
        );
    };

    const ValidateButton = (props) => {
        const {event, eventCodeDescription, validatedState} = props;

        return (
            <ActionButton additionnalClasses="validation-btn">
                {/* {event.state === toBeValidatedState ? ( */}
                <i
                    className="far fa-check-circle"
                    title="Valider l'absence"
                    onClick={() =>
                        changeOverlayContent(
                            <AbsenceValidationForm
                                action="validate"
                                user={user}
                                manager={manager}
                                event={event}
                                eventCodeDescription={eventCodeDescription}
                                validatedState={validatedState}
                                loadList={loadList}
                            />
                        )
                    }
                ></i>
                {/* ) : (
                    <span className="action-btn">V</span>
                )} */}
            </ActionButton>
        );
    };

    const RefuseButton = (props) => {
        const {event, eventCodeDescription} = props;

        return (
            <ActionButton>
                {me.hasRole("ROLE_ABSENCE_REFUSE_FIRST_LEVEL") &&
                    event.state === "submitted" && (
                        <i
                            className="fal fa-times"
                            title="Refuser l'absence"
                            onClick={() =>
                                changeOverlayContent(
                                    <AbsenceValidationForm
                                        action="refuse"
                                        user={user}
                                        manager={manager}
                                        event={event}
                                        eventCodeDescription={
                                            eventCodeDescription
                                        }
                                        loadList={loadList}
                                    />
                                )
                            }
                        ></i>
                    )}
            </ActionButton>
        );
    };

    const BackwardButton = (props) => {
        const {event, eventCodeDescription} = props;

        return (
            <ActionButton>
                <i
                    className="far fa-reply"
                    title="Redonner la main au collaborateur"
                    onClick={() =>
                        changeOverlayContent(
                            <AbsenceValidationForm
                                action="backward"
                                user={user}
                                manager={manager}
                                event={event}
                                eventCodeDescription={eventCodeDescription}
                                loadList={loadList}
                            />
                        )
                    }
                ></i>
            </ActionButton>
        );
    };

    const EditTypeButton = (props) => {
        const {event, eventCodeDescription} = props;

        return (
            <ActionButton>
                <i
                    className="far fa-edit"
                    title="Modifier le type d'absence"
                    onClick={() =>
                        changeOverlayContent(
                            <AbsenceValidationForm
                                action="edit-type"
                                user={user}
                                manager={manager}
                                event={event}
                                eventCodeDescription={eventCodeDescription}
                                loadList={loadList}
                            />
                        )
                    }
                ></i>
            </ActionButton>
        );
    };

    const CommentButton = (props) => {
        const {event} = props;

        return (
            <ActionButton additionnalClasses="action-comment-container">
                <span
                    className="fa-stack fa-1x comment-count-container"
                    onClick={() =>
                        changeOverlayContent(<CommentBox event={event}/>)
                    }
                >
                    <i
                        className="fas fa-stack-2x fa-comment-alt"
                        title="Voir les commentaires"
                    ></i>
                    <span className="fa fa-stack-1x" title="Voir les commentaires">
                        <span className="comment-count">
                            {event.commentCount}
                        </span>
                    </span>
                </span>
            </ActionButton>
        );
    };

    const HistoryButton = (props) => {
        const {event} = props;

        return (
            <ActionButton>
                <i
                    className="far fa-info-circle"
                    title="Voir l'historique des actions sur l'absence"
                    onClick={() =>
                        changeOverlayContent(
                            <AbsenceHistoryActions
                                event={event}
                                user={user}
                                manager={manager}
                            />
                        )
                    }
                ></i>
            </ActionButton>
        );
    };

    const canValidateLevel1 = (event) => {
        if (event) {
            if (
                me.hasRole("ROLE_ABSENCE_ACCEPT_FIRST_LEVEL") &&
                event.state === "submitted" &&
                !event.locked
            ) {
                return true;
            }
        }
        return false;
    };
    const canValidateLevel2 = (event) => {
        if (event) {
            if (
                me.hasRole("ROLE_ABSENCE_ACCEPT_SECOND_LEVEL") &&
                event.state === "validated1" &&
                moment.parseZone(event.startAt) <
                moment.parseZone().endOf("month") &&
                !event.locked
            ) {
                return true;
            }
        }
        return false;
    };

    const canRefuse = (event) => {
        if (event) {
            if (
                me.hasRole("ROLE_ABSENCE_REFUSE_FIRST_LEVEL") &&
                !event.locked &&
                event.state === "submitted"
            ) {
                return true;
            }
        }
        return false;
    };

    const canBackward = (event) => {
        if (event) {
            if (
                (me.hasRole("ROLE_ABSENCE_BACKWARD") &&
                    !event.locked &&
                    event.state === "validated1") ||
                (me.hasRole("ROLE_ABSENCE_BACKWARD_2") &&
                    !event.locked &&
                    !event.editable &&
                    event.state === "validated2")
            ) {
                return true;
            }
        }

        return false;
    };

    const canEdit = (event) => {
        if (event) {
            if (
                me.hasRole("ROLE_ABSENCE_EDIT_TYPE") &&
                event.state === "validated1" &&
                !event.locked
            )
                return true;
        }
        return false;
    };

    const canReadComments = (event) => {
        if (event) {
            if (me.hasRole("ROLE_ABSENCE_COMMENT")) {
                return true;
            }
        }

        return false;
    };

    const canViewHistory = (event) => {
        if (event) {
            if (me.hasRole("ROLE_ABSENCE_ACTION_HISTORY")) {
                return true;
            }
        }

        return false;
    };

    const ActionsLine = (props) => {
        const {event, eventCodeDescription} = props;

        return (
            <div className="action-buttons">
                {canValidateLevel1(event) ? (
                    <ValidateButton
                        event={event}
                        validatedState="validated1"
                        toBeValidatedState="submitted"
                        eventCodeDescription={eventCodeDescription}
                    />
                ) : me.hasRole("ROLE_ABSENCE_SHOW") &&
                (event.state === "validated1" ||
                    event.state === "validated2") ? (
                    <i
                        className="far fa-check-circle green-btn validation-btn"
                        title="Absence validée par le manager"
                    ></i>
                ) : (
                    <span className="action-btn validation-btn">&nbsp;</span>
                )}

                {canValidateLevel2(event) ? (
                    <ValidateButton
                        event={event}
                        validatedState="validated2"
                        toBeValidatedState="validated1"
                        eventCodeDescription={eventCodeDescription}
                    />
                ) : me.hasRole("ROLE_ABSENCE_SHOW") &&
                event.state === "validated2" ? (
                    <i
                        className="far fa-check-circle green-btn validation-btn"
                        title="Absence validée par le service paie"
                    ></i>
                ) : (
                    <span className="action-btn validation-btn">&nbsp;</span>
                )}

                {canRefuse(event) ? (
                    <RefuseButton
                        event={event}
                        eventCodeDescription={eventCodeDescription}
                    />
                ) : me.hasRole("ROLE_ABSENCE_SHOW") &&
                event.state === "refused" ? (
                    <i
                        className="fal fa-times coral-btn"
                        title="Absence refusée"
                    ></i>
                ) : (
                    <span className="action-btn">&nbsp;</span>
                )}

                {canBackward(event) ? (
                    <BackwardButton
                        event={event}
                        eventCodeDescription={eventCodeDescription}
                    />
                ) : (
                    <span className="action-btn">&nbsp;</span>
                )}

                {canEdit(event) ? (
                    <EditTypeButton
                        event={event}
                        eventCodeDescription={eventCodeDescription}
                    />
                ) : (
                    <span className="action-btn">&nbsp;</span>
                )}

                {canReadComments(event) && event.commentCount > 0 ? (
                    <CommentButton event={event}/>
                ) : (
                    <span className="action-btn action-comment-container">
                        &nbsp;
                    </span>
                )}

                {canViewHistory(event) && <HistoryButton event={event}/>}
            </div>
        );
    };

    return (
        <div className="actions-block">
            {props.absenceGroups &&
                Object.entries(props.absenceGroups).map((element, key) => {
                    return (
                        <div key={key} className="actions-group">
                            <div className="actions-group-blank">&nbsp;</div>
                            <div className="actions-group-content">
                                {element[1]
                                    .sort((a, b) => {
                                        return (
                                            moment.parseZone(a.startAt) -
                                            moment.parseZone(b.startAt)
                                        );
                                    })
                                    .map((event, key) => {
                                        return (
                                            <ActionsLine
                                                key={key}
                                                event={event}
                                                eventCodeDescription={
                                                    element[0]
                                                }
                                            />
                                        );

                                        // if (me.hasRole("ROLE_PAY_MANAGER")) {
                                        //     return (
                                        //         <PayManagerActionLine
                                        //             key={key}
                                        //             event={event}
                                        //             eventCodeDescription={
                                        //                 element[0]
                                        //             }
                                        //         />
                                        //     );
                                        // } else if (me.hasRole("ROLE_MANAGER")) {
                                        //     return (
                                        //         <ManagerActionLine
                                        //             key={key}
                                        //             event={event}
                                        //             eventCodeDescription={
                                        //                 element[0]
                                        //             }
                                        //         />
                                        //     );
                                        // }
                                    })}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default ActionsBlock;
