import React, {useEffect, useRef, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion/dist/framer-motion'
import './PageTransition.scss'
import {useDispatch, useSelector} from "react-redux";
import * as TimesheetActions from "actions/Timesheet";

const overlayVariants = {
    initial: {opacity: 1, y: '100vh'},
    animate: {opacity: 1, y: 0, transition: {delay: 0, duration: 0.5}},
    exit: {opacity: 0, transition: {delay: 0.5, duration: 0.5}},
};

let contentVariants = {
    initial: {position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', opacity: 0},
    animate: {
        position: 'fixed', opacity: 1, transition: {delay: 0.8, duration: 0.8, ease: 'easeIn'}, transitionEnd: {
            position: 'relative'
        }
    },
    exit: {opacity: 1, y: '100vh', transition: {delay: 0.3, duration: 0.5, ease: 'easeIn'}},
};

const PageTransition = ({children, backgroundColor, text, icon, textColor}) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const appLoading = useSelector((state) => state.common.appLoading);
    const timeoutRef = useRef(null);
    const transitionPageId = useSelector((state) => state.timesheet.transitionPageId)
    const dispatch = useDispatch()
    const [animate, setAnimate] = useState(icon !== transitionPageId)

    useEffect(() => {
        setShowOverlay(true)
        const minDuration = 1000;

        const handleTimeout = () => {
            setShowOverlay(false);
            timeoutRef.current = null;
            dispatch(TimesheetActions.setPageTransitionId(icon))
        };

        if (appLoading) {
            timeoutRef.current = setTimeout(handleTimeout, minDuration);
        } else {
            const elapsed = timeoutRef.current ? Date.now() - timeoutRef.current.start : 0;
            const remaining = Math.max(minDuration - elapsed, 0);
            timeoutRef.current = setTimeout(handleTimeout, remaining);
        }

        if (icon === transitionPageId) {
            setShowOverlay(false)
            setAnimate(false)
        } else {
            setShowOverlay(true)
            setAnimate(true)
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        // setTimeout(() => {
        if (icon === transitionPageId) {
            setShowOverlay(false)
            setAnimate(false)
        } else {
            setShowOverlay(true)
            setAnimate(true)
        }
        // }, 3000)
    }, [transitionPageId]);

    return (
        <>
            <AnimatePresence>
                {showOverlay && (
                    <motion.div
                        className="transition-overlay"
                        variants={overlayVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        style={{
                            backgroundColor: backgroundColor || 'white'
                        }}
                    >
                        <motion.div style={{color: textColor || 'white', fontSize: '32px', textAlign: 'center'}}
                                    exit={{opacity: 0, y: "-50px", transition: {duration: 0.5}}}>
                            {text}
                        </motion.div>
                        <motion.div style={{color: textColor || 'white', fontSize: '52px', textAlign: 'center'}}
                                    exit={{opacity: 0, y: "-50px", transition: {duration: 0.3, delay: 0.1}}}>
                            <i className={`fal ${icon}`}></i>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
            {animate ? <motion.div
                variants={contentVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                style={{position: 'relative', zIndex: 1}}
            >
                {children}
            </motion.div> : children}
        </>
    );
};

export default PageTransition;
