import React, {Fragment, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import Lightbox from "components/common/Lightbox";
import {changeOverlayContent} from "events/OverlayEvents";

import * as ValidationActions from "actions/Validation";
import "./ExtraActivitiesValidationForm.scss"
import Button from "../../TimesheetContainer/Forms/Event/Button";

const ExtraActivitiesValidationForm = (props) => {
    const {action, user, month, loadList} = props
    const dispatch = useDispatch();

    const [comment, setComment] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const lightboxTitle = () => {
        let title = "";
        switch (action) {
            case "validate":
                title = "Valider la demande de saisie d'activités complémentaires";
                break;
            case "refuse":
                title = "Refuser la demande de saisie d'activités complémentaires";
                break;
            default:
                break;
        }

        return title;
    };

    const close = () => {
        changeOverlayContent(null);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handlePost = () => {
        if (!submitting) {
            setSubmitting(true);
            let json = {
                month: month.id,
                comment: comment,
                updated_at: moment.parseZone(month.updatedAt).format(
                    "YYYY-MM-DD[T]HH:mm:ss"
                ),
            };

            if (action === "validate") {
                json.action = "accept_extra_activities";
            } else if (action === "refuse") {
                json.action = "refuse_extra_activities";
            }

            let body = JSON.stringify(json);
            dispatch(ValidationActions.postMonthAction(body)).then(() => {
                changeOverlayContent(null);
                setSubmitting(false);
                loadList();
            });
        }
    };

    return (
        <Lightbox className="lb-centered validate-activity-lb">
            <div className="validate-activity-header">
                <div className="validate-activity-title">{lightboxTitle()}</div>
            </div>
            <div className={"extra-activities-content"}>
                {action === "validate" &&
                    (<div className={"extra-activities-content-warning"}>
                            <div>
                                Attention : en validant cette demande, les activités complémentaires saisies par le
                                collaborateur ne seront pas refacturables.
                            </div>
                            <div>
                                Pour que les activités complémentaires soient refacturables au client, vous devez faire
                                créer une ligne d'activité professionnelle au préalable par votre responsable ADV dans
                                l'outil de facturation et refuser la demande exceptionnelle ci-dessous.
                            </div>
                            <div>
                                Une fois la ligne ajoutée en facturation, le collaborateur pourra déclarer directement
                                ses activités complémentaires via son CRA sans passer par la demande exceptionnelle.
                            </div>
                        </div>
                    )}

                <div className={"extra-activities-content-confirm"}>
                    {action === "validate" && <Fragment>
                        J'autorise <span className="bold">{user.identity}</span> à effectuer des activités
                        complémentaires pour le mois
                        de {moment(month.year + "-" + month.month.toString().padStart(2, '0') + "-01").format('MMMM YYYY')}
                    </Fragment>}
                    {action === "refuse" && <Fragment>
                        Je refuse à <span className="bold">{user.identity}</span> l'accès à la saisie d'activités
                        complémentaires pour le mois
                        de {moment(month.year + "-" + month.month.toString().padStart(2, '0') + "-01").format('MMMM YYYY')}
                    </Fragment>}
                </div>
            </div>


            <div className="extra-activities-validation-comment">
                <div className="extra-activities-validation-comment-label">
                    <i className="far fa-comment-alt"></i> Commentaire
                </div>
                <textarea
                    name="comment"
                    onChange={(e) => handleCommentChange(e)}
                    className="extra-activities-validation-comment-input"
                ></textarea>
            </div>

            <div className="validate-activity-actions">
                <Button
                    onClick={close}
                    className="cancel-btn"
                    text={"Annuler"}
                />
                <Button
                    onClick={handlePost}
                    className="next-btn"
                    text={"Confirmer"}
                    processable={true}
                    isProcessing={submitting}
                />
            </div>
        </Lightbox>
    );
}

export default ExtraActivitiesValidationForm
