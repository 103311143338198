import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import moment from "moment";
import "moment/locale/fr";

import "./EventContainer.scss";

import {
    EntityRepository,
    EVENT_REPOSITORY,
    DAY_REPOSITORY,
    EVENT_CODE_REPOSITORY,
    OVERTIME_REPOSITORY,
    OVERTIME_CODE_REPOSITORY,
} from "store/EntityRepository";
import Event from "components/TimesheetContainer/Calendar/Event";
import Availability from "components/TimesheetContainer/Calendar/Availability";
import EventEntity from "entities/Event";
import Overtime from "entities/Overtime";
import Intervention from "components/TimesheetContainer/Calendar/Intervention";
import {isEmpty} from "utils/Utils";
import OvertimeCode from "../../../entities/OvertimeCode";

const EventContainer = (props) => {
    const {isMonthEditable, type, me, isSpecialTime, isMonthSubmitted, personType, modality, extraActivities} =
        props;
    const eventRepo = EntityRepository().getRepository(EVENT_REPOSITORY);
    const eventCodeRepo = EntityRepository().getRepository(
        EVENT_CODE_REPOSITORY
    );
    const overtimeRepo = EntityRepository().getRepository(OVERTIME_REPOSITORY);
    const overtimeCodeRepo = EntityRepository().getRepository(
        OVERTIME_CODE_REPOSITORY
    );
    const dayRepo = EntityRepository().getRepository(DAY_REPOSITORY);
    const events = eventRepo.findForCurrentMonth(type);
    const overtimes = overtimeRepo.findForCurrentMonth(type);
    const days = props.days;

    const hoveredEventId = useSelector((state) => state.timesheet.hoveredEvent);
    const hoveredOvertimeId = useSelector(
        (state) => state.timesheet.hoveredOvertime
    );

    const mainActivity = eventCodeRepo.find(props.mainActivity);

    const getStartDateForMonth = (startAt) => {
        let startAtForThisMonth = moment(startAt);
        while (startAtForThisMonth.month() + 1 < props.currentMonth) {
            startAtForThisMonth.add(1, "days");
        }

        return startAtForThisMonth;
    };

    const getEndDateForMonth = (endAt) => {
        let endAtForThisMonth = moment(endAt);
        while (endAtForThisMonth.month() + 1 > props.currentMonth) {
            endAtForThisMonth.subtract(1, "days");
        }

        return endAtForThisMonth;
    };

    const autofillDays = () => {
        const eventsArray = [];
        let start = null;
        let startMoment = null;
        let lastDate = null;

        const addEvent = (endDate, endMoment) => {
            if (start) {
                let json = {
                    start_at: start.format("YYYY-MM-DDTHH:mm:ssZ"),
                    start_moment: startMoment,
                    end_at: endDate.format("YYYY-MM-DDTHH:mm:ssZ"),
                    end_moment: endMoment,
                };
                let newEvent = new EventEntity(json);
                newEvent.autofilled = true;
                eventsArray.push(newEvent);

                start = null;
                startMoment = null;
                lastDate = null;
            }
        };

        for (let i = 0; i < days.length; i++) {
            let day = days[i];

            if (
                day.worked &&
                moment.parseZone(day.dateAt).format("YYYY-MM-DD") >=
                moment.parseZone(me.startAt).format("YYYY-MM-DD") &&
                (!me.endAt ||
                    (me.endAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") <=
                        moment.parseZone(me.endAt).format("YYYY-MM-DD")))
            ) {
                let halfdays = [day.am, day.pm];
                for (let i = 0; i < halfdays.length; i++) {
                    const halfday = halfdays[i];
                    if (!halfday) {
                        if (!start) {
                            start = moment.parseZone(day.dateAt);
                            if (i === 0) {
                                startMoment = "am";
                            } else if (i === 1) {
                                startMoment = "pm";
                            }
                        }
                    } else {
                        addEvent(lastDate, i === 0 ? "pm" : "am");
                    }
                    lastDate = moment.parseZone(day.dateAt);
                }
            } else {
                addEvent(lastDate, "pm");
            }
        }

        addEvent(lastDate, "pm");

        return eventsArray;
    };

    const splitEventsByWeek = () => {
        let result = [];

        for (let index = 0; index < events.length; index++) {
            const event = events[index];
            let startAt = getStartDateForMonth(event.startAt);
            let endAt = getEndDateForMonth(event.endAt);
            let targetEventCode = eventCodeRepo.findWithoutHook(
                event.eventCode,
                type
            );
            if (!targetEventCode) {
                targetEventCode = {};
                targetEventCode.type = "";
            }

            if (
                event.daysCount >= 1 ||
                targetEventCode.type === "sickness" ||
                targetEventCode.type === "maternity" ||
                isSpecialTime
            ) {
                let days;
                let allDays = dayRepo.findDaysForRange(startAt, endAt, type);

                if (
                    targetEventCode.type === "sickness" ||
                    targetEventCode.type === "maternity" ||
                    isSpecialTime
                ) {
                    days = dayRepo.findDaysForRange(startAt, endAt, type);
                } else {
                    days = dayRepo.findWorkingDaysForRange(
                        startAt,
                        endAt,
                        type
                    );
                }

                if (days.length > 0) {
                    let startIndex = 0;
                    for (let j = 0; j < days.length; j++) {
                        const currentDay = days[j];
                        if (j > 0) {
                            const previousDay = allDays.filter(
                                (element) =>
                                    moment
                                        .parseZone(element.dateAt)
                                        .format("DD/MM/YYYY") ===
                                    moment
                                        .parseZone(currentDay.dateAt)
                                        .subtract(1, "days")
                                        .format("DD/MM/YYYY")
                            );
                            if (
                                moment
                                    .parseZone(currentDay.dateAt)
                                    .isoWeekday() === 1 ||
                                (!previousDay[0].worked &&
                                    targetEventCode.type !== "sickness" &&
                                    targetEventCode.type !== "maternity" &&
                                    !isSpecialTime)
                            ) {
                                let subEvent = {...event};
                                let subEventStartAt = moment.parseZone(
                                    days[startIndex].dateAt
                                );
                                let subEventEndAt = moment.parseZone(
                                    days[j - 1].dateAt
                                );
                                subEvent.startAt = subEventStartAt.format(
                                    "YYYY-MM-DDTHH:mm:ssZ"
                                );
                                subEvent.endAt = subEventEndAt.format(
                                    "YYYY-MM-DDTHH:mm:ssZ"
                                );
                                startIndex = j;

                                if (subEvent.startAt !== event.startAt) {
                                    subEvent.startMoment = "am";
                                }

                                if (subEvent.endAt !== event.endAt) {
                                    subEvent.endMoment = "pm";
                                }
                                result.push(subEvent);
                            }
                        }

                        if (j === days.length - 1) {
                            let subEvent = {...event};
                            let subEventStartAt = moment.parseZone(
                                days[startIndex].dateAt
                            );
                            let subEventEndAt = moment.parseZone(
                                days[j].dateAt
                            );
                            subEvent.startAt = subEventStartAt.format(
                                "YYYY-MM-DDTHH:mm:ssZ"
                            );
                            subEvent.endAt = subEventEndAt.format(
                                "YYYY-MM-DDTHH:mm:ssZ"
                            );
                            if (subEvent.startAt !== event.startAt) {
                                subEvent.startMoment = "am";
                            }

                            if (subEvent.endAt !== event.endAt) {
                                subEvent.endMoment = "pm";
                            }
                            result.push(subEvent);
                        }
                    }
                }
            } else {
                result.push(event);
            }
        }

        if (props.autofillable && type !== "view" && !isMonthSubmitted) {
            const autofilledDays = autofillDays();

            for (let index = 0; index < autofilledDays.length; index++) {
                const element = autofilledDays[index];
                result.push(element);
            }
        }

        return result;
    };

    const splitConstraintsByWeek = () => {
        let result = [];
        let constraints = overtimes.filter((overtime) => {
            let overtimeCode = overtimeCodeRepo.findWithoutHook(
                overtime.code,
                type
            );
            if (overtimeCode) {
                if (overtimeCode.category === "availability") {
                    return overtime;
                }
            }
        });


        for (let index = 0; index < constraints.length; index++) {
            const constraint = constraints[index];
            let startAt = getStartDateForMonth(constraint.startAt);
            let endAt = getEndDateForMonth(constraint.endAt);
            const days = dayRepo.findDaysForRange(startAt, endAt, type);
            if (days.length > 0) {
                let startIndex = 0;
                for (let j = 0; j < days.length; j++) {
                    const currentDay = days[j];
                    if (j > 0) {
                        const previousDay = days.filter(
                            (element) =>
                                moment
                                    .parseZone(element.dateAt)
                                    .format("DD/MM/YYYY") ===
                                moment
                                    .parseZone(currentDay.dateAt)
                                    .subtract(1, "days")
                                    .format("DD/MM/YYYY")
                        );
                        if (
                            moment.parseZone(currentDay.dateAt).isoWeekday() ===
                            1
                        ) {
                            let subEvent = {...constraint};
                            let subEventStartAt = moment.parseZone(
                                days[startIndex].dateAt
                            );
                            let subEventEndAt = moment.parseZone(
                                days[j - 1].dateAt
                            );
                            subEvent.startAt = subEventStartAt.format(
                                "YYYY-MM-DDTHH:mm:ssZ"
                            );
                            subEvent.endAt = subEventEndAt.format(
                                "YYYY-MM-DDTHH:mm:ssZ"
                            );
                            startIndex = j;
                            result.push(subEvent);
                        }
                    }

                    if (j === days.length - 1) {
                        let subEvent = {...constraint};
                        let subEventStartAt = moment.parseZone(
                            days[startIndex].dateAt
                        );
                        let subEventEndAt = moment.parseZone(days[j].dateAt);
                        subEvent.startAt = subEventStartAt.format(
                            "YYYY-MM-DDTHH:mm:ssZ"
                        );
                        subEvent.endAt = subEventEndAt.format(
                            "YYYY-MM-DDTHH:mm:ssZ"
                        );
                        result.push(subEvent);
                    }
                }
            }
        }
        return result;
    };

    const splitHours = () => {
        let hours = overtimes.filter((overtime) => {
            let overtimeCode = overtimeCodeRepo.findWithoutHook(
                overtime.code,
                type
            );
            if (overtimeCode) {
                if (overtimeCode.category === "intervention") {
                    return overtime;
                }
            }
        });
        let result = [];
        if (!isEmpty(hours)) {
            for (let i = 0; i < hours.length; i++) {
                const hourEvent = hours[i];
                let overtimeCode = overtimeCodeRepo.findWithoutHook(
                    hourEvent.code,
                    type
                );
                hourEvent.days.map((day, key) => {
                    for (let j = 0; j < day.hours.length; j++) {
                        const hour = day.hours[j];
                        let index = parseInt(
                            moment.parseZone(day.date).format("DD")
                        );
                        if (!result[index]) {
                            result[index] = [];
                        }
                        hour.date = day.date;
                        hour.id = hourEvent.id;
                        hour.description = overtimeCode
                            ? overtimeCode.description
                            : "";
                        hour.overtimeCode = overtimeCode;
                        hour.hourCount = hourEvent.hourCount;
                        hour.days = hourEvent.days;
                        result[index].push(hour);
                    }
                });
            }
        }

        if (extraActivities) {
            extraActivities.map((extraActivity) => {
                extraActivity.complementaries.map((day) => {
                    if (day.hours.length > 0) {
                        let index = parseInt(
                            moment.parseZone(day.date).format("DD")
                        );
                        if (!result[index]) {
                            result[index] = [];
                        }

                        let extraActivity = {};
                        extraActivity.date = day.date;
                        extraActivity.id = 0;
                        let duration = 0;
                        day.hours.map((hour) => {
                            let overtimeCode = overtimeCodeRepo.findWithoutHook(
                                hour.code,
                                type
                            );
                            duration += hour.duration;

                            if (!hour.code) {
                                extraActivity.description = "Activité complémentaire";
                            } else if (overtimeCode) {
                                extraActivity.description = overtimeCode.description
                            }
                            extraActivity.overtimeCode = hour.code;
                            extraActivity.start = hour.start;
                            extraActivity.end = hour.end;
                            extraActivity.hourCount = duration;
                            extraActivity.duration = duration;
                            result[index].push(extraActivity)
                        })
                    }
                })

                extraActivity.constraints.map((day) => {
                    if (day.hours.length > 0) {
                        let index = parseInt(
                            moment.parseZone(day.date).format("DD")
                        );
                        if (!result[index]) {
                            result[index] = [];
                        }

                        let extraActivity = {};
                        extraActivity.date = day.date;
                        extraActivity.id = 0;
                        let duration = 0;

                        day.hours.map((hour) => {
                            let overtimeCode = overtimeCodeRepo.findWithoutHook(
                                hour.code,
                                type
                            );
                            duration += hour.duration;

                            if (!hour.code) {
                                extraActivity.description = "Intervention en astreinte";
                            } else if (overtimeCode) {
                                extraActivity.description = overtimeCode.description
                            }
                            duration += hour.duration;
                            extraActivity.overtimeCode = hour.code;
                            extraActivity.start = hour.start;
                            extraActivity.end = hour.end;
                            extraActivity.hourCount = duration;
                            extraActivity.duration = duration;
                            result[index].push(extraActivity)
                        })
                    }
                })
            })
        }

        return result;
    };

    return (
        <div id="event-container">
            {splitEventsByWeek().map((item, key) => (
                <Event
                    entity={item}
                    key={key}
                    currentMonth={props.currentMonth}
                    firstDayOfMonth={props.firstDayOfMonth}
                    hovered={hoveredEventId === item.id}
                    mainActivity={mainActivity}
                    autofillable={props.autofillable}
                    isMonthSubmitted={props.isMonthSubmitted}
                    isMonthEditable={isMonthEditable}
                    type={type}
                    modality={modality}
                    personType={personType}
                />
            ))}
            {splitConstraintsByWeek().map((item, key) => (
                <Availability
                    entity={item}
                    key={key}
                    currentMonth={props.currentMonth}
                    currentYear={props.currentYear}
                    firstDayOfMonth={props.firstDayOfMonth}
                    hovered={hoveredOvertimeId === item.id}
                    isMonthEditable={isMonthEditable}
                    type={type}
                    personType={personType}
                />
            ))}
            {splitHours().map((item, key) => (
                <Intervention
                    entity={item[0]}
                    key={key}
                    currentMonth={props.currentMonth}
                    currentYear={props.currentYear}
                    firstDayOfMonth={props.firstDayOfMonth}
                    hovered={hoveredOvertimeId === item[0].id}
                    isMonthEditable={isMonthEditable}
                    type={type}
                    personType={personType}
                />
            ))}
        </div>
    );
};

export default EventContainer;
