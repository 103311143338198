import React, { Fragment } from "react";

import moment from "moment";
import "moment/locale/fr";

import {
    HOUR_INPUT,
    TICKET_INPUT,
} from "components/TimesheetContainer/Forms/Event/StepActions/Step3Overtime";

import HourInputLine from "components/TimesheetContainer/Forms/Event/StepActions/HourInputLine";
import TicketInputLine from "components/TimesheetContainer/Forms/Event/StepActions/TicketInputLine";
import { FieldArray } from "formik";
import {useSelector} from "react-redux";

const DayLine = (props) => {
    const overtimeType = props.overtimeType;
    const onChange = props.onChange;
    const onBlur = props.onBlur;
    const values = props.values;
    const path = props.path ? props.path : 'days';
    const isMobile = useSelector((state) => state.common.isMobile)

    const TotalHoursContainer = () => {
        let array = values.hours;
        let duration = 0;
        let durationString;
        array.forEach((element) => {
            if (element) {
                let startTime = moment.parseZone(element.start, "HH:mm");
                let endTime = moment.parseZone(element.end, "HH:mm");
                if (endTime.format("HH:mm") === "00:00") {
                    duration += 24 * 60 * 60 * 1000 + endTime.diff(startTime);
                } else {
                    duration += endTime.diff(startTime);
                }
            }
        });

        let hours = parseInt(moment.duration(duration).asHours());
        let minutes = parseInt(moment.duration(duration).asMinutes()) % 60;

        if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            durationString = hours + ":" + minutes;
        } else {
            durationString = "0:00";
        }

        return durationString;
    };

    return (
        <div className="overtime-day-line">
            <div className="label">
                {moment.parseZone(props.day.date).format(isMobile ? "ddd MM" : "dddd D MMMM")}
            </div>
            {overtimeType === HOUR_INPUT ? (
                <Fragment>
                    <div className="hour-line-container">
                        <FieldArray
                            name={`${path}[${props.id}].hours`}
                            render={(arrayHelpers) =>
                                values.hours.map((hour, key) => (
                                    <div className="hour-line" key={key}>
                                        <HourInputLine
                                            value={values.hours[key]}
                                            key={key}
                                            id={props.id}
                                            hour={key}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            path={path}
                                        />
                                        <div className="hour-input-actions">
                                            <i
                                                onClick={() => {
                                                    arrayHelpers.insert(
                                                        key + 1,
                                                        {
                                                            start: "",
                                                            end: "",
                                                        }
                                                    );
                                                }}
                                                className="far fa-plus-circle"
                                            ></i>
                                            {values.hours.length > 1 ? (
                                                <i
                                                    onClick={() => {
                                                        arrayHelpers.remove(
                                                            key
                                                        );
                                                    }}
                                                    className="far fa-times"
                                                ></i>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                ))
                            }
                        />
                    </div>
                    <div
                        className="total-hours-container"
                        style={{ lineHeight: values.hours.length * 29 + "px" }}
                    >
                        <div>
                            <TotalHoursContainer />
                        </div>
                    </div>
                </Fragment>
            ) : (
                ""
            )}
            {overtimeType === TICKET_INPUT ? (
                <TicketInputLine
                    value={values.tickets}
                    id={props.id}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            ) : (
                ""
            )}
        </div>
    );
};

export default DayLine;
