import store from "store/ConfigureStore";
import { isEmpty } from "utils/Utils";

import { useSelector } from "react-redux";

class EventCodeRepository {
    find = (id, type) => {
        return useSelector((state) => {
            if (id) {
                let eventCodes;




                // if (type === "validation") {
                //     eventCodes = state.validation.eventCodes;
                // } else if (type === "view") {
                //     eventCodes = state.validation.craEventCodes;
                // } else {
                //     eventCodes = state.timesheet.entities.eventCodes;
                // }

                if (type === "validation") {
                    eventCodes = state.validation.eventCodes;
                } else {
                     eventCodes = state.validation.craEventCodes;
                    if (isEmpty(eventCodes)) {
                        eventCodes = state.timesheet.entities.eventCodes;
                    }
                }
                if (!isEmpty(eventCodes)) {
                    let result = null;
                    let array = Object.values(eventCodes);
                    array.forEach((element) => {
                        if (element.id === id) {
                            result = element;
                        }
                    });
                    return result;
                }
            }

            return;
        });
    };

    findWithoutHook = (id, type) => {
        let eventCodes = store.getState().validation.craEventCodes;
        if (isEmpty(eventCodes)) {
            eventCodes = store.getState().timesheet.entities.eventCodes;
        }

        if (!isEmpty(eventCodes)) {
            if (id) {
                let result = null;
                let array = Object.values(eventCodes);
                array.forEach((element) => {
                    if (element.id === id) {
                        result = element;
                    }
                });
                return result;
            }
        }

        return;
    };

    findForAffectationCode = (code) => {
        if (code) {
            let eventCodes = store.getState().timesheet.entities.eventCodes;
            if (!isEmpty(eventCodes)) {
                let result = null;
                let array = Object.values(eventCodes);
                array.forEach((element) => {
                    if (element.code === code) {
                        result = element;
                    }
                });
                return result;
            }
        }

        return;
    };
}

export default EventCodeRepository;
