import React, {Fragment, useEffect, useState} from "react";
import {motion, AnimatePresence} from 'framer-motion/dist/framer-motion'
import {useDispatch, useSelector} from "react-redux";
import PageHeader from "../../common/PageHeader";
import * as ValidationActions from "actions/Validation";
import PersonTypeBadge from "../common/PersonTypeBadge";
import './AbsenceContainerMobile.scss'
import PersonRowDetailsMobile from "./PersonRowDetailsMobile";


const AbsenceContainerMobile = () => {
    const me = useSelector(state => state.user.me);
    const usersToDisplay = useSelector(state => state.validation.users);
    const dispatch = useDispatch()
    const [openUsers, setOpenUsers] = useState([])

    const loadList = () => {
        dispatch(ValidationActions.fetchAbsenceListForMobile(me.matricule));
    };

    const isUserOpen = (user) => {
        if (openUsers) {
            if (openUsers.find((openUser) => openUser === user.id)) {
                return true
            }
        }
        return false
    }

    const toggleUser = (user) => {
        if (!isUserOpen(user)) {
            let tempArray = [...openUsers]
            tempArray.push(user.id)
            setOpenUsers(tempArray)
        } else {
            setOpenUsers(openUsers.filter(openUser => openUser !== user.id))
        }
    }

    useEffect(() => {
        if (me.loaded) {
            loadList();
        }
    }, [me.loaded]);

    return <div id="page-container">
        <PageHeader title="Validation d'absences" type="absence"/>
        {usersToDisplay.length > 0 ? (
            usersToDisplay.sort((a, b) => {
                return a.lastname.localeCompare(b.lastname);
            }).map((user, key) => {
                return <Fragment key={key}>
                    <div className={"person-row-mobile" + (isUserOpen(user) ? ' open-row' : '')} key={key}
                         onClick={() => toggleUser(user)}>
                        <div className="person-row-mobile-details">
                            <div className="person-row-mobile-name">
                                {user.firstname + ' ' + user.lastname}
                            </div>
                            <PersonTypeBadge personType={user.personType}/>
                            <div className="person-row-mobile-matricule">Matricule : {user.matricule}</div>
                        </div>
                        <div>
                            <i className={"fas fa-chevron-right open-indicator"}></i>
                        </div>
                    </div>
                    <AnimatePresence>
                        {isUserOpen(user) && (
                            <motion.div
                                key={user.id}
                                initial={{height: 0, opacity: 0}}
                                animate={{height: 'auto', opacity: 1}}
                                exit={{height: 0, opacity: 0}}
                                transition={{duration: 0.3}}
                                className="person-row-absence-details-mobile"
                            >
                                <PersonRowDetailsMobile user={user}/>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Fragment>
            })
        ) : <div className="empty-validation-page">Aucune absence à valider</div>}
    </div>
}

export default AbsenceContainerMobile
