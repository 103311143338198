import React, {useEffect, useState} from "react";
import "./HomePageMobile.scss";
import {HomeAppContainer} from "../../common/HomeAppContainer";
import applications from "../../../config/Home.json"
import {useDispatch, useSelector} from "react-redux";
import * as NewsActions from "actions/News";

const HomePageMobile = () => {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.user.me);
    const internalNews = useSelector((state) => state.news.entities.internalNews);
    const [isImageLoaded, setImageLoaded] = useState(false)

    const onlyContractor = me.type === "contractor"

    const findApps = (list) => {
        return applications.filter(app => list.includes(app.name) && (onlyContractor ? app.contractor === true : true)).map(app => app.name)
    }
    const mainApps = findApps(['timesheet', 'contacts', 'expensya'])
    const ressourceApps = findApps(['news', 'documents', 'social', 'rgpd'])
    const settingsApps = findApps(['settings'])
    const validationApps = findApps(['validateMonth', 'validateAbsence', 'planning', 'validateExtra'])

    let classNames = ['homepage-mobile']
    if (onlyContractor) {
        classNames.push('homepage-mobile-contractor')
    }

    const offline = !navigator.onLine
    // const offline = true

    const logo = onlyContractor ? "/logo-contractor.png" : "/logo-my-synchrone-w.svg"

    useEffect(() => {
        dispatch(NewsActions.fetchInternalNews(true)).then(() => {
            setImageLoaded(true)
        });
        window.scrollTo(0, 0)
    }, []);

    return <div className={classNames.join(" ") + (isImageLoaded ? ' animated' : '')}>
        <div className="homepage-content">
            <div className="logo-synchrone-mobile">
                <img src={logo} alt="MySynchrone"/>
            </div>
            <h2>Bonjour {me.firstname},</h2>
            <HomeAppContainer isMobile={true} applications={mainApps}/>

            {offline ?
                <div className={"offline-warning"}>Vous êtes hors ligne, les fonctionnalités de votre application seront
                    limitées tant que vous n'aurez pas accès à internet.</div> : !onlyContractor && <>
                <h2>A la une</h2>
                {internalNews?.url ? (<a href={internalNews?.url} className="news-container">
                    <img src={internalNews?.fullImageUrl} alt=""/>
                    <p>{internalNews?.title}</p>
                </a>) : (
                    <div className="news-container">
                        <img src={internalNews?.fullImageUrl} alt=""/>
                        <p>{internalNews?.title}</p>
                    </div>
                )}
            </>}

            {ressourceApps.length > 0 && <>
                <h2>Ressources</h2>
                <HomeAppContainer isMobile={true} applications={ressourceApps}/>
            </>}

            {me.hasRole("ROLE_MANAGER") && <>
                <h2>Administration</h2>
                <HomeAppContainer isMobile={true} applications={validationApps}/>
            </>}

            {settingsApps.length > 0 && <>
                <h2>Paramètres</h2>
                <HomeAppContainer isMobile={true} applications={settingsApps}/>
            </>}
        </div>
    </div>
};

export default HomePageMobile;
