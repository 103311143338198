import React from "react";
import "./HomeApp.scss";
import {Link} from "react-router-dom";
import {getContactDefaultRoute} from "../../config/Routes";
import {useSelector} from "react-redux";

export const HomeApp = (props) => {
    const me = useSelector((state) => state.user.me);
    const offline = !navigator.onLine

    let url = props.url === null ? getContactDefaultRoute(me) : props.url
    const external = !url.startsWith('/')

    const classNames = ["home-app"]
    if (props.className) {
        classNames.push(props.className)
    }

    const styles = {};
    if (props.isContractor) {
        classNames.push("home-app-contractor")
        if (props.color) {
            styles.backgroundColor = props.color;
            styles.color = "#FFFFFF";
        }
    }

    let text = props.mobile ? props.text.replace('Mes ', '') : props.text
    text = text.charAt(0).toUpperCase() + text.slice(1)

    const attributes = {
        className: classNames.join(" ")
    }

    if (external) {
        attributes.target = "_blank"
        url = {
            pathname: url
        }
    }

    if (offline && !props.isAvailableOffline) {
        url = '#'
    }

    return <Link to={url} style={props.isContractor ? styles : {}}
                 disabled={offline && !props.isAvailableOffline} {...attributes} >
        <div className="icon">
            <i className={"fal fa-" + props.picto} style={{color: props.isContractor ? '#fff' : props.color}}></i>
        </div>
        <div className="label">{text}</div>
        {!props.mobile && <div className="subtext">{props.subtext}</div>}
    </Link>
};
