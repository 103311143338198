import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Lightbox from "components/common/Lightbox";
import * as ValidationActions from "actions/Validation";
import {changeOverlayContent} from "events/OverlayEvents";
import {monthInLetter} from "utils/Utils";

import "./LockForm.scss";
import {setLoading} from "actions/Common";
import * as TimesheetActions from "actions/Timesheet";
import Button from "../../TimesheetContainer/Forms/Event/Button";

const LockForm = (props) => {
    const dispatch = useDispatch();
    const [comment, setComment] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const {period, reload} = props;

    const close = () => {
        changeOverlayContent(null);
    };

    const handleSubmit = () => {
        if (!submitting) {
            setSubmitting(true);
            let json = {comment: comment};
            let body = JSON.stringify(json);
            dispatch(setLoading(true));
            dispatch(ValidationActions.lockPeriod(period.id, body)).then(() => {
                dispatch(setLoading(false));
                setSubmitting(false);
                changeOverlayContent(null);
                reload();
                dispatch(TimesheetActions.notifySuccess("Période clôturée"));
            });
        }
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    return (
        <Lightbox className="lb-centered lock-period-lb">
            <div className="lock-period-title">Clôturer la période</div>
            <div className="lock-period-to-be-locked">
                Période à clôturer : {monthInLetter(period.month)} {period.year}
            </div>
            <div className="lock-period-recap">
                <div className="recap-row">
                    <div className="recap-label">Nb CRAs validés niveau 2</div>
                    <div className="recap-amount">{period.validated2}</div>
                </div>
                <div className="recap-row">
                    <div className="recap-label">
                        Nb CRAs non envoyés par les collaborateurs
                    </div>
                    <div className="recap-amount">{period.nonSubmitted}</div>
                </div>
                <div className="recap-total">{period.total}</div>
            </div>
            <div className="lock-period-warning">
                <span className="warning-icon">
                    <i className="fas fa-exclamation-triangle"></i>
                </span>
                <div className="warning-message">
                    La clôture est irréversible. Tous les CRAs et les absences
                    de la période seront clôturés. Aucune modification
                    ultérieure ne pourra être effectuée.
                </div>
            </div>
            <div className="lock-period-comment">
                <div className="comment-label">Commentaire de clôture :</div>
                <textarea
                    onChange={(e) => handleCommentChange(e)}
                    className="comment-input"
                    value={comment}
                ></textarea>
            </div>
            <div className="lock-period-actions">
                <Button
                    onClick={close}
                    className={"cancel-btn"}
                    text={"Annuler"}
                />
                <Button
                    onClick={handleSubmit}
                    className={"next-btn"}
                    text={"Confirmer"}
                    processable={true}
                    isProcessing={submitting}
                />
            </div>
        </Lightbox>
    );
};

export default LockForm;
