import React from "react";
import {
    EntityRepository,
    EVENT_CODE_REPOSITORY,
} from "store/EntityRepository";
import AbsenceDates from "../../TimesheetContainer/Calendar/AbsenceDates";
import AbsenceStatus from "../../TimesheetContainer/Calendar/AbsenceStatus";
import moment from "moment";
import "moment/locale/fr";
import "./AbsenceHistoryLine.scss"

const AbsenceHistoryLine = ({event}) => {
    const eventCodeRepo = EntityRepository().getRepository(
        EVENT_CODE_REPOSITORY
    );
    const eventCode = eventCodeRepo.find(event.eventCode);

    return <div className="absence-history-line">
        <div className="absence-history-line-details">
            <AbsenceDates event={event} format={"DD/MM/YYYY"}/>
            <div className="absence-history-line-event-code-description">
                {eventCode && eventCode.description} - <span
                className="absence-history-line-days-count">{event.daysCount} J</span>
            </div>
            {event.createdAt && <div className="absence-history-line-action-at">Posée le {moment(event.createdAt).format("DD/MM/YYYY")}</div>}
            {event.validated1At && <div className="absence-history-line-action-at">Validée le {moment(event.validated1At).format("DD/MM/YYYY")}</div>}
        </div>
        <div>
            <AbsenceStatus event={event} tooltip={false}/>
        </div>
    </div>
}

export default AbsenceHistoryLine