import React, {Fragment} from "react";
import ChangePersonalEmailContainer from "components/MyAccountContainer/ChangePersonalEmailContainer";
import ChangePasswordContainer from "components/MyAccountContainer/ChangePasswordContainer";

import "./MyAccountContainer.scss";
import PageHeader from "../common/PageHeader";

const MyAccountContainer = (props) => {
    const {handleClose} = props

    return (
        <div className="parameters-category-container">
            <div className="parameters-subcategory-container">
                <PageHeader title={"Mon compte"} action={handleClose} type={"parameters"}/>
                {/*<div className="parameters-subcategory-title">*/}
                {/*    Mon compte*/}
                {/*</div>*/}
                <div className="parameters-subcategory-subtitle">
                    Modifiez votre mot de passe ou votre email personnel
                </div>
                <ChangePersonalEmailContainer/>
                <div className="parameters-separator"></div>
                <ChangePasswordContainer/>
            </div>
        </div>
    );
};

export default MyAccountContainer;
