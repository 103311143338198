import React from "react";

import PageHeader from "../common/PageHeader";
import InstallProcedure from "./InstallProcedure";

const InstallContainer = (props) => {
    const handleClose = props.handleClose;

    return <div className="parameters-category-container">
        <div className="parameters-subcategory-container">
            <PageHeader title={"Installer l'application"} action={handleClose} type={"parameters"}/>
            <InstallProcedure/>
            {/*<div className="parameters-subcategory-subtitle">*/}
            {/*    Configurez ici vos préférences relatives aux notifications.*/}
            {/*</div>*/}
        </div>
    </div>
}

export default InstallContainer
