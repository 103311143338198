export const firebaseConfig = {
    apiKey: "AIzaSyC13kQY35X_n7FXfuP04NtoN8ccaJDUyOc",
    authDomain: "mysynchrone.firebaseapp.com",
    databaseURL: "https://mysynchrone.firebaseio.com",
    projectId: "mysynchrone",
    storageBucket: "mysynchrone.appspot.com",
    messagingSenderId: "660400817306",
    appId: "1:660400817306:web:ab5f0f9b00b604f98c8b23",
    measurementId: "G-FNEP5N8FNS"
};
