import React, {Fragment, useEffect, useState} from "react";
import PageHeader from "../../common/PageHeader";
import {useDispatch, useSelector} from "react-redux";
import {motion, AnimatePresence} from 'framer-motion/dist/framer-motion'
import * as ValidationActions from "actions/Validation";
import PersonTypeBadge from "../common/PersonTypeBadge";
import {monthInLetter} from "../../../utils/Utils";
import ExtraActivitiesRequestLineMobile from "./ExtraActivitiesRequestLineMobile";

const ExtraActivitiesRequestsContainerMobile = () => {
    const extraActivities = useSelector(state => state.validation.extraActivityMonths);
    const me = useSelector(state => state.user.me);
    const [isSubmitting, setSubmitting] = useState(false);
    const [comment, setComment] = useState("");
    const [rows, setRows] = useState([]);
    const [openUsers, setOpenUsers] = useState([])
    const dispatch = useDispatch()

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleSubmit = (action) => {
        if (!isSubmitting) {
            setSubmitting(true);
            // let json = {
            //     event: absence.id,
            //     comment: comment,
            //     updated_at: moment
            //         .parseZone(absence.updatedAt)
            //         .format("YYYY-MM-DD[T]HH:mm:ss"),
            // };
            //
            // if (action === "validate") {
            //     json.action = "validated1";
            // } else if (action === "refuse") {
            //     json.action = "refused";
            // }
            //
            // let body = JSON.stringify(json);
            // dispatch(ValidationActions.postEventAction(body)).then(() => {
            //     setSubmitting(false);
            //     handleClose()
            //     dispatch(ValidationActions.fetchAbsenceListForMobile(me.matricule));
            // });
        }
    }

    const loadList = () => {
        dispatch(ValidationActions.fetchExtraActivitiesForMobile(me.matricule)).then(() => {
        })
    };

    const isUserOpen = (user) => {
        if (openUsers) {
            if (openUsers.find((openUser) => openUser === user.id)) {
                return true
            }
        }
        return false
    }

    const toggleUser = (user) => {
        if (!isUserOpen(user)) {
            let tempArray = [...openUsers]
            tempArray.push(user.id)
            setOpenUsers(tempArray)
        } else {
            setOpenUsers(openUsers.filter(openUser => openUser !== user.id))
        }
    }

    useEffect(() => {
        if (me.loaded) {
            loadList()
        }
    }, [])

    useEffect(() => {
        let tempRows = []
        if (extraActivities.length > 0) {
            extraActivities.map((element) => {
                if (tempRows.some(obj => obj.id === element.user.id)) {
                    let index = tempRows.findIndex(obj => obj.id === element.user.id)
                    tempRows[index].months.push(element)
                } else {
                    tempRows.push({"id": element.user.id, "user": element.user, "months": [element]})
                }
            })
        }
        setRows(tempRows)
    }, [extraActivities])

    return <div id="page-container">
        <PageHeader title="Validation demande d'activité complémentaire" type="hours-not-worked"/>
        {rows.length > 0 ? (rows.map((row, key) => {
            return <Fragment key={key}>
                <div className={"person-row-mobile" + (isUserOpen(row.user) ? ' open-row' : '')} key={key}
                     onClick={() => toggleUser(row.user)}>
                    <div className="person-row-mobile-details">
                        <div className="person-row-mobile-name">
                            {row.user.firstname + ' ' + row.user.lastname}
                        </div>
                        <PersonTypeBadge personType={row.user.personType}/>
                        <div className="person-row-mobile-matricule">Matricule : {row.user.matricule}</div>
                    </div>
                    <div>
                        <i className={"fas fa-chevron-right open-indicator"}></i>
                    </div>
                </div>
                <AnimatePresence>
                    {isUserOpen(row.user) && (
                        <motion.div
                            key={row.user.id}
                            initial={{height: 0, opacity: 0}}
                            animate={{height: 'auto', opacity: 1}}
                            exit={{height: 0, opacity: 0}}
                            transition={{duration: 0.3}}
                            className="person-row-absence-details-mobile"
                        >
                            {row.months.sort((a, b) => {
                                if (a.year !== b.year) {
                                    return a.year - b.year
                                }
                                return a.month - b.month
                            }).map((month, key) => {
                                return <ExtraActivitiesRequestLineMobile key={key} month={month} loadList={loadList}/>
                            })}
                        </motion.div>
                    )}
                </AnimatePresence>
            </Fragment>
        })) : <div className="empty-validation-page">Aucune demande à valider</div>}
    </div>
}

export default ExtraActivitiesRequestsContainerMobile