const MOBILE_BREAKPOINT = '1000'

export const getOSInfo = () => {
    const userAgent = navigator.userAgent;
    // alert(userAgent)
    let osName = 'Inconnu';
    let osVersion = 'Inconnu';

    try {
        if (/Windows/.test(userAgent)) {
            osName = 'Windows';
            osVersion = /Windows NT ([\d.]+)/.exec(userAgent)[1];
        } else if (/iOS|iPhone|iPad|iPod/.test(userAgent)) {
            osName = 'iOS';
            const osVersionMatch = /OS (\d+[_.]\d+[_.]\d+)/.exec(userAgent);
            if (osVersionMatch && osVersionMatch[1]) {
                osVersion = osVersionMatch[1].replace(/_/g, '.');
            } else {
                osVersion = 'Inconnu';
            }
        } else if (/Mac OS X/.test(userAgent)) {
            osName = 'macOS';
            const osVersionMatch = /Mac OS X (\d+[_.]\d+[_.]\d+)/.exec(userAgent);
            if (osVersionMatch && osVersionMatch[1]) {
                osVersion = osVersionMatch[1].replace(/_/g, '.');
            } else {
                osVersion = 'Inconnu';
            }
        } else if (/Android/.test(userAgent)) {
            osName = 'Android';
            osVersion = /Android (\d+[._]\d+[._]\d+)/.exec(userAgent)[1];
        } else if (/Linux/.test(userAgent)) {
            osName = 'Linux';
            // Vous pouvez ajouter des conditions pour différents types de distribution Linux si nécessaire
        }
    } catch (e) {
        // nope
    }

    return {
        osName: osName,
        osVersion: osVersion,
    };
};

export const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    console.log(navigator)
    const vendor = navigator.vendor;
    let browserName = 'Inconnu';
    let browserVersion = 'Inconnu';

    if (/Chrome/.test(userAgent) && /Google Inc/.test(vendor)) {
        browserName = 'Google Chrome';
        browserVersion = /Chrome\/([\d.]+)/.exec(userAgent)[1];
    } else if (/Firefox/.test(userAgent)) {
        browserName = 'Mozilla Firefox';
        browserVersion = /Firefox\/([\d.]+)/.exec(userAgent)[1];
    } else if (/Safari/.test(userAgent) && /Apple/.test(vendor)) {
        browserName = 'Apple Safari';
        browserVersion = /Version\/([\d.]+)/.exec(userAgent)[1];
    } else if (/MSIE|Trident/.test(userAgent)) {
        browserName = 'Internet Explorer';
        browserVersion = /(?:MSIE|rv:)\s?([\d.]+)/.exec(userAgent)[1];
    } else if (/Edge/.test(userAgent)) {
        browserName = 'Microsoft Edge';
        browserVersion = /Edge\/([\d.]+)/.exec(userAgent)[1];
    } else if (/Opera/.test(userAgent) || /OPR/.test(userAgent)) {
        browserName = 'Opera';
        browserVersion = /(?:Opera|OPR)\/([\d.]+)/.exec(userAgent)[1];
    }

    return {
        browserName: browserName,
        browserVersion: browserVersion,
    };
};

export const isPWA = () => {
    return window.matchMedia('(display-mode: standalone)').matches
}

export const isDesktop = () => {
    return !window.matchMedia(`only screen and (max-width: ${MOBILE_BREAKPOINT}px)`).matches
}

export const isMobile = () => {
    return window.matchMedia(`only screen and (max-width: ${MOBILE_BREAKPOINT}px)`).matches
}
