import React from "react";
import {Link} from "react-router-dom";
import {removeCache, removeExpirableCache} from "utils/LocalStorage";
import {setAppLoading} from "actions/Common";
import {useSelector, useDispatch} from "react-redux";
import * as UserActions from "actions/User";
import {logout} from "utils/Utils";

const LogoutButton = (props) => {
    const dispatch = useDispatch();
    const me = useSelector((state) => state.user.me);

    const exit = () => {
        localStorage.removeItem("switchUser");
        removeCache("userForAbsenceList");
        removeCache("userForCRAList");
        removeCache("userForAbsenceListFetchedAt");
        removeCache("userForCRAListFetchedAt");
        removeExpirableCache("overtimeCodes");
        removeExpirableCache("eventCodes");
        me.loaded = false;
        dispatch(setAppLoading(true));
        dispatch(UserActions.fetchCurrentUser());
    };

    return localStorage.getItem("switchUser") ? (
        <Link to="/" onClick={exit} className={props.className}>
            <i className="fal fa-sign-out fa-fw "></i>
            Déconnexion
        </Link>
    ) : (
        <Link to="#" onClick={logout} className={props.className}>
            <i className="fal fa-sign-out fa-fw"></i>
            Déconnexion
        </Link>
    )
}

export default LogoutButton
