class EventCode {
    id;
    type;
    code;
    description;
    priority;
    color;
    startAt;
    endAt;
    editable;
    shortCode;
    relevantCounter;
    eligibleToRemote;

    constructor(json) {
        this.id = json.id;
        this.type = json.type;
        this.code = json.code;
        this.description = json.description;
        this.priority = json.priority;
        this.color = json.color;
        this.startAt = json.start_at;
        this.endAt = json.end_at;
        this.editable = json.editable;
        this.shortCode = json.short_code;
        this.relevantCounter = json.relevant_counter;
        this.eligibleToRemote = json.eligible_to_remote;
    }

    get label() {
        return this.description;
    }

    get typeLabel() {
        switch (this.type) {
            case 'absence' :
            case 'sickness' : return 'Absence'
            default: return 'Activité'
        }
    }
}

export default EventCode;
