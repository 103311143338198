import React, {Fragment, useEffect, useState} from "react";

import {
    EntityRepository,
    HALFDAY_REPOSITORY,
    EVENT_CODE_REPOSITORY,
    EVENT_REPOSITORY, OVERTIME_REPOSITORY, OVERTIME_CODE_REPOSITORY
} from "store/EntityRepository";
import {useDispatch, useSelector} from "react-redux";
import {createPortal} from "react-dom";
import useClickOutside from "../../../hooks/useClickOutside";
import moment from "moment";
import * as TimesheetActions from "actions/Timesheet";
import {changeOverlayContent} from "../../../events/OverlayEvents";
import Edit from "../../TimesheetContainer/Forms/Event/Edit";
import {isDayAvailable} from "../../../utils/CalendarHelper";
import {useDaySelector} from "../../../hooks/useDaySelector";
import DeleteOvertime from "../../TimesheetContainer/Forms/Overtime/DeleteOvertime";
import {useMonth} from "../../../hooks/useMonth";
import {useOvertimeCodes} from "../../../hooks/useOvertimeCodes";
import {useEventCodes} from "../../../hooks/useEventCodes";

const Day = (props) => {
    const dispatch = useDispatch();
    const {selectDay, selectable, isSelectable} = useDaySelector();

    const me = useSelector((state) => state.user.me)
    moment.updateLocale("fr", {
        weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    });
    const day = props.day
    const halfDayRepo = EntityRepository().getRepository(HALFDAY_REPOSITORY);
    const eventRepo = EntityRepository().getRepository(EVENT_REPOSITORY);
    const eventCodeRepo = EntityRepository().getRepository(EVENT_CODE_REPOSITORY);
    const overtimeRepo = EntityRepository().getRepository(OVERTIME_REPOSITORY);
    // const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const monthEntity = useMonth()
    // const overtimeCodes = useSelector((state) => state.timesheet.entities.overtimeCodes);
    const overtimeCodes = useOvertimeCodes()
    const eventCodes = useEventCodes()
    const craMode = useSelector((state) => state.timesheet.craMode)
    const selectedStartDay = useSelector((state) => state.timesheet.selectedStartDay)
    const dayDetails = document.getElementById('day-details');
    const [showDetails, setShowDetails] = useState(false)
    const [overtimes, setOvertimes] = useState([])
    const [constraints, setConstraints] = useState(false)
    const [complementaries, setComplementaries] = useState(false)
    const [hasExtra, setHasExtra] = useState(false)
    const [am, setAm] = useState(null)
    const [pm, setPm] = useState(null)

    const ref = useClickOutside(() => {
        setShowDetails(false)
    });

    useEffect(() => {
        let complementaries = false
        let constraints = false
        if (typeof day.dateAt !== 'object') {
            let tmp = []
            if (overtimeCodes.length > 0) {
                const date = day.dateAt
                tmp = date ? overtimeRepo.findForDateWithoutHook(date) : []
                setOvertimes(tmp)
            }
            monthEntity.extraActivities.map(extraActivity => {
                extraActivity.complementaries.filter(activity => activity.date === day.dateAt.slice(0, 10)).map(activity => {
                    complementaries = activity
                })
                extraActivity.constraints.filter(activity => activity.date === day.dateAt.slice(0, 10)).map(activity => {
                    constraints = activity
                })
            })
            setConstraints(constraints)
            setComplementaries(complementaries)
            setHasExtra(tmp.length > 0 || constraints !== false || complementaries !== false)
        }

        setAm(buildHalfday(day.am))
        setPm(buildHalfday(day.pm))

    }, [monthEntity, overtimeCodes, eventCodes]);

    const isAutofillable = () => {
        return monthEntity.autofillable && day.workingState === "worked"
    }

    const buildHalfday = (halfdayId) => {
        const halfday = halfDayRepo.findWithoutHook(halfdayId)
        if (halfday?.event) {
            const event = eventRepo.findWithoutHook(halfday.event)
            if (event?.eventCode) {
                const eventCode = eventCodeRepo.findWithoutHook(event.eventCode)
                return {
                    event: event,
                    code: eventCode,
                    autofilled: false
                }
            }
        } else if (isAutofillable()) {
            return {
                code: eventCodeRepo.findWithoutHook(monthEntity.mainActivity),
                autofilled: true
            }
        } else if (day.workingState === "weekend") {
            return {
                code: {
                    typeLabel: 'Jour non travaillé',
                    description: 'Weekend'
                }
            }
        } else if (day.workingState === "public_holiday") {
            return {
                code: {
                    typeLabel: 'Jour non travaillé',
                    description: 'Férié'
                }
            }
        }


        return null
    }

    const getHalfdayClasses = () => {
        const classes = ['day-hd']
        if (am?.event) {
            classes.push('day-hd-am')
            const eventType = am?.code?.type
            if (eventType) {
                classes.push('day-hd-am-' + eventType)
                const state = am?.event?.state;
                if (state === 'validated1' || state === 'validated2') {
                    classes.push('day-hd-am-validated')
                }
            }
        }
        if (pm?.event) {
            classes.push('day-hd-pm')
            const eventType = pm?.code?.type
            if (eventType) {
                classes.push('day-hd-pm-' + eventType)
                const state = pm?.event?.state;
                if (state === 'validated1' || state === 'validated2') {
                    classes.push('day-hd-pm-validated')
                }
            }
        }

        return classes.join(' ')
    }

    const isValidated = () => {
        const AmState = am?.event?.state;
        const PmState = pm?.event?.state;

        return (AmState === 'validated1' || AmState === 'validated2') && (PmState === 'validated1' || PmState === 'validated2')
    }

    const handleClick = () => {
        if (typeof day.dateAt !== 'object') {
            if (craMode === 'view') {
                const overtimes = overtimeRepo.findForDateWithoutHook(day.dateAt)
                overtimes.map(overtime => {
                    if (typeof overtime.code !== "object") {
                        overtime.code = overtimeCodes.find(code => code.id === overtime.code)
                    }
                })
                setOvertimes(overtimes)
                setShowDetails(true)
            } else if (craMode === 'edit') {
                selectDay(day)
            }
        }
    }

    const isRemote = () => {
        const remote = monthEntity.remoteDays.find(remote => remote.day === day.dateAt.slice(0, 10))
        if (remote.am && remote.pm) {
            return 'full'
        } else if (remote.am) {
            return 'am'
        } else if (remote.pm) {
            return 'pm'
        } else {
            return false
        }
    }

    const getClasses = (day) => {
        const classes = ['day-mobile']
        if (day.workingState === "notCurrentMonth") {
            classes.push("not-current-month")
        } else if (!isDayAvailable(day, me)) {
            classes.push("unavailable");
        } else if (isAutofillable()) {
            classes.push("autofillable");
        } else {
            // console.log(day.workingState)
        }

        if (isValidated()) {
            classes.push("day-mobile-validated");
        }

        if (selectable(day)) {
            classes.push("selectable");
        }

        if (craMode === 'edit' && (!selectable(day) || monthEntity.locked)) {
            classes.push("not-selectable");
        }

        if (selectedStartDay === day) {
            classes.push("selected");
        }

        return classes.join(" ")
    }

    const editEvent = (event) => {
        if (!monthEntity.submitted && event.editable) {
            dispatch(TimesheetActions.checkEventBoundaries(event.id));
            changeOverlayContent(
                <Edit
                    action="edit"
                    targetEvent={event}
                    autofillable={monthEntity.autofillable}
                    mainActivity={eventCodeRepo.findWithoutHook(monthEntity.mainActivity)}
                    currentMonth={monthEntity}
                    monthEntity={monthEntity}
                    // modality={modality}
                />
            );
        }
    };

    const removeConstraint = (constraint) => {
        if (monthEntity.editable) {
            changeOverlayContent(
                <DeleteOvertime entity={constraint}/>
            );
        }
    }

    return <div className={getClasses(day)} onClick={handleClick}>
        <div className={"day-number" + (hasExtra ? " day-has-extra" : "")}>{day.DayNumber}</div>
        {(am || pm) && <div className={getHalfdayClasses()}></div>}
        {showDetails && createPortal(
            <div ref={ref} className={"day-details"}>
                <div className="day-details-header">
                    <div className="day-details-date">{moment(day.dateAt).format('dddd D MMMM')}</div>
                    {isRemote() &&
                        <div className={"day-remote day-remote-" + isRemote()}><i className="fa fa-home"></i></div>}
                </div>

                {((am?.event?.id !== pm?.event?.id) ? [am, pm] : [am]).map((hd, key) => <div key={key}
                                                                                             className={"day-details-block hd-type-" + (hd?.event ? hd?.code?.type : "autofilled")}>
                    <div>
                        <strong>{hd?.code?.typeLabel}</strong>
                        <div
                            className="day-details-desc">{hd?.code?.description} {am?.autofilled && "(autogénéré)"}</div>
                    </div>
                    {hd?.event?.editable && <i className="fa fa-pencil" onClick={() => editEvent(hd.event)}></i>}
                </div>)}
                {overtimes.map((overtime, key) => <div key={key} className="day-details-block day-constraint">
                    <div>
                        <strong>{overtime.code.label}</strong>
                        <div className="day-details-desc">{overtime.code.description}</div>
                    </div>
                    {monthEntity.editable && <i className="fa fa-trash" onClick={() => removeConstraint(overtime)}></i>}
                </div>)}
                {complementaries && <div className="day-details-block day-extra">
                    <div>
                        <strong>Activité complémentaire</strong>
                        {complementaries.hours.map((hour, key) => {
                            const overtimeCode = overtimeCodes.find(code => code.id === hour.code)
                            return <div className="day-details-desc"
                                        key={key}>{hour.start} - {hour.end} {overtimeCode ? "(" + overtimeCode.description + ")" : ""}</div>
                        })}
                    </div>
                </div>}
                {constraints && <div className="day-details-block day-extra-constraint">
                    <div>
                        <strong>Intervention en astreinte</strong>
                        {constraints.hours.map((hour, key) => {
                            const overtimeCode = overtimeCodes.find(code => code.id === hour.code)
                            return <div className="day-details-desc"
                                        key={key}>{hour.start} - {hour.end} {overtimeCode ? "(" + overtimeCode.description + ")" : ""}</div>
                        })}
                    </div>
                </div>}
            </div>, dayDetails
        )}
    </div>
}

export default Day
