import React from "react";
import "./AbsenceStatus.scss";

const AbsenceStatus = (props) => {
    const {event, hasTooltip} = props;
    let text;

    if (event.state === "submitted") {
        text = "En attente de validation";
    } else if (event.state === "validated1" || event.state === "validated2") {
        text = "Validé";
    } else if (event.state === "refused") {
        text = "Refusé";
    }

    const ToolTip = () => {
        return <div className="tooltip">{text}</div>;
    };

    return (
        <div className="absence-status">
            <span className={event.state}>
                {/* {event.state === "refused" ? (
                        <i className="fal fa-times"></i>
                    ) : (
                        ""
                    )} */}
                {hasTooltip && <ToolTip/>}
            </span>
        </div>
    );
};

export default AbsenceStatus;
