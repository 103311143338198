import React, {useEffect} from "react";
import CheckBox from "../TimesheetContainer/Calendar/CheckBox";
import {Formik} from "formik";
import ParametersSubCategoryContainer from "./ParametersSubCategoryContainer";

const ParametersCategoryContainer = (props) => {
    const {category, handleClose} = props;

    return <div className="parameters-category-container">
        {category.subCategories.map((subcategory, key) => {
            return <ParametersSubCategoryContainer subcategory={subcategory} key={key} handleClose={handleClose}/>
        })}

    </div>
}

export default ParametersCategoryContainer
