import React, {useEffect, useState} from "react";
import PageTransition from "../common/PageTransition";
import DocumentContainer from "./DocumentContainer";
import {useParams} from "react-router";

const DocumentsPage = () => {
    const { type } = useParams();

  const getPageText = (type) => {
    switch (type) {
      case 'social':
        return 'Mes informations sociales';
      case 'syndicats':
        return 'Mes communications syndicales';
      default:
        return 'Mes Documents';
    }
  };

  return (
    <PageTransition backgroundColor="#12afa9" text={getPageText(type)} icon="fa-folder-open">
      <DocumentContainer />
    </PageTransition>
  );
}

export default DocumentsPage;