import React, {useState} from "react";
import moment from "moment";
import "moment/locale/fr";
import {monthInLetter} from "../../../utils/Utils";
import SlidePageTransitionPortal from "../../common/SlidePageTransitionPortal";
import * as ValidationActions from "actions/Validation";
import PageHeader from "../../common/PageHeader";
import './ExtraActivitiesRequestLineMobile.scss'
import {useDispatch} from "react-redux";
import Button from "../../TimesheetContainer/Forms/Event/Button";

const ExtraActivitiesRequestLineMobile = ({month, loadList}) => {
    const [showPortal, setShowPortal] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false);
    const [isRefusing, setRefusing] = useState(false);
    const [isValidating, setValidating] = useState(false);
    const [comment, setComment] = useState("");
    const dispatch = useDispatch()

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleClose = () => {
        setShowPortal(false);
    };

    const handleSubmit = (action) => {
        if (!isSubmitting) {
            setSubmitting(true);
            let json = {
                month: month.id,
                comment: comment,
                updated_at: moment.parseZone(month.updatedAt).format(
                    "YYYY-MM-DD[T]HH:mm:ss"
                ),
            };

            if (action === "validate") {
                json.action = "accept_extra_activities";
                setValidating(true)
            } else if (action === "refuse") {
                json.action = "refuse_extra_activities";
                setRefusing(true)
            }

            let body = JSON.stringify(json);
            dispatch(ValidationActions.postMonthAction(body)).then(() => {
                setSubmitting(false);
                setValidating(false)
                setRefusing(false)
                handleClose()
                loadList();
            });
        }
    }

    return <div className="extra-activities-line-mobile">
        <div>
            <div className="extra-activities-line-month">
                {monthInLetter(month.month) + ' ' + month.year}
            </div>
        </div>
        <div className="extra-activities-line-mobile-actions" onClick={() => setShowPortal(true)}>
            <i className="fas fa-caret-right"></i>
        </div>
        <SlidePageTransitionPortal show={showPortal} handleClose={handleClose}>
            <PageHeader action={handleClose} title={month.user.firstname + ' ' + month.user.lastname}
                        type="hours-not-worked"/>
            <div className="extra-activities-validation-mobile-header">
                Valider la demande de saisie d'activités complémentaires pour le mois de :
            </div>
            <div className="extra-activities-validation-mobile-month">
                {monthInLetter(month.month) + ' ' + month.year}
            </div>
            <textarea
                className="validate-extra-activity-comment-input"
                placeholder="Commentaire"
                onChange={(e) => handleCommentChange(e)}
            ></textarea>
            <div className="validation-mobile-actions">
                <Button className={"validate-btn" + (isSubmitting ? ' disabled-btn' : '')}
                        onClick={() => handleSubmit('validate')}
                        text={"Valider"}
                        processable={true}
                        isProcessing={isValidating}
                />
                <Button className={"refuse-btn" + (isSubmitting ? ' disabled-btn' : '')}
                        onClick={() => handleSubmit('refuse')}
                        text={"Refuser"}
                        processable={true}
                        isProcessing={isRefusing}
                />
            </div>
        </SlidePageTransitionPortal>
    </div>
}

export default ExtraActivitiesRequestLineMobile
