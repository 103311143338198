import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import UnionDocuments from "components/DocumentContainer/UnionDocuments";
import UnionHome from "components/DocumentContainer/UnionHome";
import DocumentsList from "components/DocumentContainer/DocumentsList";
import SocialList from "components/DocumentContainer/SocialList";
import * as DocumentActions from "actions/Document";
import "./DocumentContainer.scss";
import PageHeader from "../common/PageHeader";
import {isMobile} from "../../utils/Browser";

const DocumentContainer = () => {
    const {id = undefined, type = "documents"} = useParams();
    const dispatch = useDispatch();
    const union = useSelector((state) => {
        return state.document.entities.union;
    });

    const decideHeaderTitle = () => {
        let title = ''

        switch (type) {
            case "syndicats":
                if (isMobile()) {
                    title = union.label
                } else {
                    title = "Mes informations sociales"
                }
                break;
            case "social":
                title = "Mes informations sociales"
                break;
            default:
                title = "Mes documents"
                break;
        }

        return title
    }

    const [headerTitle, setHeaderTitle] = useState(decideHeaderTitle())

    useEffect(() => {
        setHeaderTitle(decideHeaderTitle())
    }, [union]);

    return (
        <div id="page-container">
            <div className="container">
                <div className="links-container">
                    <PageHeader title={headerTitle} type="documents"
                                link={type === "syndicats" ? "/documents/social" : "/"}
                                breadcrumb={type === "syndicats" ? [
                                    {label: "Mes informations sociales", url: "/documents/social"},
                                    {label: union.label ? union.label : "", url: ""},
                                ] : undefined}
                    />
                </div>


                {/* {type === "syndicats" && id === undefined && <UnionHome />} */}
                {type === "syndicats" && id !== undefined && (
                    <UnionDocuments id={id}/>
                )}
                {type === "documents" && <DocumentsList/>}
                {type === "social" && <SocialList/>}
            </div>

        </div>
    );
};

export default DocumentContainer;
