import React, {useState} from "react";
import moment from "moment";
import "moment/locale/fr";
import Lightbox from "components/common/Lightbox";
import {useDispatch, useSelector} from "react-redux";
import * as TimesheetActions from "actions/Timesheet";
import {changeOverlayContent} from "events/OverlayEvents";

import "./DeleteOvertime.scss";
import {daysForOvertimeList} from "utils/Utils";
import LightboxHeader from "../../../common/LightboxHeader";
import {EntityRepository, OVERTIME_CODE_REPOSITORY} from "../../../../store/EntityRepository";
import Button from "../Event/Button";

const DeleteOvertime = (props) => {
    const {entity} = props;
    const dispatch = useDispatch();
    const [isSubmitting, setSubmitting] = useState(false);
    const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const isMobile = useSelector((state) => state.common.isMobile)
    const overtimeCodeRepo = EntityRepository().getRepository(OVERTIME_CODE_REPOSITORY);


    const overtimeCode = typeof entity.code !== "object" ? overtimeCodeRepo.findWithoutHook(entity.code, 'edit') : entity.code

    const handleSubmit = () => {
        if (!entity.locked) {
            setSubmitting(true)
            dispatch(TimesheetActions.deleteOvertime(entity.id)).then(() => {
                setSubmitting(false)
                changeOverlayContent(null);
                dispatch(
                    TimesheetActions.notifySuccess("Votre astreinte a été supprimée avec succès")
                );
                dispatch(
                    TimesheetActions.fetchEntireMonth(monthEntity.month, monthEntity.year)
                );
            });
        }
    };

    const HourContainer = (props) => {
        const {day} = props;

        const HourLine = (props) => {
            const {hour} = props;

            return (
                <div>
                    {hour.start}{" "}
                    <div className="hour-separator">
                        <i className="far fa-chevron-right"></i>
                    </div>
                    {" "}
                    {hour.end}
                </div>
            );
        };

        return (
            <div className="overtime-delete-day-container">
                <div className="overtime-delete-date">
                    {moment.parseZone(day.date).format(isMobile ? "ddd DD" : "dddd D MMMM")}
                </div>
                <div className="overtime-delete-hour-container">
                    {day.hours.map((hour, key) => {
                        return <HourLine key={key} hour={hour}/>;
                    })}
                </div>
            </div>
        );
    };

    const TicketLine = (props) => {
        const {day} = props;

        return (
            <div className="overtime-delete-ticket-line">
                <div className="overtime-delete-date">
                    {moment.parseZone(day.date).format(isMobile ? "ddd DD" : "dddd D MMMM")}
                </div>
                <div className="overtime-ticket-amount">{day.tickets}</div>
            </div>
        );
    };

    const RecapHeader = (props) => {
        const HourHeader = () => {
            return (
                <div className="overtime-days-container-header">
                    <div className="col1">Début :</div>
                    <div className="col2">Fin :</div>
                </div>
            );
        };

        const TicketHeader = () => {
            return (
                <div className="overtime-days-container-header">
                    <div className="tickets-header">Nombre de tickets :</div>
                </div>
            );
        };

        return (
            <div>
                {entity.hourCount > 0 && <HourHeader/>}
                {entity.ticketCount > 0 && <TicketHeader/>}
            </div>
        );
    };

    return (
        <Lightbox className="lb-w1200 lb-overtime-delete">
            <LightboxHeader>
                <div className="lb-overtime-delete-header">Supprimer des astreintes</div>
            </LightboxHeader>
            <div className="lb-overtime-delete-label">
                Les éléments suivants seront supprimés :
                <div className="lb-overtime-delete-description">
                    {overtimeCode.description}
                </div>
            </div>
            <div className="lb-overtime-delete-recap">
                <RecapHeader/>
                {entity.hourCount > 0 || entity.ticketCount > 0
                    ? daysForOvertimeList(entity, entity.type).map((day, key) =>
                        entity.hourCount > 0 ? (
                            <HourContainer key={key} day={day}/>
                        ) : (
                            <TicketLine key={key} day={day}/>
                        )
                    )
                    : daysForOvertimeList(entity, entity.type).map(
                        (day, key) => (
                            <div
                                key={key}
                                className="overtime-delete-constraint-line"
                            >
                                <div className="overtime-delete-date">
                                    {moment.parseZone(day.date).format(isMobile ? "ddd DD" : "dddd D MMMM")}
                                </div>
                            </div>
                        )
                    )
                }

                {entity.locked ? (<div className="cant-delete-overtime-warning warning-message bold">
                    <i className="extra-activities-warning far fa-exclamation-square "></i>{" "}
                    Vous devez d'abord supprimer les interventions liées à cette astreinte avant de pouvoir la
                    supprimer.
                </div>) : ""}

            </div>
            <div className="lb-confirm-send-actions">
                <div
                    onClick={() => changeOverlayContent(null)}
                    className="lb-confirm-send-cancel"
                >
                    Annuler
                </div>
                <Button
                    onClick={handleSubmit}
                    className={`lb-confirm-send-confirm ${entity.locked ? " disabled-btn" : ""}`}
                    title={entity.locked ? "Vous devez d'abord supprimer les interventions liées à cette astreinte" : ""}
                    text={"Supprimer"}
                    processable={true}
                    isProcessing={isSubmitting}
                />
            </div>
        </Lightbox>
    );
};

export default DeleteOvertime;
