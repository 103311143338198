import React from "react";

import ConfirmSendMonth from "components/TimesheetContainer/Calendar/ConfirmSendMonth";
import {changeOverlayContent} from "events/OverlayEvents";

import "./SendMonthButton.scss";
import {useSelector} from "react-redux";
import Button from "../Forms/Event/Button";

const SendMonthButton = (props) => {
    const {monthEntity, personType} = props;
    const isActive = monthEntity.submittable && !monthEntity.submitted;
    const craMode = useSelector((state) => state.timesheet.craMode)

    const handleClick = () => {
        if (isActive) {
            changeOverlayContent(
                <ConfirmSendMonth
                    month={monthEntity.month}
                    year={monthEntity.year}
                    personType={personType}
                    hasEvents={monthEntity.events.length > 0}
                    surveySent={monthEntity.surveySent}
                    hasExtraActivityPaymentChoice={() => monthEntity.hasExtraActivityPaymentChoice()}
                    extraActivities={monthEntity.extraActivities}
                    extraActivityWeeks={monthEntity.extraActivityWeeks}
                    overtimes={monthEntity.overtimes}
                />
            );
        }
    };

    return (
        <Button
            onClick={handleClick}
            className={"send-month-btn" + (isActive ? " active" : "") + (craMode !== "view" ? " send-month-btn-hidden" : "")}
            title={
                !monthEntity.absenceValidated
                    ? "Des absences n'ont pas encore été validées par votre manager"
                    : ""
            }
            text={"Envoyer mon CRA"}
        />
    );
};

export default SendMonthButton;
