import {normalize} from "normalizr";
import moment from "moment";
import "moment/locale/fr";

import * as Params from "config/Parameters";
import {refreshToken} from "actions/Common";
import {isEmpty} from "utils/Utils";
import * as TimesheetActions from "actions/Timesheet";

export const CALL_API = "Call API";

export const callMySynchroneAPIMiddleware = (store) => {
    const dispatch = store.dispatch;
    return (next) => (action) => {
        const {
            types,
            schema,
            callAPI,
            name,
            alwaysFetch = true,
            needLogin = () => false,
            payload = {},
        } = action;

        if (!alwaysFetch) {
            const state = store.getState();
            const currentState = eval("state." + name);
            if (!isEmpty(currentState)) {
                return Promise.resolve();
            }
        }

        if (!types) {
            // Normal action: pass it on
            return next(action);
        }

        if (typeof callAPI !== "function") {
            throw new Error("Expected callAPI to be a function");
        }
        if (!schema) {
            throw new Error("Specify one of the exported Schemas.");
        }
        if (!Array.isArray(types) || types.length !== 3) {
            throw new Error(
                "Expected an array of at least three action types."
            );
        }
        if (!types.every((type) => typeof type === "string")) {
            throw new Error("Expected action types to be strings.");
        }

        const [requestType, successType, failureType] = types;

        dispatch(
            Object.assign({}, payload, {
                type: requestType,
            })
        );

        const doCallApi = () => {
            return callAPI()
                .then(
                    (response) => {
                        if (!response.ok) {
                            response.failureType = failureType;
                            response.payload = payload;
                            if (!(response.failureType === "FETCH_EXTRA_ACTIVITY_FOR_WEEK_FAILURE" || response.failureType === "FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_FAILURE")) {
                                return Promise.reject(response);
                            }
                        } else {
                            if (
                                response.statusText !== "No Content" &&
                                response.status !== 204
                            ) {
                                let totalCount =
                                    response.headers.get("X-Total-Count");
                                return response.json().then((json) => {
                                    if (json !== null) {
                                        return dispatch(
                                            Object.assign({}, payload, {
                                                response: normalize(
                                                    json,
                                                    schema
                                                ),
                                                type: successType,
                                                totalCount: totalCount,
                                            })
                                        );
                                    }
                                });
                            }
                        }
                    },
                    (error) => {
                        return dispatch(
                            Object.assign({}, payload, {
                                error,
                                type: failureType,
                            })
                        );
                    }
                )
                .catch((response) => {
                    let throwException = true;
                    if (response.status === 503) {
                        window.location = "/maintenance";
                    } else {
                        response.clone().json().then((json) => {
                            let errorMessage;
                            if (json?.errors?.length > 0) {
                                errorMessage = json.errors[0].message;
                            } else {
                                if (json?.errors?.cause === "scanner.infected") {
                                    errorMessage =
                                        "Votre fichier a été refusé par notre antivirus.";
                                } else {
                                    errorMessage = "Une erreur s'est produite";
                                }
                            }

                            // return dispatch(
                            //     Object.assign({}, payload, {
                            //         errorMessage,
                            //         type: failureType,
                            //     })
                            // );
                            if ((response.failureType === "FETCH_EXTRA_ACTIVITY_FOR_WEEK_FAILURE" || response.failureType === "FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_FAILURE") && response.status !== 404) {
                                return dispatch(
                                    TimesheetActions.notifyError(errorMessage)
                                );
                            } else if ((response.failureType === "FETCH_EXTRA_ACTIVITY_FOR_WEEK_FAILURE" || response.failureType === "FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_FAILURE")) {
                                response.failureType = failureType;
                                throwException = false;
                                return dispatch(
                                    Object.assign({}, payload, {
                                        response: normalize(
                                            json,
                                            schema
                                        ),
                                        type: failureType,
                                    })
                                );
                            }
                        });

                        return Promise.reject(response);
                    }
                });
        };

        if (needLogin) {
            let expirationDate = moment
                .unix(localStorage.getItem("synchroneTokenExpiration"))
                .subtract(Params.REFRESH_DELAY, "s");
            if (moment.parseZone() >= expirationDate) {
                return refreshToken().then(() => {
                    return doCallApi();
                });
            }
        }

        return doCallApi();
    };
};
