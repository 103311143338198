import React, {useEffect, useState} from 'react';
import {isPWA} from "../utils/Browser";

const useDisplayInstallProcedure = () => {
    const [isInstalled, setIsInstalled] = useState(false);
    const [isPossiblyInstalled, setIsPossiblyInstalled] = useState(false);

    useEffect(() => {
        // Vérifiez si la méthode est supportée
        if ('getInstalledRelatedApps' in navigator) {
            navigator.getInstalledRelatedApps().then((apps) => {
                if (apps.length > 0) {
                    // Si des apps sont installées
                    setIsPossiblyInstalled(true);
                } else {
                    // Aucune app installée
                    setIsPossiblyInstalled(false);
                }
            }).catch((error) => {
                console.error('Erreur lors de la vérification des apps installées:', error);
            });
        } else {
            console.warn('getInstalledRelatedApps non supportée dans ce navigateur.');
        }
    }, []);

    useEffect(() => {
        console.log(!isPossiblyInstalled)
        console.log(!isPWA())
        setIsInstalled(!isPossiblyInstalled && !isPWA())
    }, [isPossiblyInstalled]);

    return isInstalled;
};

export default useDisplayInstallProcedure;
