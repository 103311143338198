import React, {useState} from "react";
import usePressEnterKey from "hooks/usePressEnterKey";
import usePressNumPadEnterKey from "hooks/usePressNumPadEnterKey";
import "./SearchBar.scss";
import {useSelector} from "react-redux";

const SearchBar = (props) => {
    const [value, setValue] = useState("");
    const isMobile = useSelector((state) => state.common.isMobile)

    usePressEnterKey(() => {
        applyFilter();
    });

    usePressNumPadEnterKey(() => {
        applyFilter();
    });

    const applyFilter = () => {
        props.setFilter(value);
    };

    const resetInput = () => {
        setValue("");
        props.setFilter("");
    };

    const handleChange = (event) => {
        setValue(event.target.value);
        props.setFilter(event.target.value);
    };

    return (
        <div className="search-container">
            <i className="fal fa-search" onClick={applyFilter}></i>
            <input
                type="text"
                value={value}
                className="contact-searchbar"
                placeholder={props.placeholder}
                onChange={handleChange}
                ref={(input) => {
                    if (input && !isMobile) {
                        input.focus()
                    }
                    return input
                }}
            ></input>
            <i
                className={(!value ? "hidden " : "") + "fal fa-times "}
                onClick={resetInput}
            ></i>
        </div>
    );
};

export default SearchBar;
