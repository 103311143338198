import moment from 'moment';

const formatDate = (date) => {
    return moment.parseZone(date).format("YYYY-MM-DD");
}

const isOutsideActivePeriod = (date, startAt, endAt) => {
    const formattedDate = formatDate(date);
    const formattedStart = startAt ? formatDate(startAt) : '-Infinity';
    const formattedEnd = endAt ? formatDate(endAt) : 'Infinity';
    return formattedDate < formattedStart || formattedDate > formattedEnd;
}

const isWithinLastActivePeriod = (date, startAt, lastEndAt) => {
    const formattedDate = formatDate(date);
    const formattedStart = formatDate(startAt);
    const formattedLastEnd = formatDate(lastEndAt);
    return formattedDate > formattedLastEnd && formattedDate < formattedStart;
}

export const isDayAvailable = (day, user) => {
    if (day.workingState !== "worked") {
        return false;
    }

    // Check conditions for the user
    if (user) {
        if ((user.startAt && !user.lastEndAt && isOutsideActivePeriod(day.dateAt, user.startAt, null)) ||
            (user.endAt && isOutsideActivePeriod(day.dateAt, null, user.endAt)) ||
            (user.lastEndAt && user.startAt && isWithinLastActivePeriod(day.dateAt, user.startAt, user.lastEndAt))) {
            return false;
        }
    }

    return true;
}

export const getWeekRange = (weekNumber, year) => {
  // Créer une date à partir de l'année et du numéro de semaine
  const startOfWeek = moment().year(year).week(weekNumber).startOf('week');
  const endOfWeek = moment().year(year).week(weekNumber).endOf('week');

  return {
    startOfWeek,
    endOfWeek
  };
};
