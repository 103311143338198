import React, {useEffect} from "react";
import ExtraDayTypeSelector from "./ExtraDayTypeSelector";
import moment from "moment";
import {useDispatch} from "react-redux";
import * as TimesheetActions from "actions/Timesheet";

const ExtraDaySelector = (props) => {
    const dispatch = useDispatch();
    const {config} = props;

    const days = []
    const data = []
    config.complementaries.map((day) => {
        if (typeof data[day.date] === "undefined") {
            data[day.date] = {
                number: moment(day.date).format("D"),
            }
        }
        data[day.date].complementary = day.fillable
    })

    config.constraints.map((day) => {
        if (typeof data[day.date] === "undefined") {
            data[day.date] = {}
        }
        data[day.date].constraint = day.fillable
    })

    for (let day in data) {
        days.push(data[day])
    }

    useEffect(() => {
        const day = days[0]
        dispatch(TimesheetActions.setExtraDay({
            number: day.number,
            type: day.complementary ? 'complementary' : 'constraint',
        }))

        return () => {
            dispatch(TimesheetActions.clearExtraDay())
        }
    }, [])

    return <div className="extra-day-selector">{days.map((day, key) => (
            <ExtraDayTypeSelector day={day} key={key} rtl={key > 3} />
        ))}</div>
}

export default ExtraDaySelector
