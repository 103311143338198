import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import * as TimesheetActions from "actions/Timesheet";

import "./TimesheetNotifier.scss";

const TimesheetNotifier = () => {
    const dispatch = useDispatch();
    const errorMessage = useSelector(state => state.timesheet.errorMessage);
    const successMessage = useSelector(state => state.timesheet.successMessage);
    const [opened, setOpened] = useState(false)

    const handleClick = () => {
        setOpened(false)
        if (errorMessage) {
            dispatch(TimesheetActions.resetErrorMessage());
        } else if (successMessage) {
            dispatch(TimesheetActions.resetSuccessMessage());
        }
    };

    useEffect(() => {
        if (errorMessage) {
            setOpened(true)
            setTimeout(() => {
                setOpened(false)
                setTimeout(() => {
                    dispatch(TimesheetActions.resetErrorMessage());
                }, 1000)
            }, 5000);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (successMessage) {
            setOpened(true)
            setTimeout(() => {
                setOpened(false)
                setTimeout(() => {
                    dispatch(TimesheetActions.resetSuccessMessage());
                }, 1000)
            }, 5000);
        }
    }, [successMessage]);

    return (
        <div
            className={
                (opened ? "open " : "") +
                (successMessage ? " success-notifier " : "") +
                (errorMessage ? " error-notifier " : "") +
                "timesheet-notifier"
            }
        >
            <span className="pre-icon">
                {errorMessage ? (
                    <i className="fal fa-exclamation-square"></i>
                ) : (
                    ""
                )}
                {successMessage ? <i className="fal fa-smile"></i> : ""}
            </span>
            <span className="notifier-text">
                {errorMessage || successMessage}
            </span>
            <span className="post-icon clickable" onClick={() => handleClick()}>
                <i className="fal fa-times"></i>
            </span>
        </div>
    );
};

export default TimesheetNotifier;
