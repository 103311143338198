import React from "react";
import { useDispatch } from "react-redux";
import * as CommonActions from "actions/Common";
import * as TimesheetActions from "actions/Timesheet";
import { changeOverlayContent } from "events/OverlayEvents";

import {
    STEP1,
    STEP1A,
    STEP1B,
    STEP1C,
    STEP2,
    STEP3,
    STEP4,
} from "components/TimesheetContainer/Forms/Event/Edit";
import {useSwipeable} from "react-swipeable";
import LightboxHeader from "../../../common/LightboxHeader";

const Header = (props) => {
    const dispatch = useDispatch();

    function closeLightbox() {
        changeOverlayContent(null);
        dispatch(TimesheetActions.clearDaysSelection());
        dispatch(TimesheetActions.unlockStart());
        dispatch(TimesheetActions.unlockEnd());
    }

    return (
        <LightboxHeader>
            <div className="lb-event-edit-header">
                <div
                    className={
                        (props.step === STEP1 ||
                        props.step === STEP1A ||
                        props.step === STEP1B ||
                        props.step === STEP1C ||
                        props.step === STEP2
                            ? "active "
                            : "") +
                        (props.step > STEP1 ? " pointer " : "") +
                        "lb-event-edit-header-title"
                    }
                    onClick={() =>
                        props.isEdit ? props.goToStep(STEP2) : props.goToStep(STEP1)
                    }
                >
                    QUOI ?
                </div>
                <div
                    className={
                        (props.step === STEP3 ? "active " : "") +
                        (props.step > STEP3 ? " pointer " : "") +
                        "lb-event-edit-header-title"
                    }
                    onClick={() => props.goToStep(STEP3)}
                >
                    QUAND ?
                </div>
                <div
                    className={
                        (props.step === STEP4 ? "active " : "") +
                        "lb-event-edit-header-title"
                    }
                >
                    RECAPITULATIF
                </div>
                <i
                    onClick={closeLightbox}
                    className="clickable lb-close fal fa-times"
                ></i>
            </div>
        </LightboxHeader>
    );
};

export default Header;
