import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as TimesheetActions from "actions/Timesheet";
import {isDayAvailable} from "../../../utils/CalendarHelper";
import {
    EntityRepository,
    HALFDAY_REPOSITORY,
    EVENT_CODE_REPOSITORY,
    EVENT_REPOSITORY
} from "store/EntityRepository";

const RemoteDay = (props) => {
    const day = props.day
    const me = useSelector((state) => state.user.me)
    const halfDayRepo = EntityRepository().getRepository(HALFDAY_REPOSITORY);
    const eventRepo = EntityRepository().getRepository(EVENT_REPOSITORY);
    const eventCodeRepo = EntityRepository().getRepository(EVENT_CODE_REPOSITORY);
    // const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const remoteDays = useSelector((state) => state.timesheet.remoteDays);
    const dispatch = useDispatch();

    const isRemote = () => {
        if (typeof day.dateAt !== 'object') {
            const remote = remoteDays.find(remote => remote.day === day.dateAt.slice(0, 10))
            if (remote) {
                if (remote.am && remote.pm) {
                    return 'full'
                } else if (remote.am) {
                    return 'am'
                } else if (remote.pm) {
                    return 'pm'
                }
            }
        }

        return false
    }

    const getDateFormatted = () => {
        if (typeof day.dateAt !== 'object') {
            return day.dateAt.slice(0, 10)
        }
    }

    const buildData = () => {
        const data = {}
        data.day = getDateFormatted()
        data.am = remote === 'am' || remote === 'full'
        data.pm = remote === 'pm' || remote === 'full'

        return data
    }

    const [remote, setRemote] = useState(isRemote())
    const states = []

    // useEffect(() => {
    //     console.log(remoteDays);
    //     setRemoteDays(isRemote())
    // }, [remoteDays])

    const changeRemote = () => {
        const currentIndex = states.indexOf(remote);
        const nextIndex = (currentIndex + 1) % states.length;
        setRemote(states[nextIndex]);
    }

    useEffect(() => {
        dispatch(TimesheetActions.setRemoteDay(buildData()))
    }, [remote]);

    const handleClick = () => {
        if (dayAvailable()) {
            changeRemote()
        }
    }

    const buildHalfday = (halfdayId) => {
        const halfday = halfDayRepo.find(halfdayId)
        if (halfday?.event) {
            const event = eventRepo.find(halfday.event)
            if (event?.eventCode) {
                const eventCode = eventCodeRepo.find(event.eventCode)
                return eventCode.type
            }
        }


        return null
    }

    const am = buildHalfday(day.am)
    const pm = buildHalfday(day.pm)

    if (["activity", null].includes(am) && ["activity", null].includes(pm)) {
        states.push("full")
    }
    if (["activity", null].includes(am)) {
        states.push("am")
    }
    if (["activity", null].includes(pm)) {
        states.push("pm")
    }
    states.push(false)

    const getHalfdayClasses = () => {
        const classes = ['remote-hd-abs']
        if (!["activity", null].includes(am)) {
            classes.push('remote-hd-abs-am')
        }
        if (!["activity", null].includes(pm)) {
            classes.push('remote-hd-abs-pm')
        }

        return classes.join(' ')
    }

    const dayAvailable = () => {
        if (isDayAvailable(day, me) && (["activity", null].includes(am) || ["activity", null].includes(pm))) {
            return true;
        }

        return false
    }

    const getClasses = (day) => {
        const classes = ['day-mobile remote-day-mobile']
        if (day.workingState === "notCurrentMonth") {
            classes.push("not-current-month")
        } else if (!dayAvailable()) {
            classes.push("unavailable");
        } else {
            // console.log(day.workingState)
        }
        if (remote) {
            classes.push("day-remote-"+remote)
        }

        return classes.join(" ")
    }

    return <div className={getClasses(day)} onClick={handleClick}>
        <div className={"day-number "+getHalfdayClasses()} data-value={day.DayNumber}>{day.DayNumber}</div>
    </div>
}

export default RemoteDay
